import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library. The Decimal appears in a JSON
   * response as a string to preserve precision.
   */
  Decimal: any;
  /**
   * The `Json` scalar type represents arbitrary json string data, represented as UTF-8
   * character sequences. The Json type is most often used to represent a free-form
   * human-readable json string.
   */
  Json: any;
  /** GeoLocation - Point(Lng, Lat) */
  Location: any;
  /**
   * The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
   */
  NaiveDateTime: any;
  /**
   * The `Time` scalar type represents a time. The Time appears in a JSON
   * response as an ISO8601 formatted string, without a date component.
   */
  Time: any;
};

export type AddRooms = {
  __typename?: 'AddRooms';
  eventId: Scalars['ID'];
  providerUrl: Scalars['String'];
};

export type AddRoomsPayload = {
  __typename?: 'AddRoomsPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<AddRooms>;
};

export type Admin = User & {
  __typename?: 'Admin';
  avatarUrl: Maybe<Scalars['String']>;
  eventInfo: Event;
  eventList: Array<Event>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  invitation: InvitationInfo;
  lastName: Scalars['String'];
  myBookings: Array<Booking>;
  myEventInfo: MyEvent;
  myEventList: Array<MyEvent>;
};


export type AdminEventInfoArgs = {
  id: Scalars['ID'];
};


export type AdminInvitationArgs = {
  eventId: Scalars['ID'];
};


export type AdminMyBookingsArgs = {
  eventId: Scalars['ID'];
};


export type AdminMyEventInfoArgs = {
  id: Scalars['ID'];
};

export type Article = {
  __typename?: 'Article';
  author: Maybe<Scalars['String']>;
  body: Scalars['String'];
  credits: Array<Credit>;
  id: Scalars['ID'];
  images: Array<Image>;
  insertedAt: Scalars['NaiveDateTime'];
  location: Maybe<Scalars['String']>;
  metadata: Maybe<Scalars['Json']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Balance = {
  __typename?: 'Balance';
  balance: Scalars['Decimal'];
  lastMessage: Maybe<NegotiationMessage>;
  provider: Scalars['ID'];
  quotes: Array<Quote>;
};

export type BalanceUpdate = {
  __typename?: 'BalanceUpdate';
  balance: Scalars['Decimal'];
};

export type BestPackage = {
  __typename?: 'BestPackage';
  baseNumber: Scalars['Int'];
  capacity: Scalars['Int'];
  minimumGuests: Scalars['Int'];
  price: Maybe<Scalars['Decimal']>;
};

export type BestRoom = {
  __typename?: 'BestRoom';
  price: Maybe<Scalars['Decimal']>;
  sleeps: Scalars['Int'];
};

export type Booking = {
  __typename?: 'Booking';
  availability: Scalars['Boolean'];
  bookingAddOn: Array<BookingAddOn>;
  date: Maybe<Scalars['NaiveDateTime']>;
  duration: Maybe<Scalars['Int']>;
  eventComponent: EventComponent;
  guest: GuestShort;
  guestPrice: PriceComponents;
  id: Scalars['ID'];
  status: BookingStatus;
};

export type BookingAddOn = {
  __typename?: 'BookingAddOn';
  bookingGuest: Maybe<BookingGuest>;
  bookingId: Scalars['ID'];
  id: Scalars['ID'];
  offeringAddOn: OfferingAddOns;
  offeringAddOnOption: Maybe<OfferingAddOnOptions>;
};

export type BookingAddOnPayload = {
  __typename?: 'BookingAddOnPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<BookingAddOn>;
};

export type BookingGuest = {
  __typename?: 'BookingGuest';
  bookingAddOnId: Scalars['ID'];
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  guest: Maybe<GuestShort>;
  lastName: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  plusone: Scalars['Boolean'];
};

export type BookingPayload = {
  __typename?: 'BookingPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Booking>;
};

export enum BookingStatus {
  Abandonned = 'ABANDONNED',
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Declined = 'DECLINED'
}

export type Comment = {
  __typename?: 'Comment';
  body: Scalars['String'];
  id: Scalars['ID'];
  insertedAt: Scalars['NaiveDateTime'];
  user: UserProfile;
};

export type CommentInput = {
  body: Scalars['String'];
};

export type CommentPayload = {
  __typename?: 'CommentPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Comment>;
};

export type Concierge = User & {
  __typename?: 'Concierge';
  avatarUrl: Maybe<Scalars['String']>;
  eventInfo: Event;
  eventList: Array<Event>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  invitation: InvitationInfo;
  lastName: Scalars['String'];
  myBookings: Array<Booking>;
  myEventInfo: MyEvent;
  myEventList: Array<MyEvent>;
};


export type ConciergeEventInfoArgs = {
  id: Scalars['ID'];
};


export type ConciergeInvitationArgs = {
  eventId: Scalars['ID'];
};


export type ConciergeMyBookingsArgs = {
  eventId: Scalars['ID'];
};


export type ConciergeMyEventInfoArgs = {
  id: Scalars['ID'];
};

export type Credit = {
  __typename?: 'Credit';
  articleId: Maybe<Scalars['ID']>;
  business: Scalars['String'];
  providerId: Maybe<Scalars['ID']>;
  service: Scalars['String'];
};

export type CursorInput = {
  cursor: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
};

export type Customer = User & {
  __typename?: 'Customer';
  avatarUrl: Maybe<Scalars['String']>;
  eventInfo: Event;
  eventList: Array<Event>;
  events: Array<Event>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  invitation: InvitationInfo;
  invitations: Maybe<Array<Invitation>>;
  lastName: Scalars['String'];
  myBookings: Array<Booking>;
  myEventInfo: MyEvent;
  myEventList: Array<MyEvent>;
  myEvents: Array<MyEvent>;
};


export type CustomerEventInfoArgs = {
  id: Scalars['ID'];
};


export type CustomerEventsArgs = {
  id: Maybe<Scalars['ID']>;
};


export type CustomerInvitationArgs = {
  eventId: Scalars['ID'];
};


export type CustomerMyBookingsArgs = {
  eventId: Scalars['ID'];
};


export type CustomerMyEventInfoArgs = {
  id: Scalars['ID'];
};


export type CustomerMyEventsArgs = {
  id: Maybe<Scalars['ID']>;
};


export type Error = {
  __typename?: 'Error';
  key: Scalars['String'];
  message: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  brideFirstName: Maybe<Scalars['String']>;
  brideLastName: Maybe<Scalars['String']>;
  cover: Maybe<Scalars['String']>;
  defaultKidsAllowed: Scalars['Boolean'];
  description: Scalars['String'];
  groomFirstName: Maybe<Scalars['String']>;
  groomLastName: Maybe<Scalars['String']>;
  guestProfile: Guest;
  host: HostBasicInfo;
  id: Scalars['ID'];
  invitationTemplate: Maybe<InvitationTemplate>;
  mainImage: Scalars['String'];
  name: Scalars['String'];
  tiers: Array<Tier>;
  video: Maybe<Scalars['String']>;
};

export type EventComponent = {
  __typename?: 'EventComponent';
  alias: Maybe<Scalars['String']>;
  booking: Maybe<Array<Maybe<Booking>>>;
  bookingCount: Scalars['Int'];
  capacity: Scalars['Int'];
  customTexts: Maybe<Scalars['Json']>;
  date: Scalars['NaiveDateTime'];
  deadline: Scalars['Int'];
  duration: Scalars['Int'];
  event: Event;
  eventId: Scalars['ID'];
  guestPays: Scalars['Decimal'];
  id: Scalars['ID'];
  items: Array<NegotiationItem>;
  main: Scalars['Boolean'];
  offering: Offering;
  order: Scalars['Int'];
  packageCustomization: Array<PackageAddons>;
  softDeadline: Maybe<Scalars['NaiveDateTime']>;
  status: EventComponentStatus;
  tiers: Array<Tier>;
};

export type EventComponentAddedBody = {
  __typename?: 'EventComponentAddedBody';
  eventComponentId: Scalars['ID'];
  eventId: Scalars['ID'];
  image: Maybe<Image>;
  message: Scalars['String'];
};

export enum EventComponentEvent {
  Cancel = 'CANCEL',
  Confirm = 'CONFIRM',
  Negotiate = 'NEGOTIATE',
  Pay = 'PAY'
}

export type EventComponentGroup = {
  __typename?: 'EventComponentGroup';
  components: Array<EventComponent>;
  event: Event;
};

export type EventComponentInput = {
  alias: Maybe<Scalars['String']>;
  capacity: Maybe<Scalars['Int']>;
  date: Maybe<Scalars['NaiveDateTime']>;
  duration: Maybe<Scalars['Int']>;
  guestPays: Maybe<Scalars['Decimal']>;
};

export type EventComponentPayload = {
  __typename?: 'EventComponentPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<EventComponent>;
};

export enum EventComponentStatus {
  Available = 'AVAILABLE',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Deleted = 'DELETED',
  Negotiating = 'NEGOTIATING',
  Ready = 'READY',
  Rejected = 'REJECTED'
}

export type EventInput = {
  customUrl: Maybe<Scalars['String']>;
  defaultKidsAllowed: Maybe<Scalars['Boolean']>;
  defaultPlusOne: Maybe<Scalars['Int']>;
  description: Maybe<Scalars['String']>;
  emailCustomText: Maybe<Scalars['String']>;
  guestCanPost: Maybe<Scalars['Boolean']>;
  isPrivate: Maybe<Scalars['Boolean']>;
  mainImage: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  tiers: Maybe<Array<TierInput>>;
};

export type ExtraNightsAddon = {
  addonNightsAfterId: Maybe<Scalars['ID']>;
  addonNightsBeforeId: Maybe<Scalars['ID']>;
  bookingId: Scalars['ID'];
  date: Maybe<Scalars['NaiveDateTime']>;
  duration: Maybe<Scalars['Int']>;
  optionNightsAfterId: Maybe<Scalars['ID']>;
  optionNightsBeforeId: Maybe<Scalars['ID']>;
};

export type ExtraNightsAddonPublic = {
  addonNightsAfterId: Maybe<Scalars['ID']>;
  addonNightsBeforeId: Maybe<Scalars['ID']>;
  date: Maybe<Scalars['NaiveDateTime']>;
  duration: Maybe<Scalars['Int']>;
  optionNightsAfterId: Maybe<Scalars['ID']>;
  optionNightsBeforeId: Maybe<Scalars['ID']>;
};

export type FlightInformation = {
  __typename?: 'FlightInformation';
  adults: Scalars['Int'];
  arrivalAirline: Scalars['String'];
  arrivalAirport: Scalars['String'];
  arrivalDatetime: Scalars['NaiveDateTime'];
  arrivalFlight: Scalars['String'];
  arrivalLocation: Scalars['String'];
  departureAirline: Maybe<Scalars['String']>;
  departureAirport: Maybe<Scalars['String']>;
  departureDatetime: Maybe<Scalars['NaiveDateTime']>;
  departureFlight: Maybe<Scalars['String']>;
  departureLocation: Maybe<Scalars['String']>;
  guestId: Maybe<Scalars['ID']>;
  guestNames: Scalars['String'];
  id: Scalars['ID'];
  kids: Scalars['Int'];
  propertyName: Scalars['String'];
};

export type FlightInformationInput = {
  adults: Scalars['Int'];
  arrivalAirline: Scalars['String'];
  arrivalAirport: Scalars['String'];
  arrivalDatetime: Scalars['NaiveDateTime'];
  arrivalFlight: Scalars['String'];
  arrivalLocation: Scalars['String'];
  departureAirline: Maybe<Scalars['String']>;
  departureAirport: Maybe<Scalars['String']>;
  departureDatetime: Maybe<Scalars['NaiveDateTime']>;
  departureFlight: Maybe<Scalars['String']>;
  departureLocation: Maybe<Scalars['String']>;
  guestNames: Scalars['String'];
  id: Maybe<Scalars['ID']>;
  kids: Scalars['Int'];
  propertyName: Scalars['String'];
};

export type FlightInformationPayload = {
  __typename?: 'FlightInformationPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<FlightInformation>;
};

export type Guest = User & {
  __typename?: 'Guest';
  avatarUrl: Maybe<Scalars['String']>;
  bookedComponents: Scalars['Int'];
  booking: Array<Booking>;
  canPost: Scalars['Boolean'];
  email: Maybe<Scalars['String']>;
  event: Event;
  eventComponents: Maybe<Array<EventComponent>>;
  firstName: Scalars['String'];
  flightInformation: Maybe<FlightInformation>;
  guests: Array<Guest>;
  id: Scalars['ID'];
  invitedBy: Maybe<GuestShort>;
  kidsAllowed: Scalars['Boolean'];
  lastName: Scalars['String'];
  mainComponent: Maybe<EventComponent>;
  phone: Maybe<Scalars['String']>;
  plusOne: Scalars['Int'];
  possibleEvents: Array<GuestEvent>;
  preInvitationSent: Scalars['Boolean'];
  reminderSentAt: Maybe<Scalars['NaiveDateTime']>;
  role: Maybe<GuestRole>;
  seen: Scalars['Boolean'];
  status: Scalars['String'];
  tiers: Array<Tier>;
};

export type GuestBookingAddon = {
  bookingAddOnId: Maybe<Scalars['ID']>;
  bookingId: Scalars['ID'];
  guestBookingInput: Maybe<GuestBookingInput>;
  offeringAddOnId: Scalars['ID'];
  offeringAddOnOptionId: Maybe<Scalars['ID']>;
};

export type GuestBookingAddonPublic = {
  bookingAddOnId: Maybe<Scalars['ID']>;
  guestBookingInput: Maybe<GuestBookingInput>;
  offeringAddOnId: Scalars['ID'];
  offeringAddOnOptionId: Maybe<Scalars['ID']>;
};

export type GuestBookingInput = {
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  plusone: Scalars['Boolean'];
};

export enum GuestEvent {
  Accept = 'ACCEPT',
  BookRoom = 'BOOK_ROOM',
  Decline = 'DECLINE',
  DeliverInvitation = 'DELIVER_INVITATION',
  PutOnHold = 'PUT_ON_HOLD',
  SendInvitation = 'SEND_INVITATION'
}

export type GuestInput = {
  canPost: Maybe<Scalars['Boolean']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  kidsAllowed: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  plusOne: Maybe<Scalars['Int']>;
};

export type GuestPayload = {
  __typename?: 'GuestPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Guest>;
};

export type GuestRole = {
  __typename?: 'GuestRole';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GuestShort = {
  __typename?: 'GuestShort';
  avatarUrl: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  flightInformation: Maybe<FlightInformation>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  plusOne: Scalars['Int'];
  plusOneCount: Scalars['Int'];
  role: Maybe<GuestRole>;
};

export type HostBasicInfo = {
  __typename?: 'HostBasicInfo';
  avatarUrl: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export enum HostBookingAction {
  Cancel = 'CANCEL',
  Confirm = 'CONFIRM'
}

export type Image = {
  __typename?: 'Image';
  assocId: Scalars['ID'];
  assocType: Scalars['String'];
  ext: Scalars['String'];
  id: Scalars['ID'];
  metadata: Scalars['Json'];
  path: Maybe<Scalars['String']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  eventId: Scalars['ID'];
  eventName: Scalars['String'];
  guestId: Scalars['ID'];
  kidsAllowed: Scalars['Boolean'];
  plusOne: Scalars['Int'];
  status: Scalars['String'];
};

export type InvitationInfo = {
  __typename?: 'InvitationInfo';
  brideFirstName: Maybe<Scalars['String']>;
  brideLastName: Maybe<Scalars['String']>;
  components: Array<EventComponent>;
  cover: Maybe<Scalars['String']>;
  defaultKidsAllowed: Scalars['Boolean'];
  defaultPlusOne: Scalars['Int'];
  description: Scalars['String'];
  groomFirstName: Maybe<Scalars['String']>;
  groomLastName: Maybe<Scalars['String']>;
  host: HostBasicInfo;
  id: Scalars['ID'];
  invitationTemplate: Maybe<InvitationTemplate>;
  mainImage: Scalars['String'];
  name: Scalars['String'];
  video: Maybe<Scalars['String']>;
};

export type InvitationTemplate = {
  __typename?: 'InvitationTemplate';
  id: Scalars['ID'];
  template: Scalars['String'];
};



export type MarketingForm = {
  __typename?: 'MarketingForm';
  assocId: Maybe<Scalars['String']>;
  assocType: Maybe<Scalars['String']>;
  formData: Scalars['String'];
  formName: Scalars['String'];
};

export type MarketingFormInput = {
  assocId: Maybe<Scalars['String']>;
  assocType: Maybe<Scalars['String']>;
  eventId: Maybe<Scalars['ID']>;
  formData: Maybe<Scalars['String']>;
  formName: Maybe<Scalars['String']>;
};

export type MyEvent = {
  __typename?: 'MyEvent';
  balances: Array<Balance>;
  brideFirstName: Maybe<Scalars['String']>;
  brideLastName: Maybe<Scalars['String']>;
  components: Array<EventComponent>;
  cover: Maybe<Scalars['String']>;
  customUrl: Maybe<Scalars['String']>;
  defaultKidsAllowed: Scalars['Boolean'];
  defaultPlusOne: Scalars['Int'];
  description: Scalars['String'];
  emailCustomText: Maybe<Scalars['String']>;
  groomFirstName: Maybe<Scalars['String']>;
  groomLastName: Maybe<Scalars['String']>;
  guestCanPost: Scalars['Boolean'];
  guestCount: Scalars['Int'];
  guests: Array<Guest>;
  id: Scalars['ID'];
  invitationTemplate: Maybe<InvitationTemplate>;
  isPrivate: Scalars['Boolean'];
  mainImage: Scalars['String'];
  name: Scalars['String'];
  negotiation: Negotiation;
  negotiations: Maybe<PaginatedNegotiationPreviews>;
  paidComponents: Scalars['Int'];
  tiers: Array<Tier>;
  video: Maybe<Scalars['String']>;
};


export type MyEventBalancesArgs = {
  eventId: Scalars['ID'];
  providers: Array<Scalars['ID']>;
};


export type MyEventComponentsArgs = {
  filterRecent: Maybe<Scalars['Boolean']>;
};


export type MyEventGuestsArgs = {
  tierIds: Maybe<Array<Scalars['ID']>>;
};


export type MyEventNegotiationArgs = {
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
};


export type MyEventNegotiationsArgs = {
  cursor: Maybe<CursorInput>;
};


export type MyEventPaidComponentsArgs = {
  eventId: Maybe<Scalars['ID']>;
};

export type MyEventPayload = {
  __typename?: 'MyEventPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<MyEvent>;
};

export type MyMarketingForm = {
  __typename?: 'MyMarketingForm';
  id: Maybe<Scalars['ID']>;
};

export type MyMarketingFormPayload = {
  __typename?: 'MyMarketingFormPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<MyMarketingForm>;
};

export type MyProvider = {
  __typename?: 'MyProvider';
  address: Maybe<Scalars['String']>;
  airportCode: Maybe<Scalars['String']>;
  bookAllRequired: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata: Scalars['Json'];
  name: Scalars['String'];
  timeToAirport: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};


/**
 * Negotiation object is a container for messages and quotes.
 * Sequence of quotes is supposed to be listed in the left panel, while messages are
 * supposed to flow like normal.
 */
export type Negotiation = {
  __typename?: 'Negotiation';
  balance: Scalars['Decimal'];
  event: Event;
  messages: Maybe<PaginatedNegotiationMessages>;
  provider: ProviderPreview;
  quotes: Array<Quote>;
};


/**
 * Negotiation object is a container for messages and quotes.
 * Sequence of quotes is supposed to be listed in the left panel, while messages are
 * supposed to flow like normal.
 */
export type NegotiationMessagesArgs = {
  cursor: Maybe<CursorInput>;
};

export type NegotiationItem = {
  __typename?: 'NegotiationItem';
  deleted: Scalars['Boolean'];
  eventComponentId: Scalars['ID'];
  id: Scalars['ID'];
  master: Scalars['Boolean'];
  price: Scalars['Decimal'];
  title: Scalars['String'];
};

export type NegotiationItemInput = {
  price: Scalars['Decimal'];
  title: Scalars['String'];
};

export type NegotiationItemPayload = {
  __typename?: 'NegotiationItemPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<NegotiationItem>;
};

export type NegotiationMessage = {
  __typename?: 'NegotiationMessage';
  body: Scalars['String'];
  id: Scalars['ID'];
  insertedAt: Maybe<Scalars['NaiveDateTime']>;
  item: Maybe<NegotiationItem>;
  seen: Scalars['Boolean'];
  user: UserProfile;
};

export type NegotiationMessageInput = {
  body: Scalars['String'];
};

export type NegotiationMessagePayload = {
  __typename?: 'NegotiationMessagePayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<NegotiationMessage>;
};

/**
 * Negotiation preview is used to display list of negotiations in chat area.
 * The returned fields will have to be refined later, we start from
 * letting chose between displaying provider name (for customer) and event name (for vendor)
 */
export type NegotiationPreview = {
  __typename?: 'NegotiationPreview';
  event: Event;
  lastUpdate: Maybe<Scalars['NaiveDateTime']>;
  provider: ProviderPreview;
};

export type NoneBody = {
  __typename?: 'NoneBody';
  body: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  dismissed: Scalars['Boolean'];
  id: Scalars['ID'];
  payload: Maybe<NotificationBody>;
  seen: Scalars['Boolean'];
  volatile: Scalars['Boolean'];
};

export type NotificationBody = EventComponentAddedBody | NoneBody | RsvpBody | SimpleNotificationBody;

export type NotificationPayload = {
  __typename?: 'NotificationPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Notification>;
};

export type Offering = PackageOffering | RoomOffering;

export type OfferingAddOnOptions = {
  __typename?: 'OfferingAddOnOptions';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  offeringAddOnId: Scalars['ID'];
  price: Maybe<Scalars['Decimal']>;
  quantity: Scalars['Int'];
};

export type OfferingAddOns = {
  __typename?: 'OfferingAddOns';
  description: Maybe<Scalars['String']>;
  format: Maybe<OfferingAddOnsFormat>;
  frequency: Maybe<OfferingAddOnsFrequency>;
  id: Scalars['ID'];
  images: Array<Image>;
  label: Scalars['String'];
  mandatory: Scalars['Boolean'];
  metadata: Scalars['Json'];
  offeringAddOnOptions: Array<OfferingAddOnOptions>;
  price: Maybe<Scalars['Decimal']>;
  quantity: Scalars['Int'];
  taxable: Scalars['Boolean'];
  type: OfferingAddOnsType;
};

export enum OfferingAddOnsFormat {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE'
}

export enum OfferingAddOnsFrequency {
  Daily = 'DAILY',
  PerBooking = 'PER_BOOKING'
}

export enum OfferingAddOnsType {
  ExtraGuest = 'EXTRA_GUEST',
  ExtraNights = 'EXTRA_NIGHTS',
  Fee = 'FEE',
  SubEvent = 'SUB_EVENT'
}

export type PackageAddons = {
  __typename?: 'PackageAddons';
  eventComponentId: Scalars['ID'];
  id: Scalars['ID'];
  location: Maybe<Scalars['String']>;
  offeringAddOn: OfferingAddOns;
  offeringAddOnOption: OfferingAddOnOptions;
  time: Maybe<Scalars['Time']>;
};

export type PackageAddonsInput = {
  eventComponentId: Scalars['ID'];
  location: Maybe<Scalars['String']>;
  offeringAddOnId: Scalars['ID'];
  offeringAddOnOptionId: Scalars['ID'];
  time: Maybe<Scalars['Time']>;
};

export type PackageAddonsPayload = {
  __typename?: 'PackageAddonsPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<PackageAddons>;
};

export type PackageDetails = {
  __typename?: 'PackageDetails';
  baseGuestNumber: Scalars['Int'];
  basePrice: Scalars['Decimal'];
  basic: Scalars['Int'];
  minimumGuests: Scalars['Int'];
};

/** Package Offering */
export type PackageOffering = {
  __typename?: 'PackageOffering';
  addOns: Array<OfferingAddOns>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Array<Image>;
  intro: Maybe<Scalars['String']>;
  mainImage: Maybe<Image>;
  metadata: Scalars['Json'];
  name: Scalars['String'];
  package: PackageDetails;
  price: Maybe<Scalars['Decimal']>;
  provider: Provider;
  quantity: Scalars['Int'];
};

export type PaginatedArticles = {
  __typename?: 'PaginatedArticles';
  cursor: Maybe<Scalars['String']>;
  entries: Maybe<Array<Maybe<Article>>>;
};

export type PaginatedComments = {
  __typename?: 'PaginatedComments';
  cursor: Maybe<Scalars['String']>;
  entries: Array<Comment>;
};

export type PaginatedEventComponentGroups = {
  __typename?: 'PaginatedEventComponentGroups';
  cursor: Maybe<Scalars['String']>;
  entries: Array<EventComponentGroup>;
};

export type PaginatedMyProviders = {
  __typename?: 'PaginatedMyProviders';
  cursor: Maybe<Scalars['String']>;
  entries: Array<MyProvider>;
};

export type PaginatedNegotiationMessages = {
  __typename?: 'PaginatedNegotiationMessages';
  cursor: Maybe<Scalars['String']>;
  entries: Array<NegotiationMessage>;
};

export type PaginatedNegotiationPreviews = {
  __typename?: 'PaginatedNegotiationPreviews';
  cursor: Maybe<Scalars['String']>;
  entries: Array<NegotiationPreview>;
};

export type PaginatedNotifications = {
  __typename?: 'PaginatedNotifications';
  cursor: Maybe<Scalars['String']>;
  entries: Maybe<Array<Maybe<Notification>>>;
};

export type PaginatedPosts = {
  __typename?: 'PaginatedPosts';
  cursor: Maybe<Scalars['String']>;
  entries: Maybe<Array<Maybe<Post>>>;
};

export type PaginatedProviders = {
  __typename?: 'PaginatedProviders';
  cursor: Maybe<Scalars['String']>;
  entries: Array<Provider>;
};

export type PasswordInput = {
  password: Scalars['String'];
  secret: Scalars['String'];
};

export type Poll = {
  __typename?: 'Poll';
  multi: Scalars['Boolean'];
  options: Array<PollOption>;
};

export type PollInput = {
  multi: Scalars['Boolean'];
  options: Array<PollOptionInput>;
};

export type PollOption = {
  __typename?: 'PollOption';
  id: Scalars['ID'];
  name: Scalars['String'];
  percent: Scalars['Decimal'];
  voted: Scalars['Boolean'];
};

export type PollOptionInput = {
  id: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type PollOptionListPayload = {
  __typename?: 'PollOptionListPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Array<PollOption>>;
};

export type Post = {
  __typename?: 'Post';
  body: Scalars['String'];
  commentCount: Scalars['Int'];
  commentsPreview: Array<Comment>;
  deadline: Maybe<Scalars['NaiveDateTime']>;
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  insertedAt: Scalars['NaiveDateTime'];
  pinned: Scalars['Boolean'];
  poll: Maybe<Poll>;
  tiers: Array<Tier>;
  user: UserProfile;
  video: Maybe<Scalars['String']>;
};

export type PostInput = {
  body: Scalars['String'];
  deadline: Maybe<Scalars['NaiveDateTime']>;
  poll: Maybe<PollInput>;
  sms: Scalars['Boolean'];
  video: Maybe<Scalars['String']>;
};

export type PostPayload = {
  __typename?: 'PostPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Post>;
};

export type PriceComponents = {
  __typename?: 'PriceComponents';
  basePrice: Scalars['Decimal'];
  duration: Scalars['Int'];
  offeringPrice: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  total: Scalars['Decimal'];
};

export type Provider = {
  __typename?: 'Provider';
  accomodationTaxRate: Scalars['Decimal'];
  address: Maybe<Scalars['String']>;
  airportCode: Maybe<Scalars['String']>;
  bookAllRequired: Maybe<Scalars['Boolean']>;
  cancellationPolicy: Maybe<Scalars['String']>;
  coord: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Array<Image>;
  location: Maybe<Scalars['String']>;
  mainImage: Maybe<Image>;
  metadata: Scalars['Json'];
  name: Scalars['String'];
  nearbyLocationsCount: Scalars['Int'];
  package: Maybe<BestPackage>;
  packages: Array<PackageOffering>;
  room: Maybe<BestRoom>;
  rooms: Array<RoomOffering>;
  timeToAirport: Maybe<Scalars['Int']>;
  url: Scalars['String'];
  venueTaxRate: Scalars['Decimal'];
};

export type ProviderPreview = {
  __typename?: 'ProviderPreview';
  id: Scalars['ID'];
  mainImage: Maybe<Image>;
  name: Scalars['String'];
  vendor: VendorBasicInfo;
};

/** Quote links event_component with negotiation items to be displayed as a block. */
export type Quote = {
  __typename?: 'Quote';
  eventComponent: EventComponent;
  items: Array<NegotiationItem>;
};

export enum Role {
  Admin = 'ADMIN',
  Concierge = 'CONCIERGE',
  Customer = 'CUSTOMER',
  Vendor = 'VENDOR'
}

export type RoomDetails = {
  __typename?: 'RoomDetails';
  basic: Scalars['Int'];
  beds: Scalars['Int'];
  occupancy: Scalars['Int'];
  size: Scalars['Int'];
};

/** Room Offering */
export type RoomOffering = {
  __typename?: 'RoomOffering';
  addOns: Array<OfferingAddOns>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Array<Image>;
  intro: Maybe<Scalars['String']>;
  mainImage: Maybe<Image>;
  marketPrice: Maybe<Scalars['Decimal']>;
  metadata: Scalars['Json'];
  name: Scalars['String'];
  price: Maybe<Scalars['Decimal']>;
  provider: Provider;
  quantity: Scalars['Int'];
  room: RoomDetails;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Add addon to booking */
  addAddonToBooking: Maybe<BookingAddOnPayload>;
  /** Add all addons to booking */
  addAllAddonsToBooking: Maybe<BookingPayload>;
  /** Paid booking */
  addAllAddonsToBookingPublic: Maybe<BookingPayload>;
  /** Add flight information for Booking */
  addFlightInformation: Maybe<FlightInformationPayload>;
  /** Add rooms to negotiation using event id and provider id. */
  addRoomsToNegotiation: Maybe<AddRoomsPayload>;
  /** Add a tier to event component. */
  addTierToEventComponent: EventComponentPayload;
  /** Assign tier to a guest */
  assignTierToGuest: Maybe<TierListPayload>;
  /**
   * Cancel booking.
   * TODO: PROCESS REFUNDS!!!
   */
  cancelBooking: Maybe<BookingPayload>;
  /**
   * Confirm booking. This is only for free components, the transition will fail on non free one.
   * Practically this means RSVP on a package or room paid by the host.
   * This action should effectively replace payment flow for components with guest_price == 0.
   */
  confirmBooking: Maybe<BookingPayload>;
  /**
   * Confirm booking public. This is only for free components, the transition will fail on non free one.
   * This means RSVP on a package or room paid by the host.
   * This action should effectively replace payment flow for components with guest_price == 0.
   */
  confirmBookingPublic: Maybe<BookingPayload>;
  /**
   * Create booking for guest.
   * TODO: Once some metadata needed for bookings (like +1 name or something) we'll need :update_booking mutation
   */
  createBooking: Maybe<BookingPayload>;
  /** Host creates booking for guest from the guest list. */
  createBookingForGuest: Maybe<BookingPayload>;
  /** Create comment / must be an Event owner or guest */
  createComment: Maybe<CommentPayload>;
  /** Create event */
  createEvent: Maybe<MyEventPayload>;
  /** Create event component adds offering to customer's event builder. */
  createEventComponent: Maybe<EventComponentPayload>;
  /** Create guest */
  createGuest: Maybe<GuestPayload>;
  /** Enter Data from Marketing Form */
  createMarketingForm: Maybe<MyMarketingFormPayload>;
  /** Enter Data from Marketing Form - Existing User */
  createMarketingFormExistingUser: Maybe<MyMarketingFormPayload>;
  /**
   * Create negotiation item with title and price, adjusting the quote.
   * This mutation is for Vendor role to be called only when event component is
   * in `negotiating` state.
   */
  createNegotiationItem: Maybe<NegotiationItemPayload>;
  /**
   * Create negotiation message.
   * Identified by event and provider, this guarantees that event components
   * from the same provider will be stacked together.
   */
  createNegotiationMessage: Maybe<NegotiationMessagePayload>;
  /** Create post / must be an Event owner */
  createPost: Maybe<PostPayload>;
  /** Create tier */
  createTier: Maybe<TierPayload>;
  /** Create token */
  createToken: Maybe<TokenPayload>;
  /** Create vendor account */
  createVendor: Maybe<VendorPayload>;
  /** Customize booking duration */
  customizeBookingDuration: Maybe<BookingPayload>;
  /**
   * Decline booking. This is only for free components, the transition will fail on non free one.
   * For now this is only for packages but can be extended to rooms some day.
   */
  declineBooking: Maybe<BookingPayload>;
  /** Delete comment / must be Comment author */
  deleteComment: Maybe<CommentPayload>;
  /** Delete event */
  deleteEvent: Maybe<MyEventPayload>;
  /** Delete flight information for Booking */
  deleteFlightInformation: Maybe<FlightInformationPayload>;
  /** Delete guest */
  deleteGuest: Maybe<GuestPayload>;
  /**
   * Delete negotiation item.
   * Allowed for vendors when event component is in `negotiating` state.
   */
  deleteNegotiationItem: Maybe<NegotiationItemPayload>;
  /** Delete package customization */
  deletePackageCustomization: Maybe<PackageAddonsPayload>;
  /** Delete post / must be an Event owner */
  deletePost: Maybe<PostPayload>;
  /** Update tier */
  deleteTier: Maybe<TierPayload>;
  /**
   * Edit booking. This is only for free stuff
   * Right now we only allow editing free guests. In the future we can allow editing paid stuff as well
   * generating a new payment session for the difference or refunding the difference.
   */
  editBooking: Maybe<BookingPayload>;
  /** Edit existing package customization */
  editPackageCustomization: Maybe<PackageAddonsPayload>;
  /** Create user token to login */
  getUserToken: Maybe<TokenPayload>;
  /** Create payment session for guest based on booking_id. */
  initiateGuestPayment: Maybe<StripeSessionPayload>;
  /** Create payment session for guest based on booking_id */
  initiateGuestPaymentPublic: Maybe<StripeSessionPayload>;
  /** Create payment session for host. */
  initiateHostPayment: Maybe<StripeSessionPayload>;
  /** Manage guest booking by Host. */
  manageGuestBooking: Maybe<BookingPayload>;
  /**
   * Mark messages as seen.
   * messages in a chat are marked as seen based on the provider_id and event_id
   */
  markMessagesAsSeen: Maybe<ViewedMessagesPayload>;
  /** Dismiss notification */
  notificationDismissed: Maybe<NotificationPayload>;
  /** Mark notification as seen */
  notificationSeen: Maybe<NotificationPayload>;
  /** Password Reset Link */
  passwordResetLink: Maybe<UserPayload>;
  /** Pin post / must be an Event owner */
  pinPost: Maybe<PostPayload>;
  /** Refresh token */
  refreshToken: Maybe<TokenUpdatePayload>;
  /** Remove addon from booking */
  removeAddonFromBooking: Maybe<BookingAddOnPayload>;
  /** Remove tier from event component. */
  removeTierFromEventComponent: EventComponentPayload;
  /** Password Reset */
  resetPassword: Maybe<TokenPayload>;
  /** Resign tier from a guest */
  resignTierFromGuest: Maybe<TierListPayload>;
  /** Save package customization. */
  savePackageCustomization: Maybe<PackageAddonsPayload>;
  /** Send Invitation to guest */
  sendInvitation: Maybe<GuestPayload>;
  /** Send Pre Invitation to guest */
  sendPreInvitation: Maybe<GuestPayload>;
  /** Send Reminder to guest */
  sendReminder: Maybe<GuestPayload>;
  /**
   * Makes a component "main" after setting the main property
   * of the other components to false.
   */
  setMainComponent: Maybe<EventComponentPayload>;
  /** Set password for a user that has been created from Landing Page */
  setPasswordLandingUser: Maybe<VerifyAccountPayload>;
  /** Track view */
  trackView: Maybe<TrackPayload>;
  /**
   * Trigger an event on EventComponent.
   * `NEGOTIATE` and `CANCEL` are to be called by customer.
   * `CONFIRM` is to be called by vendor (to confirm the quote).
   */
  triggerEventComponentEvent: Maybe<EventComponentPayload>;
  /** Trigger event on guest */
  triggerGuestEvent: Maybe<GuestPayload>;
  /** Unpin post / must be an Event owner */
  unpinPost: Maybe<PostPayload>;
  /** Update comment / must be Comment author */
  updateComment: Maybe<CommentPayload>;
  /** Update event */
  updateEvent: Maybe<MyEventPayload>;
  /**
   * Update event component used to update date, duration or quantity.
   * Only customer can update a component in `created` state,
   * later in `negotiating` it can only be updated by a vendor.
   */
  updateEventComponent: Maybe<EventComponentPayload>;
  /** Update flight information for Booking */
  updateFlightInformation: Maybe<FlightInformationPayload>;
  /** Update guest */
  updateGuest: Maybe<GuestPayload>;
  /** Update my invitation (accept, decline, pending) - Guests (RSVP) */
  updateMyInvitation: Maybe<GuestPayload>;
  /** Update post / must be an Event owner */
  updatePost: Maybe<PostPayload>;
  /** Update tier */
  updateTier: Maybe<TierPayload>;
  /** Update user account */
  updateUser: Maybe<UserPayload>;
  /** Validate payment intent for guest. */
  validateGuestPayment: Maybe<ValidateGuestPaymentPayload>;
  /** Validate payment intent for public users */
  validateGuestPaymentPublic: Maybe<ValidateGuestPaymentPayload>;
  /** Verify account */
  verifyAccount: Maybe<VerifyAccountPayload>;
  /** Vote in poll */
  vote: Maybe<PollOptionListPayload>;
};


export type RootMutationTypeAddAddonToBookingArgs = {
  bookingId: Scalars['ID'];
  guestBookingInput: Maybe<GuestBookingInput>;
  offeringAddOnId: Scalars['ID'];
  offeringAddOnOptionId: Maybe<Scalars['ID']>;
};


export type RootMutationTypeAddAllAddonsToBookingArgs = {
  addonsToDelete: Maybe<Array<Scalars['ID']>>;
  bookingId: Scalars['ID'];
  extraNights: Maybe<ExtraNightsAddon>;
  freeGuests: Maybe<Array<Maybe<GuestBookingAddon>>>;
  paidGuests: Maybe<Array<Maybe<GuestBookingAddon>>>;
};


export type RootMutationTypeAddAllAddonsToBookingPublicArgs = {
  addonsToDelete: Maybe<Array<Scalars['ID']>>;
  eventComponentId: Scalars['ID'];
  extraNights: Maybe<ExtraNightsAddonPublic>;
  freeGuests: Maybe<Array<Maybe<GuestBookingAddonPublic>>>;
  guestData: GuestInput;
  paidGuests: Maybe<Array<Maybe<GuestBookingAddonPublic>>>;
};


export type RootMutationTypeAddFlightInformationArgs = {
  guestId: Scalars['ID'];
  input: FlightInformationInput;
};


export type RootMutationTypeAddRoomsToNegotiationArgs = {
  eventId: Scalars['ID'];
  providerUrl: Scalars['String'];
};


export type RootMutationTypeAddTierToEventComponentArgs = {
  id: Scalars['ID'];
  tierId: Scalars['ID'];
};


export type RootMutationTypeAssignTierToGuestArgs = {
  guestId: Scalars['ID'];
  tierId: Scalars['ID'];
};


export type RootMutationTypeCancelBookingArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeConfirmBookingArgs = {
  addonsToDelete: Maybe<Array<Scalars['ID']>>;
  bookingId: Scalars['ID'];
  extraNights: Maybe<ExtraNightsAddon>;
  freeGuests: Maybe<Array<Maybe<GuestBookingAddon>>>;
  paidGuests: Maybe<Array<Maybe<GuestBookingAddon>>>;
};


export type RootMutationTypeConfirmBookingPublicArgs = {
  addonsToDelete: Maybe<Array<Scalars['ID']>>;
  eventComponentId: Scalars['ID'];
  extraNights: Maybe<ExtraNightsAddonPublic>;
  freeGuests: Maybe<Array<Maybe<GuestBookingAddonPublic>>>;
  guestData: GuestInput;
  paidGuests: Maybe<Array<Maybe<GuestBookingAddonPublic>>>;
};


export type RootMutationTypeCreateBookingArgs = {
  eventComponentId: Scalars['ID'];
};


export type RootMutationTypeCreateBookingForGuestArgs = {
  eventComponentId: Scalars['ID'];
  guestId: Scalars['ID'];
};


export type RootMutationTypeCreateCommentArgs = {
  input: CommentInput;
  postId: Scalars['ID'];
};


export type RootMutationTypeCreateEventArgs = {
  input: EventInput;
};


export type RootMutationTypeCreateEventComponentArgs = {
  eventId: Scalars['ID'];
  input: EventComponentInput;
  offeringId: Scalars['ID'];
};


export type RootMutationTypeCreateGuestArgs = {
  eventId: Scalars['ID'];
  input: GuestInput;
  tierIds: Maybe<Array<Scalars['ID']>>;
};


export type RootMutationTypeCreateMarketingFormArgs = {
  input: MarketingFormInput;
};


export type RootMutationTypeCreateMarketingFormExistingUserArgs = {
  input: MarketingFormInput;
};


export type RootMutationTypeCreateNegotiationItemArgs = {
  eventComponentId: Scalars['ID'];
  input: NegotiationItemInput;
};


export type RootMutationTypeCreateNegotiationMessageArgs = {
  eventId: Scalars['ID'];
  input: NegotiationMessageInput;
  providerId: Scalars['ID'];
};


export type RootMutationTypeCreatePostArgs = {
  eventId: Scalars['ID'];
  input: PostInput;
  tierIds: Array<Scalars['ID']>;
};


export type RootMutationTypeCreateTierArgs = {
  eventId: Scalars['ID'];
  input: TierInput;
};


export type RootMutationTypeCreateTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootMutationTypeCreateVendorArgs = {
  input: VendorInput;
  token: Scalars['String'];
};


export type RootMutationTypeCustomizeBookingDurationArgs = {
  addonNightsAfterId: Maybe<Scalars['ID']>;
  addonNightsBeforeId: Maybe<Scalars['ID']>;
  bookingId: Scalars['ID'];
  date: Maybe<Scalars['NaiveDateTime']>;
  duration: Maybe<Scalars['Int']>;
  optionNightsAfterId: Maybe<Scalars['ID']>;
  optionNightsBeforeId: Maybe<Scalars['ID']>;
};


export type RootMutationTypeDeclineBookingArgs = {
  guestId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  offeringAddOnId: Maybe<Scalars['ID']>;
};


export type RootMutationTypeDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteEventArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteFlightInformationArgs = {
  guestId: Scalars['ID'];
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteGuestArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteNegotiationItemArgs = {
  negotiationItemId: Scalars['ID'];
};


export type RootMutationTypeDeletePackageCustomizationArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeletePostArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteTierArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeEditBookingArgs = {
  addonsToDelete: Maybe<Array<Scalars['ID']>>;
  bookingId: Scalars['ID'];
  freeGuests: Maybe<Array<Maybe<GuestBookingAddon>>>;
};


export type RootMutationTypeEditPackageCustomizationArgs = {
  id: Scalars['ID'];
  input: PackageAddonsInput;
};


export type RootMutationTypeGetUserTokenArgs = {
  token: Scalars['String'];
};


export type RootMutationTypeInitiateGuestPaymentArgs = {
  bookingId: Scalars['ID'];
};


export type RootMutationTypeInitiateGuestPaymentPublicArgs = {
  bookingId: Scalars['ID'];
};


export type RootMutationTypeInitiateHostPaymentArgs = {
  amount: Scalars['Decimal'];
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
};


export type RootMutationTypeManageGuestBookingArgs = {
  action: HostBookingAction;
  eventId: Scalars['ID'];
  id: Scalars['ID'];
};


export type RootMutationTypeMarkMessagesAsSeenArgs = {
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
};


export type RootMutationTypeNotificationDismissedArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeNotificationSeenArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypePasswordResetLinkArgs = {
  email: Maybe<Scalars['String']>;
};


export type RootMutationTypePinPostArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeRefreshTokenArgs = {
  token: Scalars['String'];
};


export type RootMutationTypeRemoveAddonFromBookingArgs = {
  bookingAddonId: Scalars['ID'];
  bookingId: Scalars['ID'];
};


export type RootMutationTypeRemoveTierFromEventComponentArgs = {
  id: Scalars['ID'];
  tierId: Scalars['ID'];
};


export type RootMutationTypeResetPasswordArgs = {
  input: PasswordInput;
};


export type RootMutationTypeResignTierFromGuestArgs = {
  guestId: Scalars['ID'];
  tierId: Scalars['ID'];
};


export type RootMutationTypeSavePackageCustomizationArgs = {
  input: PackageAddonsInput;
};


export type RootMutationTypeSendInvitationArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeSendPreInvitationArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeSendReminderArgs = {
  guestId: Scalars['ID'];
  template: Scalars['String'];
};


export type RootMutationTypeSetMainComponentArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeSetPasswordLandingUserArgs = {
  event: Maybe<Scalars['ID']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type RootMutationTypeTrackViewArgs = {
  destination: Scalars['String'];
  eventId: Maybe<Scalars['ID']>;
  guestId: Maybe<Scalars['ID']>;
  source: Scalars['String'];
};


export type RootMutationTypeTriggerEventComponentEventArgs = {
  event: Maybe<EventComponentEvent>;
  id: Maybe<Scalars['ID']>;
};


export type RootMutationTypeTriggerGuestEventArgs = {
  event: Maybe<GuestEvent>;
  guestId: Maybe<Scalars['ID']>;
};


export type RootMutationTypeUnpinPostArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeUpdateCommentArgs = {
  id: Scalars['ID'];
  input: CommentInput;
};


export type RootMutationTypeUpdateEventArgs = {
  id: Scalars['ID'];
  input: EventInput;
};


export type RootMutationTypeUpdateEventComponentArgs = {
  id: Scalars['ID'];
  input: EventComponentInput;
};


export type RootMutationTypeUpdateFlightInformationArgs = {
  guestId: Scalars['ID'];
  input: FlightInformationInput;
};


export type RootMutationTypeUpdateGuestArgs = {
  id: Scalars['ID'];
  input: GuestInput;
  tierIds: Maybe<Array<Scalars['ID']>>;
};


export type RootMutationTypeUpdateMyInvitationArgs = {
  event: GuestEvent;
  guestId: Scalars['ID'];
};


export type RootMutationTypeUpdatePostArgs = {
  id: Scalars['ID'];
  input: PostInput;
  tierIds: Array<Scalars['ID']>;
};


export type RootMutationTypeUpdateTierArgs = {
  id: Scalars['ID'];
  input: TierInput;
};


export type RootMutationTypeUpdateUserArgs = {
  input: UserBasicData;
};


export type RootMutationTypeValidateGuestPaymentArgs = {
  paymentIntent: Scalars['String'];
};


export type RootMutationTypeValidateGuestPaymentPublicArgs = {
  paymentIntent: Scalars['String'];
};


export type RootMutationTypeVerifyAccountArgs = {
  event: Maybe<Scalars['String']>;
  secret: Scalars['String'];
};


export type RootMutationTypeVoteArgs = {
  optionId: Scalars['ID'];
  postId: Scalars['ID'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Available packages (RSVP) for specific event and guest (optional) */
  availablePackages: Array<EventComponent>;
  /** Blog entry point, returns articles */
  blog: Maybe<PaginatedArticles>;
  booking: Maybe<Booking>;
  /** Get booking data for host */
  bookingAsHost: Maybe<Booking>;
  /** Get booking by id fro public use */
  bookingPublic: Maybe<Booking>;
  bookings: Array<Booking>;
  /** Check role of the user for specific event */
  checkRole: GuestShort;
  /** Comments feed for given post_id */
  comments: PaginatedComments;
  /** Feed entry point, returns posts */
  feed: PaginatedPosts;
  /** Feed event components available for guests */
  feedComponentGroups: PaginatedEventComponentGroups;
  /** Get event by custom url */
  getEvent: Maybe<Event>;
  /** Get prices for event component */
  getEventComponentPrices: PriceComponents;
  /** Get event components for public users */
  getEventComponents: Array<EventComponent>;
  /** Guest list by tier id or all guests by event id */
  guestList: Array<GuestShort>;
  /** Get marketing form by assoc_id */
  marketingFormByAssocId: Maybe<MarketingForm>;
  /** Provider entry point, returns provider(s) */
  marketplace: Maybe<PaginatedProviders>;
  /** Token owner's profile (to be loaded on client side init) */
  me: Maybe<User>;
  /** Notifications for current user (cursored) */
  notifications: Maybe<PaginatedNotifications>;
  provider: Maybe<Provider>;
  providers: Array<Provider>;
  /** Voted users visible for poll owner only */
  votes: Array<UserProfile>;
};


export type RootQueryTypeAvailablePackagesArgs = {
  eventId: Scalars['ID'];
  guestId: Maybe<Scalars['ID']>;
};


export type RootQueryTypeBlogArgs = {
  articleId: Maybe<Scalars['ID']>;
  cursor: Maybe<CursorInput>;
  url: Maybe<Scalars['String']>;
};


export type RootQueryTypeBookingArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};


export type RootQueryTypeBookingAsHostArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeBookingPublicArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeBookingsArgs = {
  ids: Array<Scalars['ID']>;
};


export type RootQueryTypeCheckRoleArgs = {
  eventId: Scalars['ID'];
};


export type RootQueryTypeCommentsArgs = {
  cursor: Maybe<CursorInput>;
  postId: Scalars['ID'];
};


export type RootQueryTypeFeedArgs = {
  cursor: Maybe<CursorInput>;
  eventIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type RootQueryTypeFeedComponentGroupsArgs = {
  cursor: Maybe<CursorInput>;
  eventIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type RootQueryTypeGetEventArgs = {
  customUrl: Scalars['String'];
};


export type RootQueryTypeGetEventComponentPricesArgs = {
  eventComponentId: Scalars['ID'];
};


export type RootQueryTypeGetEventComponentsArgs = {
  eventId: Scalars['ID'];
};


export type RootQueryTypeGuestListArgs = {
  eventId: Maybe<Scalars['ID']>;
  tierId: Maybe<Scalars['ID']>;
};


export type RootQueryTypeMarketingFormByAssocIdArgs = {
  assocId: Scalars['ID'];
  formName: Scalars['String'];
};


export type RootQueryTypeMarketplaceArgs = {
  cursor: Maybe<CursorInput>;
  groupSize?: Scalars['Int'];
  maxPackagePrice: Maybe<Scalars['Decimal']>;
  maxRoomPrice: Maybe<Scalars['Decimal']>;
  what: Maybe<Scalars['String']>;
  where: Maybe<Scalars['Location']>;
};


export type RootQueryTypeNotificationsArgs = {
  cursor: Maybe<CursorInput>;
};


export type RootQueryTypeProviderArgs = {
  url: Scalars['String'];
};


export type RootQueryTypeProvidersArgs = {
  ids: Array<Scalars['ID']>;
};


export type RootQueryTypeVotesArgs = {
  optionId: Scalars['ID'];
  postId: Scalars['ID'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  /** Listening for balance update while being on a negotiation page. */
  balanceUpdates: Maybe<BalanceUpdate>;
  /** Listening for deleted addons in event_components. */
  eventComponentsAddonsDelete: Maybe<PackageAddons>;
  /** Listening for changes in event_components using event_id. */
  eventComponentsUpdate: Maybe<PackageAddons>;
  /** Listening for new messages in negotiation flows based on combination of event_id and provider_id. */
  negotiationMessages: Maybe<NegotiationMessage>;
  /** Push notifications subscription. */
  notificationsPush: Maybe<Notification>;
  /**
   * Tracking incoming posts in Event's feed. When post created, event id is returned.
   * Client must collect ids separately, so that when filtering criteria changed,
   * number of outstanding posts could be recomputed properly.
   */
  postAdded: Maybe<Scalars['ID']>;
};


export type RootSubscriptionTypeBalanceUpdatesArgs = {
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
};


export type RootSubscriptionTypeEventComponentsAddonsDeleteArgs = {
  eventId: Scalars['ID'];
};


export type RootSubscriptionTypeEventComponentsUpdateArgs = {
  eventId: Scalars['ID'];
};


export type RootSubscriptionTypeNegotiationMessagesArgs = {
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
};


export type RootSubscriptionTypePostAddedArgs = {
  eventId: Scalars['ID'];
};

export type RsvpBody = {
  __typename?: 'RsvpBody';
  eventComponentId: Scalars['ID'];
  eventComponentName: Scalars['String'];
  guestId: Scalars['ID'];
  guestName: Scalars['String'];
};

export type SimpleNotificationBody = {
  __typename?: 'SimpleNotificationBody';
  message: Scalars['String'];
  meta: Scalars['Json'];
};

export type StripeSession = {
  __typename?: 'StripeSession';
  amount: Scalars['Int'];
  clientSecret: Scalars['String'];
  id: Scalars['String'];
};

export type StripeSessionPayload = {
  __typename?: 'StripeSessionPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<StripeSession>;
};

export type Tier = {
  __typename?: 'Tier';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TierInput = {
  name: Maybe<Scalars['String']>;
};

export type TierListPayload = {
  __typename?: 'TierListPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Array<Tier>>;
};

export type TierPayload = {
  __typename?: 'TierPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Tier>;
};


export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String'];
  accessTokenExpiresAt: Scalars['Int'];
  avatarUrl: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['Int'];
  role: Role;
  userId: Scalars['ID'];
};

export type TokenPayload = {
  __typename?: 'TokenPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Token>;
};

export type TokenUpdate = {
  __typename?: 'TokenUpdate';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type TokenUpdatePayload = {
  __typename?: 'TokenUpdatePayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<TokenUpdate>;
};

export type Track = {
  __typename?: 'Track';
  destination: Scalars['String'];
  eventId: Maybe<Scalars['ID']>;
  guestId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  source: Scalars['String'];
};

export type TrackPayload = {
  __typename?: 'TrackPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Track>;
};

export type User = {
  avatarUrl: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type UserBasicData = {
  avatarUrl: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<User>;
};

export type UserProfile = User & {
  __typename?: 'UserProfile';
  avatarUrl: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type ValidateGuestPayment = {
  __typename?: 'ValidateGuestPayment';
  booking: Booking;
  paymentStatus: Scalars['String'];
};

export type ValidateGuestPaymentPayload = {
  __typename?: 'ValidateGuestPaymentPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<ValidateGuestPayment>;
};

export type Vendor = User & {
  __typename?: 'Vendor';
  avatarUrl: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  myProviders: Maybe<PaginatedMyProviders>;
  negotiation: Negotiation;
  negotiations: Maybe<PaginatedNegotiationPreviews>;
};


export type VendorMyProvidersArgs = {
  cursor: Maybe<CursorInput>;
};


export type VendorNegotiationArgs = {
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
};


export type VendorNegotiationsArgs = {
  cursor: Maybe<CursorInput>;
};

export type VendorBasicInfo = {
  __typename?: 'VendorBasicInfo';
  avatarUrl: Maybe<Scalars['String']>;
};

export type VendorInput = {
  avatarUrl: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
};

export type VendorPayload = {
  __typename?: 'VendorPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<Vendor>;
};

export type VerifyAccount = {
  __typename?: 'VerifyAccount';
  isVerified: Maybe<Scalars['Boolean']>;
  token: Maybe<Token>;
};

export type VerifyAccountPayload = {
  __typename?: 'VerifyAccountPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<VerifyAccount>;
};

export type ViewedMessages = {
  __typename?: 'ViewedMessages';
  marked: Scalars['Boolean'];
};

export type ViewedMessagesPayload = {
  __typename?: 'ViewedMessagesPayload';
  errors: Maybe<Array<Maybe<Error>>>;
  result: Maybe<ViewedMessages>;
};

export type CreateTokenMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateTokenMutation = (
  { __typename?: 'RootMutationType' }
  & { createToken: Maybe<(
    { __typename?: 'TokenPayload' }
    & { result: Maybe<(
      { __typename?: 'Token' }
      & Pick<Token, 'accessToken' | 'avatarUrl' | 'firstName' | 'lastName' | 'refreshToken' | 'role' | 'userId' | 'email'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RefreshTokenMutation = (
  { __typename?: 'RootMutationType' }
  & { refreshToken: Maybe<(
    { __typename?: 'TokenUpdatePayload' }
    & { result: Maybe<(
      { __typename?: 'TokenUpdate' }
      & Pick<TokenUpdate, 'accessToken' | 'refreshToken'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  input: PasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'RootMutationType' }
  & { resetPassword: Maybe<(
    { __typename?: 'TokenPayload' }
    & { result: Maybe<(
      { __typename?: 'Token' }
      & Pick<Token, 'accessToken' | 'accessTokenExpiresAt' | 'avatarUrl' | 'firstName' | 'lastName' | 'refreshToken' | 'refreshTokenExpiresAt'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type VerifyAccountMutationVariables = Exact<{
  secret: Scalars['String'];
  event: Maybe<Scalars['String']>;
}>;


export type VerifyAccountMutation = (
  { __typename?: 'RootMutationType' }
  & { verifyAccount: Maybe<(
    { __typename?: 'VerifyAccountPayload' }
    & { errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'VerifyAccount' }
      & Pick<VerifyAccount, 'isVerified'>
      & { token: Maybe<(
        { __typename?: 'Token' }
        & Pick<Token, 'accessToken' | 'avatarUrl' | 'firstName' | 'lastName' | 'refreshToken' | 'role' | 'userId'>
      )> }
    )> }
  )> }
);

export type PasswordResetLinkMutationVariables = Exact<{
  email: Maybe<Scalars['String']>;
}>;


export type PasswordResetLinkMutation = (
  { __typename?: 'RootMutationType' }
  & { passwordResetLink: Maybe<(
    { __typename?: 'UserPayload' }
    & { errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type SetPasswordLandingUserMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  event: Maybe<Scalars['ID']>;
}>;


export type SetPasswordLandingUserMutation = (
  { __typename?: 'RootMutationType' }
  & { setPasswordLandingUser: Maybe<(
    { __typename?: 'VerifyAccountPayload' }
    & { result: Maybe<(
      { __typename?: 'VerifyAccount' }
      & Pick<VerifyAccount, 'isVerified'>
      & { token: Maybe<(
        { __typename?: 'Token' }
        & Pick<Token, 'accessToken' | 'avatarUrl' | 'firstName' | 'lastName' | 'refreshToken' | 'role' | 'userId'>
      )> }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetUserTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetUserTokenMutation = (
  { __typename?: 'RootMutationType' }
  & { getUserToken: Maybe<(
    { __typename?: 'TokenPayload' }
    & { errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'Token' }
      & Pick<Token, 'accessToken' | 'avatarUrl' | 'firstName' | 'lastName' | 'refreshToken' | 'role' | 'userId'>
    )> }
  )> }
);

export type GetBlogPostsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  cursor: Maybe<Scalars['String']>;
}>;


export type GetBlogPostsQuery = (
  { __typename?: 'RootQueryType' }
  & { blog: Maybe<(
    { __typename?: 'PaginatedArticles' }
    & Pick<PaginatedArticles, 'cursor'>
    & { entries: Maybe<Array<Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id' | 'body' | 'title' | 'url' | 'location'>
      & { images: Array<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )> }
    )>>> }
  )> }
);

export type GetBlogPostQueryVariables = Exact<{
  url: Maybe<Scalars['String']>;
}>;


export type GetBlogPostQuery = (
  { __typename?: 'RootQueryType' }
  & { blog: Maybe<(
    { __typename?: 'PaginatedArticles' }
    & { entries: Maybe<Array<Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'author' | 'body' | 'id' | 'insertedAt' | 'location' | 'title' | 'url'>
      & { credits: Array<(
        { __typename?: 'Credit' }
        & Pick<Credit, 'business' | 'service'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )> }
    )>>> }
  )> }
);

export type GuestFieldsWithBookingFragment = (
  { __typename?: 'Guest' }
  & Pick<Guest, 'avatarUrl' | 'canPost' | 'email' | 'firstName' | 'id' | 'kidsAllowed' | 'lastName' | 'phone' | 'plusOne' | 'status' | 'seen' | 'preInvitationSent' | 'reminderSentAt'>
  & { tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )>, flightInformation: Maybe<(
    { __typename?: 'FlightInformation' }
    & FlightDataFragment
  )>, booking: Array<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'status'>
    & { guest: (
      { __typename?: 'GuestShort' }
      & Pick<GuestShort, 'id'>
    ), bookingAddOn: Array<(
      { __typename?: 'BookingAddOn' }
      & Pick<BookingAddOn, 'id'>
      & { bookingGuest: Maybe<(
        { __typename?: 'BookingGuest' }
        & Pick<BookingGuest, 'email' | 'firstName' | 'lastName' | 'plusone'>
        & { guest: Maybe<(
          { __typename?: 'GuestShort' }
          & Pick<GuestShort, 'id'>
        )> }
      )> }
    )>, eventComponent: (
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'id'>
      & { offering: { __typename: 'PackageOffering' } | { __typename: 'RoomOffering' } }
    ) }
  )> }
);

export type GuestFieldsFragment = (
  { __typename?: 'Guest' }
  & Pick<Guest, 'avatarUrl' | 'canPost' | 'email' | 'firstName' | 'id' | 'kidsAllowed' | 'lastName' | 'phone' | 'plusOne' | 'status'>
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name'>
  ), tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )> }
);

export type EventFieldsFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'defaultKidsAllowed' | 'defaultPlusOne' | 'guestCanPost' | 'id' | 'name' | 'customUrl' | 'isPrivate' | 'description' | 'mainImage'>
  & { guests: Array<(
    { __typename?: 'Guest' }
    & GuestFieldsFragment
  )>, tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )> }
);

export type MessagesFieldsFragment = (
  { __typename?: 'NegotiationMessage' }
  & Pick<NegotiationMessage, 'body' | 'id' | 'insertedAt' | 'seen'>
  & { item: Maybe<(
    { __typename?: 'NegotiationItem' }
    & Pick<NegotiationItem, 'deleted' | 'id' | 'price' | 'title' | 'eventComponentId'>
  )>, user: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'avatarUrl' | 'firstName' | 'id' | 'lastName'>
  ) }
);

export type NegotiationItemFieldsFragment = (
  { __typename?: 'NegotiationItem' }
  & Pick<NegotiationItem, 'deleted' | 'id' | 'master' | 'price' | 'title'>
);

export type CreateEventMutationVariables = Exact<{
  input: EventInput;
}>;


export type CreateEventMutation = (
  { __typename?: 'RootMutationType' }
  & { createEvent: Maybe<(
    { __typename?: 'MyEventPayload' }
    & { result: Maybe<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id' | 'defaultKidsAllowed' | 'defaultPlusOne' | 'guestCanPost' | 'name' | 'description' | 'emailCustomText' | 'mainImage'>
      & { tiers: Array<(
        { __typename?: 'Tier' }
        & Pick<Tier, 'id' | 'name'>
      )> }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type UpdateEventMutationVariables = Exact<{
  id: Scalars['ID'];
  input: EventInput;
}>;


export type UpdateEventMutation = (
  { __typename?: 'RootMutationType' }
  & { updateEvent: Maybe<(
    { __typename?: 'MyEventPayload' }
    & { result: Maybe<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id' | 'name' | 'defaultKidsAllowed' | 'defaultPlusOne' | 'description' | 'emailCustomText' | 'mainImage' | 'isPrivate' | 'customUrl'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEventMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteEvent: Maybe<(
    { __typename?: 'MyEventPayload' }
    & { result: Maybe<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetMyEventQueryVariables = Exact<{
  id: Scalars['ID'];
  tierIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetMyEventQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & MyEventFieldsCommonFragment
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & MyEventFieldsCommonFragment
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & MyEventFieldsCommonFragment
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type MyEventFieldsCommonFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'defaultKidsAllowed' | 'defaultPlusOne' | 'guestCanPost' | 'mainImage' | 'id' | 'name' | 'paidComponents'>
  & { tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )>, guests: Array<(
    { __typename?: 'Guest' }
    & GuestFieldsFragment
  )> }
);

export type GetMyEventInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMyEventInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & CommonEventFieldsFragment
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & CommonEventFieldsFragment
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & CommonEventFieldsFragment
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type CommonEventFieldsFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'paidComponents' | 'emailCustomText'>
  & { components: Array<(
    { __typename?: 'EventComponent' }
    & Pick<EventComponent, 'main' | 'date'>
    & { offering: (
      { __typename: 'PackageOffering' }
      & { provider: (
        { __typename?: 'Provider' }
        & Pick<Provider, 'name' | 'address' | 'location'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )> }
      ) }
    ) | (
      { __typename: 'RoomOffering' }
      & { provider: (
        { __typename?: 'Provider' }
        & Pick<Provider, 'name' | 'address' | 'location'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )> }
      ) }
    ) }
  )> }
  & EventFieldsFragment
);

export type GetAllEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEventsQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & GetAllEventsCommonMeFragment
    )> }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & GetAllEventsCommonMeFragment
    )> }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & GetAllEventsCommonMeFragment
    )>, eventList: Array<(
      { __typename?: 'Event' }
      & GetAllEventsCommonEFragment
    )> }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetAllEventsCommonMeFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'defaultKidsAllowed' | 'defaultPlusOne' | 'guestCanPost' | 'id' | 'name' | 'mainImage'>
  & { tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )> }
);

export type GetAllEventsCommonEFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'mainImage'>
  & { guestProfile: (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id' | 'canPost'>
  ), host: (
    { __typename?: 'HostBasicInfo' }
    & Pick<HostBasicInfo, 'avatarUrl'>
  ), tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )> }
);

export type GetMyEventsIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyEventsIdsQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id'>
    )> }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id'>
    )> }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id'>
    )> }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetMyEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyEventsQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & GetMyEventsCommonFragment
    )> }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & GetMyEventsCommonFragment
    )> }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & GetMyEventsCommonFragment
    )> }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetMyEventsCommonFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'id' | 'name'>
  & { components: Array<(
    { __typename?: 'EventComponent' }
    & { offering: (
      { __typename: 'PackageOffering' }
      & Pick<PackageOffering, 'id'>
    ) | (
      { __typename: 'RoomOffering' }
      & Pick<RoomOffering, 'id'>
    ) }
  )> }
);

export type GetEventBalancesQueryVariables = Exact<{
  eventId: Scalars['ID'];
  providers: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetEventBalancesQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetEventBalancesCommonFragment
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetEventBalancesCommonFragment
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetEventBalancesCommonFragment
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetEventBalancesCommonFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'id'>
  & { balances: Array<(
    { __typename?: 'Balance' }
    & Pick<Balance, 'balance' | 'provider'>
    & { lastMessage: Maybe<(
      { __typename?: 'NegotiationMessage' }
      & Pick<NegotiationMessage, 'seen'>
      & { user: (
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id'>
      ) }
    )>, quotes: Array<(
      { __typename?: 'Quote' }
      & { eventComponent: (
        { __typename?: 'EventComponent' }
        & Pick<EventComponent, 'id' | 'status' | 'date'>
        & { offering: { __typename: 'PackageOffering' } | { __typename: 'RoomOffering' } }
      ), items: Array<(
        { __typename?: 'NegotiationItem' }
        & Pick<NegotiationItem, 'deleted' | 'price'>
      )> }
    )> }
  )> }
);

export type GetEventComponentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEventComponentsQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetEventComponentsCommonFragment
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetEventComponentsCommonFragment
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetEventComponentsCommonFragment
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetEventComponentsCommonFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'id' | 'name'>
  & { tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )>, components: Array<(
    { __typename?: 'EventComponent' }
    & Pick<EventComponent, 'main' | 'capacity' | 'date' | 'duration' | 'id' | 'guestPays' | 'status'>
    & { tiers: Array<(
      { __typename?: 'Tier' }
      & Pick<Tier, 'id'>
    )>, offering: (
      { __typename: 'PackageOffering' }
      & PackageFieldsFragment
    ) | (
      { __typename: 'RoomOffering' }
      & RoomFieldsFragment
    ), items: Array<(
      { __typename?: 'NegotiationItem' }
      & Pick<NegotiationItem, 'price' | 'deleted'>
    )>, packageCustomization: Array<(
      { __typename?: 'PackageAddons' }
      & PackageAddonsFieldsFragment
    )> }
  )> }
);

export type CreateEventComponentMutationVariables = Exact<{
  eventId: Scalars['ID'];
  offeringId: Scalars['ID'];
  input: EventComponentInput;
}>;


export type CreateEventComponentMutation = (
  { __typename?: 'RootMutationType' }
  & { createEventComponent: Maybe<(
    { __typename?: 'EventComponentPayload' }
    & { result: Maybe<(
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'capacity' | 'date' | 'duration' | 'id' | 'guestPays' | 'status' | 'main'>
      & { tiers: Array<(
        { __typename?: 'Tier' }
        & Pick<Tier, 'id'>
      )>, offering: (
        { __typename: 'PackageOffering' }
        & PackageFieldsFragment
      ) | (
        { __typename: 'RoomOffering' }
        & RoomFieldsFragment
      ) }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type UpdateEventComponentMutationVariables = Exact<{
  id: Scalars['ID'];
  input: EventComponentInput;
}>;


export type UpdateEventComponentMutation = (
  { __typename?: 'RootMutationType' }
  & { updateEventComponent: Maybe<(
    { __typename?: 'EventComponentPayload' }
    & { result: Maybe<(
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'capacity' | 'date' | 'duration' | 'id' | 'guestPays' | 'status' | 'main'>
      & { tiers: Array<(
        { __typename?: 'Tier' }
        & Pick<Tier, 'id'>
      )>, offering: (
        { __typename: 'PackageOffering' }
        & PackageFieldsFragment
      ) | (
        { __typename: 'RoomOffering' }
        & RoomFieldsFragment
      ) }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type AliasEventComponentMutationVariables = Exact<{
  id: Scalars['ID'];
  input: EventComponentInput;
}>;


export type AliasEventComponentMutation = (
  { __typename?: 'RootMutationType' }
  & { updateEventComponent: Maybe<(
    { __typename?: 'EventComponentPayload' }
    & { result: Maybe<(
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'alias' | 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type SetMainComponentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetMainComponentMutation = (
  { __typename?: 'RootMutationType' }
  & { setMainComponent: Maybe<(
    { __typename?: 'EventComponentPayload' }
    & { result: Maybe<(
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'id' | 'main'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type TriggerEventComponentEventMutationVariables = Exact<{
  id: Maybe<Scalars['ID']>;
  event: Maybe<EventComponentEvent>;
}>;


export type TriggerEventComponentEventMutation = (
  { __typename?: 'RootMutationType' }
  & { triggerEventComponentEvent: Maybe<(
    { __typename?: 'EventComponentPayload' }
    & { result: Maybe<(
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'capacity' | 'date' | 'duration' | 'id' | 'status' | 'main'>
      & { offering: (
        { __typename: 'PackageOffering' }
        & PackageFieldsFragment
      ) | (
        { __typename: 'RoomOffering' }
        & RoomFieldsFragment
      ) }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type AddRoomsToNegotiationMutationVariables = Exact<{
  eventId: Scalars['ID'];
  providerUrl: Scalars['String'];
}>;


export type AddRoomsToNegotiationMutation = (
  { __typename?: 'RootMutationType' }
  & { addRoomsToNegotiation: Maybe<(
    { __typename?: 'AddRoomsPayload' }
    & { result: Maybe<(
      { __typename?: 'AddRooms' }
      & Pick<AddRooms, 'eventId' | 'providerUrl'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetNegotiationMessagesQueryVariables = Exact<{
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
  cursor: Maybe<Scalars['String']>;
}>;


export type GetNegotiationMessagesQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetNegotiationMessagesCommonFragment
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetNegotiationMessagesCommonFragment
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetNegotiationMessagesCommonFragment
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetNegotiationMessagesCommonFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'id'>
  & { negotiation: (
    { __typename?: 'Negotiation' }
    & { quotes: Array<(
      { __typename?: 'Quote' }
      & { eventComponent: (
        { __typename?: 'EventComponent' }
        & Pick<EventComponent, 'id'>
        & { offering: (
          { __typename: 'PackageOffering' }
          & Pick<PackageOffering, 'name'>
        ) | (
          { __typename: 'RoomOffering' }
          & Pick<RoomOffering, 'name'>
        ) }
      ) }
    )>, messages: Maybe<(
      { __typename?: 'PaginatedNegotiationMessages' }
      & Pick<PaginatedNegotiationMessages, 'cursor'>
      & { entries: Array<(
        { __typename?: 'NegotiationMessage' }
        & MessagesFieldsFragment
      )> }
    )> }
  ) }
);

export type GetNegotiationQuotesQueryVariables = Exact<{
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
}>;


export type GetNegotiationQuotesQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetNegotiationQuotesCommonFragment
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetNegotiationQuotesCommonFragment
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetNegotiationQuotesCommonFragment
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetNegotiationQuotesCommonFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'id'>
  & { negotiation: (
    { __typename?: 'Negotiation' }
    & Pick<Negotiation, 'balance'>
    & { provider: (
      { __typename?: 'ProviderPreview' }
      & Pick<ProviderPreview, 'id' | 'name'>
      & { mainImage: Maybe<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )>, vendor: (
        { __typename?: 'VendorBasicInfo' }
        & Pick<VendorBasicInfo, 'avatarUrl'>
      ) }
    ), event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    ), quotes: Array<(
      { __typename?: 'Quote' }
      & { eventComponent: (
        { __typename?: 'EventComponent' }
        & Pick<EventComponent, 'id' | 'status' | 'date' | 'alias' | 'duration'>
        & { offering: (
          { __typename: 'PackageOffering' }
          & { addOns: Array<(
            { __typename?: 'OfferingAddOns' }
            & Pick<OfferingAddOns, 'id' | 'frequency' | 'label' | 'price' | 'mandatory'>
            & { offeringAddOnOptions: Array<(
              { __typename?: 'OfferingAddOnOptions' }
              & Pick<OfferingAddOnOptions, 'id' | 'description' | 'label' | 'price'>
            )> }
          )> }
        ) | { __typename: 'RoomOffering' }, packageCustomization: Array<(
          { __typename?: 'PackageAddons' }
          & PackageAddonsFieldsFragment
        )> }
      ), items: Array<(
        { __typename?: 'NegotiationItem' }
        & NegotiationItemFieldsFragment
      )> }
    )> }
  ) }
);

export type OnEventComponentsUpdateSubscriptionVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type OnEventComponentsUpdateSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { eventComponentsUpdate: Maybe<(
    { __typename?: 'PackageAddons' }
    & PackageAddonsFieldsFragment
  )> }
);

export type OnEventComponentsAddonsDeleteSubscriptionVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type OnEventComponentsAddonsDeleteSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { eventComponentsAddonsDelete: Maybe<(
    { __typename?: 'PackageAddons' }
    & Pick<PackageAddons, 'id' | 'eventComponentId'>
  )> }
);

export type OnNegotiationMessagesSubscriptionVariables = Exact<{
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
}>;


export type OnNegotiationMessagesSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { negotiationMessages: Maybe<(
    { __typename?: 'NegotiationMessage' }
    & MessagesFieldsFragment
  )> }
);

export type GetNegotiationsPreviewQueryVariables = Exact<{
  eventId: Scalars['ID'];
  cursor: Maybe<Scalars['String']>;
}>;


export type GetNegotiationsPreviewQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetNegotiationsPreviewCommonFragment
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetNegotiationsPreviewCommonFragment
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & GetNegotiationsPreviewCommonFragment
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetNegotiationsPreviewCommonFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'id'>
  & { negotiations: Maybe<(
    { __typename?: 'PaginatedNegotiationPreviews' }
    & Pick<PaginatedNegotiationPreviews, 'cursor'>
    & { entries: Array<(
      { __typename?: 'NegotiationPreview' }
      & Pick<NegotiationPreview, 'lastUpdate'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name'>
      ), provider: (
        { __typename?: 'ProviderPreview' }
        & Pick<ProviderPreview, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type CreateNegotiationMessageMutationVariables = Exact<{
  eventId: Scalars['ID'];
  input: NegotiationMessageInput;
  providerId: Scalars['ID'];
}>;


export type CreateNegotiationMessageMutation = (
  { __typename?: 'RootMutationType' }
  & { createNegotiationMessage: Maybe<(
    { __typename?: 'NegotiationMessagePayload' }
    & { result: Maybe<(
      { __typename?: 'NegotiationMessage' }
      & MessagesFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type CreateNegotiationItemMutationVariables = Exact<{
  eventComponentId: Scalars['ID'];
  input: NegotiationItemInput;
}>;


export type CreateNegotiationItemMutation = (
  { __typename?: 'RootMutationType' }
  & { createNegotiationItem: Maybe<(
    { __typename?: 'NegotiationItemPayload' }
    & { result: Maybe<(
      { __typename?: 'NegotiationItem' }
      & NegotiationItemFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type DeleteNegotiationItemMutationVariables = Exact<{
  negotiationItemId: Scalars['ID'];
}>;


export type DeleteNegotiationItemMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteNegotiationItem: Maybe<(
    { __typename?: 'NegotiationItemPayload' }
    & { result: Maybe<(
      { __typename?: 'NegotiationItem' }
      & NegotiationItemFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type InitiateHostPaymentMutationVariables = Exact<{
  amount: Scalars['Decimal'];
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
}>;


export type InitiateHostPaymentMutation = (
  { __typename?: 'RootMutationType' }
  & { initiateHostPayment: Maybe<(
    { __typename?: 'StripeSessionPayload' }
    & { result: Maybe<(
      { __typename?: 'StripeSession' }
      & Pick<StripeSession, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type OnBalanceUpdatesSubscriptionVariables = Exact<{
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
}>;


export type OnBalanceUpdatesSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { balanceUpdates: Maybe<(
    { __typename?: 'BalanceUpdate' }
    & Pick<BalanceUpdate, 'balance'>
  )> }
);

export type FeedComponentGroupsQueryVariables = Exact<{
  eventId: Scalars['ID'];
  cursor: Maybe<Scalars['String']>;
}>;


export type FeedComponentGroupsQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myBookings: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
      & { eventComponent: (
        { __typename?: 'EventComponent' }
        & Pick<EventComponent, 'id' | 'customTexts'>
        & { offering: { __typename: 'PackageOffering' } | { __typename: 'RoomOffering' } }
      ) }
    )> }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )>, feedComponentGroups: (
    { __typename?: 'PaginatedEventComponentGroups' }
    & Pick<PaginatedEventComponentGroups, 'cursor'>
    & { entries: Array<(
      { __typename?: 'EventComponentGroup' }
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
        & { host: (
          { __typename?: 'HostBasicInfo' }
          & Pick<HostBasicInfo, 'avatarUrl' | 'firstName'>
        ) }
      ), components: Array<(
        { __typename?: 'EventComponent' }
        & Pick<EventComponent, 'capacity' | 'date' | 'duration' | 'id' | 'status' | 'alias' | 'main' | 'bookingCount'>
        & { booking: Maybe<Array<Maybe<(
          { __typename?: 'Booking' }
          & Pick<Booking, 'id' | 'status'>
        )>>>, packageCustomization: Array<(
          { __typename?: 'PackageAddons' }
          & PackageAddonsFieldsFragment
        )>, offering: (
          { __typename: 'PackageOffering' }
          & Pick<PackageOffering, 'name' | 'intro' | 'description'>
          & { mainImage: Maybe<(
            { __typename?: 'Image' }
            & ImageFieldsFragment
          )>, images: Array<(
            { __typename?: 'Image' }
            & ImageFieldsFragment
          )>, provider: (
            { __typename?: 'Provider' }
            & Pick<Provider, 'location' | 'id' | 'address' | 'url' | 'name'>
            & { mainImage: Maybe<(
              { __typename?: 'Image' }
              & ImageFieldsFragment
            )> }
          ) }
        ) | (
          { __typename: 'RoomOffering' }
          & Pick<RoomOffering, 'name' | 'description' | 'price'>
          & { mainImage: Maybe<(
            { __typename?: 'Image' }
            & ImageFieldsFragment
          )>, images: Array<(
            { __typename?: 'Image' }
            & ImageFieldsFragment
          )>, provider: (
            { __typename?: 'Provider' }
            & Pick<Provider, 'location' | 'id' | 'address' | 'url' | 'name'>
            & { mainImage: Maybe<(
              { __typename?: 'Image' }
              & ImageFieldsFragment
            )> }
          ), room: (
            { __typename?: 'RoomDetails' }
            & Pick<RoomDetails, 'beds' | 'occupancy' | 'size'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type AddTierToEventComponentMutationVariables = Exact<{
  id: Scalars['ID'];
  tierId: Scalars['ID'];
}>;


export type AddTierToEventComponentMutation = (
  { __typename?: 'RootMutationType' }
  & { addTierToEventComponent: (
    { __typename?: 'EventComponentPayload' }
    & { result: Maybe<(
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'id'>
      & { tiers: Array<(
        { __typename?: 'Tier' }
        & Pick<Tier, 'id' | 'name'>
      )> }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  ) }
);

export type RemoveTierFromEventComponentMutationVariables = Exact<{
  id: Scalars['ID'];
  tierId: Scalars['ID'];
}>;


export type RemoveTierFromEventComponentMutation = (
  { __typename?: 'RootMutationType' }
  & { removeTierFromEventComponent: (
    { __typename?: 'EventComponentPayload' }
    & { result: Maybe<(
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'id'>
      & { tiers: Array<(
        { __typename?: 'Tier' }
        & Pick<Tier, 'id' | 'name'>
      )> }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  ) }
);

export type MarkMessagesAsSeenMutationVariables = Exact<{
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
}>;


export type MarkMessagesAsSeenMutation = (
  { __typename?: 'RootMutationType' }
  & { markMessagesAsSeen: Maybe<(
    { __typename?: 'ViewedMessagesPayload' }
    & { result: Maybe<(
      { __typename?: 'ViewedMessages' }
      & Pick<ViewedMessages, 'marked'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetGuestListByTierIdQueryVariables = Exact<{
  tierId: Maybe<Scalars['ID']>;
  eventId: Maybe<Scalars['ID']>;
}>;


export type GetGuestListByTierIdQuery = (
  { __typename?: 'RootQueryType' }
  & { guestList: Array<(
    { __typename?: 'GuestShort' }
    & Pick<GuestShort, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  )> }
);

export type GetGuestlistWithBookingsQueryVariables = Exact<{
  id: Scalars['ID'];
  tierIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type GetGuestlistWithBookingsQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & EventFieldsCommonFragment
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & EventFieldsCommonFragment
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & EventFieldsCommonFragment
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )>, availablePackages: Array<(
    { __typename?: 'EventComponent' }
    & Pick<EventComponent, 'id' | 'alias'>
    & { offering: (
      { __typename: 'PackageOffering' }
      & Pick<PackageOffering, 'name'>
    ) | { __typename: 'RoomOffering' } }
  )> }
);

export type EventFieldsCommonFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'defaultKidsAllowed' | 'defaultPlusOne' | 'guestCanPost' | 'mainImage' | 'id' | 'name' | 'paidComponents'>
  & { tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )>, guests: Array<(
    { __typename?: 'Guest' }
    & { guests: Array<(
      { __typename?: 'Guest' }
      & GuestFieldsWithBookingFragment
    )>, role: Maybe<(
      { __typename?: 'GuestRole' }
      & Pick<GuestRole, 'id' | 'name'>
    )> }
    & GuestFieldsWithBookingFragment
  )> }
);

export type SavePackageCustomizationMutationVariables = Exact<{
  input: PackageAddonsInput;
}>;


export type SavePackageCustomizationMutation = (
  { __typename?: 'RootMutationType' }
  & { savePackageCustomization: Maybe<(
    { __typename?: 'PackageAddonsPayload' }
    & { result: Maybe<(
      { __typename?: 'PackageAddons' }
      & Pick<PackageAddons, 'id' | 'eventComponentId' | 'location' | 'time'>
      & { offeringAddOn: (
        { __typename?: 'OfferingAddOns' }
        & Pick<OfferingAddOns, 'id'>
      ), offeringAddOnOption: (
        { __typename?: 'OfferingAddOnOptions' }
        & Pick<OfferingAddOnOptions, 'id'>
      ) }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type EditPackageCustomizationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PackageAddonsInput;
}>;


export type EditPackageCustomizationMutation = (
  { __typename?: 'RootMutationType' }
  & { editPackageCustomization: Maybe<(
    { __typename?: 'PackageAddonsPayload' }
    & { result: Maybe<(
      { __typename?: 'PackageAddons' }
      & Pick<PackageAddons, 'id' | 'eventComponentId' | 'location' | 'time'>
      & { offeringAddOn: (
        { __typename?: 'OfferingAddOns' }
        & Pick<OfferingAddOns, 'id'>
      ), offeringAddOnOption: (
        { __typename?: 'OfferingAddOnOptions' }
        & Pick<OfferingAddOnOptions, 'id'>
      ) }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type DeletePackageCustomizationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePackageCustomizationMutation = (
  { __typename?: 'RootMutationType' }
  & { deletePackageCustomization: Maybe<(
    { __typename?: 'PackageAddonsPayload' }
    & { result: Maybe<(
      { __typename?: 'PackageAddons' }
      & Pick<PackageAddons, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetAvailablePackagesQueryVariables = Exact<{
  eventId: Scalars['ID'];
  guestId: Maybe<Scalars['ID']>;
}>;


export type GetAvailablePackagesQuery = (
  { __typename?: 'RootQueryType' }
  & { availablePackages: Array<(
    { __typename?: 'EventComponent' }
    & Pick<EventComponent, 'id' | 'main' | 'date' | 'alias' | 'eventId' | 'capacity' | 'customTexts' | 'bookingCount' | 'guestPays'>
    & { offering: (
      { __typename: 'PackageOffering' }
      & Pick<PackageOffering, 'name' | 'price'>
      & { mainImage: Maybe<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )>, provider: (
        { __typename?: 'Provider' }
        & Pick<Provider, 'name'>
      ), package: (
        { __typename?: 'PackageDetails' }
        & Pick<PackageDetails, 'basePrice'>
      ) }
    ) | { __typename: 'RoomOffering' }, tiers: Array<(
      { __typename?: 'Tier' }
      & Pick<Tier, 'id' | 'name'>
    )>, items: Array<(
      { __typename?: 'NegotiationItem' }
      & Pick<NegotiationItem, 'price' | 'deleted'>
    )>, packageCustomization: Array<(
      { __typename?: 'PackageAddons' }
      & PackageAddonsFieldsFragment
    )> }
  )> }
);

export type CheckRoleQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type CheckRoleQuery = (
  { __typename?: 'RootQueryType' }
  & { checkRole: (
    { __typename?: 'GuestShort' }
    & Pick<GuestShort, 'id'>
    & { role: Maybe<(
      { __typename?: 'GuestRole' }
      & Pick<GuestRole, 'id' | 'name'>
    )> }
  ) }
);

export type ImageFieldsFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'assocId' | 'assocType' | 'ext' | 'id' | 'metadata'>
);

export type PackageAddonsFieldsFragment = (
  { __typename?: 'PackageAddons' }
  & Pick<PackageAddons, 'location' | 'time' | 'id' | 'eventComponentId'>
  & { offeringAddOn: (
    { __typename?: 'OfferingAddOns' }
    & Pick<OfferingAddOns, 'id' | 'label' | 'description'>
    & { images: Array<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )> }
  ), offeringAddOnOption: (
    { __typename?: 'OfferingAddOnOptions' }
    & Pick<OfferingAddOnOptions, 'id' | 'label' | 'description'>
  ) }
);

export type CreateGuestMutationVariables = Exact<{
  eventId: Scalars['ID'];
  input: GuestInput;
  tierIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateGuestMutation = (
  { __typename?: 'RootMutationType' }
  & { createGuest: Maybe<(
    { __typename?: 'GuestPayload' }
    & { result: Maybe<(
      { __typename?: 'Guest' }
      & GuestFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type UpdateGuestMutationVariables = Exact<{
  id: Scalars['ID'];
  input: GuestInput;
  tierIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateGuestMutation = (
  { __typename?: 'RootMutationType' }
  & { updateGuest: Maybe<(
    { __typename?: 'GuestPayload' }
    & { result: Maybe<(
      { __typename?: 'Guest' }
      & GuestFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type DeleteGuestMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGuestMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteGuest: Maybe<(
    { __typename?: 'GuestPayload' }
    & { result: Maybe<(
      { __typename?: 'Guest' }
      & Pick<Guest, 'id'>
      & { invitedBy: Maybe<(
        { __typename?: 'GuestShort' }
        & Pick<GuestShort, 'id'>
      )>, event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
      ) }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type CreateMarketingFormMutationVariables = Exact<{
  input: MarketingFormInput;
}>;


export type CreateMarketingFormMutation = (
  { __typename?: 'RootMutationType' }
  & { createMarketingForm: Maybe<(
    { __typename?: 'MyMarketingFormPayload' }
    & { result: Maybe<(
      { __typename?: 'MyMarketingForm' }
      & Pick<MyMarketingForm, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type NotificationFieldsFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'dismissed' | 'id' | 'seen' | 'volatile'>
  & { payload: Maybe<(
    { __typename?: 'EventComponentAddedBody' }
    & Pick<EventComponentAddedBody, 'eventComponentId' | 'eventId' | 'message'>
    & { image: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'assocId' | 'assocType' | 'ext' | 'id'>
    )> }
  ) | (
    { __typename?: 'NoneBody' }
    & Pick<NoneBody, 'body'>
  ) | (
    { __typename?: 'RsvpBody' }
    & Pick<RsvpBody, 'eventComponentId' | 'eventComponentName' | 'guestId' | 'guestName'>
  ) | (
    { __typename?: 'SimpleNotificationBody' }
    & Pick<SimpleNotificationBody, 'message' | 'meta'>
  )> }
);

export type OnNotificationsPushSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnNotificationsPushSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { notificationsPush: Maybe<(
    { __typename?: 'Notification' }
    & NotificationFieldsFragment
  )> }
);

export type GetNotificationsQueryVariables = Exact<{
  cursor: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetNotificationsQuery = (
  { __typename?: 'RootQueryType' }
  & { notifications: Maybe<(
    { __typename?: 'PaginatedNotifications' }
    & Pick<PaginatedNotifications, 'cursor'>
    & { entries: Maybe<Array<Maybe<(
      { __typename?: 'Notification' }
      & NotificationFieldsFragment
    )>>> }
  )> }
);

export type NotificationSeenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NotificationSeenMutation = (
  { __typename?: 'RootMutationType' }
  & { notificationSeen: Maybe<(
    { __typename?: 'NotificationPayload' }
    & { errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'Notification' }
      & NotificationFieldsFragment
    )> }
  )> }
);

export type NotificationDismissedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NotificationDismissedMutation = (
  { __typename?: 'RootMutationType' }
  & { notificationDismissed: Maybe<(
    { __typename?: 'NotificationPayload' }
    & { errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'Notification' }
      & NotificationFieldsFragment
    )> }
  )> }
);

export type TrackViewMutationVariables = Exact<{
  guestId: Maybe<Scalars['ID']>;
  eventId: Maybe<Scalars['ID']>;
  source: Scalars['String'];
  destination: Scalars['String'];
}>;


export type TrackViewMutation = (
  { __typename?: 'RootMutationType' }
  & { trackView: Maybe<(
    { __typename?: 'TrackPayload' }
    & { result: Maybe<(
      { __typename?: 'Track' }
      & Pick<Track, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type RoomFieldsFragment = (
  { __typename?: 'RoomOffering' }
  & Pick<RoomOffering, 'description' | 'id' | 'intro' | 'name' | 'price' | 'marketPrice' | 'quantity'>
  & { images: Array<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, mainImage: Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, provider: (
    { __typename?: 'Provider' }
    & Pick<Provider, 'name' | 'id' | 'url'>
    & { images: Array<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )>, mainImage: Maybe<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )> }
  ), room: (
    { __typename?: 'RoomDetails' }
    & Pick<RoomDetails, 'beds' | 'occupancy' | 'size'>
  ) }
);

export type PackageFieldsFragment = (
  { __typename?: 'PackageOffering' }
  & Pick<PackageOffering, 'description' | 'id' | 'intro' | 'name' | 'price' | 'quantity'>
  & { images: Array<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, mainImage: Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, package: (
    { __typename?: 'PackageDetails' }
    & Pick<PackageDetails, 'baseGuestNumber' | 'basePrice' | 'minimumGuests'>
  ), provider: (
    { __typename?: 'Provider' }
    & Pick<Provider, 'name' | 'id' | 'url'>
    & { images: Array<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )>, mainImage: Maybe<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )> }
  ) }
);

export type GetLocationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  cursor: Maybe<Scalars['String']>;
  where: Maybe<Scalars['Location']>;
  what: Maybe<Scalars['String']>;
  maxPackagePrice: Maybe<Scalars['Decimal']>;
  maxRoomPrice: Maybe<Scalars['Decimal']>;
  groupSize?: Maybe<Scalars['Int']>;
}>;


export type GetLocationsQuery = (
  { __typename?: 'RootQueryType' }
  & { marketplace: Maybe<(
    { __typename?: 'PaginatedProviders' }
    & Pick<PaginatedProviders, 'cursor'>
    & { entries: Array<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id' | 'url' | 'name' | 'location' | 'address' | 'coord'>
      & { room: Maybe<(
        { __typename?: 'BestRoom' }
        & Pick<BestRoom, 'price' | 'sleeps'>
      )>, package: Maybe<(
        { __typename?: 'BestPackage' }
        & Pick<BestPackage, 'capacity' | 'price' | 'baseNumber' | 'minimumGuests'>
      )>, mainImage: Maybe<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )> }
    )> }
  )> }
);

export type GetLocationQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type GetLocationQuery = (
  { __typename?: 'RootQueryType' }
  & { provider: Maybe<(
    { __typename?: 'Provider' }
    & Pick<Provider, 'address' | 'airportCode' | 'bookAllRequired' | 'description' | 'id' | 'url' | 'name' | 'location' | 'coord' | 'metadata' | 'cancellationPolicy' | 'timeToAirport'>
    & { package: Maybe<(
      { __typename?: 'BestPackage' }
      & Pick<BestPackage, 'capacity' | 'price' | 'minimumGuests'>
    )>, room: Maybe<(
      { __typename?: 'BestRoom' }
      & Pick<BestRoom, 'price' | 'sleeps'>
    )>, packages: Array<(
      { __typename?: 'PackageOffering' }
      & PackageFieldsFragment
    )>, rooms: Array<(
      { __typename?: 'RoomOffering' }
      & RoomFieldsFragment
    )>, images: Array<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )> }
  )> }
);

export type GetLocationsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetLocationsByIdsQuery = (
  { __typename?: 'RootQueryType' }
  & { providers: Array<(
    { __typename?: 'Provider' }
    & Pick<Provider, 'address' | 'airportCode' | 'bookAllRequired' | 'description' | 'id' | 'url' | 'name' | 'location' | 'coord' | 'metadata' | 'cancellationPolicy' | 'timeToAirport'>
    & { package: Maybe<(
      { __typename?: 'BestPackage' }
      & Pick<BestPackage, 'capacity' | 'price' | 'minimumGuests'>
    )>, room: Maybe<(
      { __typename?: 'BestRoom' }
      & Pick<BestRoom, 'price' | 'sleeps'>
    )>, packages: Array<(
      { __typename?: 'PackageOffering' }
      & PackageFieldsFragment
    )>, rooms: Array<(
      { __typename?: 'RoomOffering' }
      & RoomFieldsFragment
    )>, images: Array<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )>, mainImage: Maybe<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )> }
  )> }
);

export type GetLocationsFeedQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  cursor: Maybe<Scalars['String']>;
  where: Maybe<Scalars['Location']>;
  what?: Maybe<Scalars['String']>;
  maxPackagePrice: Maybe<Scalars['Decimal']>;
  maxRoomPrice: Maybe<Scalars['Decimal']>;
  groupSize?: Maybe<Scalars['Int']>;
}>;


export type GetLocationsFeedQuery = (
  { __typename?: 'RootQueryType' }
  & { marketplace: Maybe<(
    { __typename?: 'PaginatedProviders' }
    & Pick<PaginatedProviders, 'cursor'>
    & { entries: Array<(
      { __typename?: 'Provider' }
      & Pick<Provider, 'id' | 'name' | 'description' | 'location' | 'address' | 'airportCode' | 'timeToAirport' | 'url'>
      & { room: Maybe<(
        { __typename?: 'BestRoom' }
        & Pick<BestRoom, 'sleeps'>
      )>, package: Maybe<(
        { __typename?: 'BestPackage' }
        & Pick<BestPackage, 'capacity'>
      )>, packages: Array<(
        { __typename?: 'PackageOffering' }
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )> }
      )>, mainImage: Maybe<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )>, images: Array<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )> }
    )> }
  )> }
);

export type GetProviderMainImagesQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetProviderMainImagesQuery = (
  { __typename?: 'RootQueryType' }
  & { providers: Array<(
    { __typename?: 'Provider' }
    & Pick<Provider, 'id' | 'name'>
    & { mainImage: Maybe<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )> }
  )> }
);

export type MarketingFormByAssocIdQueryVariables = Exact<{
  assocId: Scalars['ID'];
  formName: Scalars['String'];
}>;


export type MarketingFormByAssocIdQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & { eventList: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  ) | (
    { __typename?: 'Concierge' }
    & { eventList: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  ) | (
    { __typename?: 'Customer' }
    & { eventList: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  ) | { __typename?: 'Guest' } | { __typename?: 'UserProfile' } | { __typename?: 'Vendor' }>, marketingFormByAssocId: Maybe<(
    { __typename?: 'MarketingForm' }
    & Pick<MarketingForm, 'assocId' | 'formData' | 'formName'>
  )> }
);

export type CreateMarketingFormExistingUserMutationVariables = Exact<{
  input: MarketingFormInput;
}>;


export type CreateMarketingFormExistingUserMutation = (
  { __typename?: 'RootMutationType' }
  & { createMarketingFormExistingUser: Maybe<(
    { __typename?: 'MyMarketingFormPayload' }
    & { result: Maybe<(
      { __typename?: 'MyMarketingForm' }
      & Pick<MyMarketingForm, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetEventQueryVariables = Exact<{
  customUrl: Scalars['String'];
}>;


export type GetEventQuery = (
  { __typename?: 'RootQueryType' }
  & { getEvent: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'description' | 'defaultKidsAllowed' | 'brideFirstName' | 'brideLastName' | 'groomFirstName' | 'groomLastName' | 'video' | 'cover' | 'mainImage'>
    & { invitationTemplate: Maybe<(
      { __typename?: 'InvitationTemplate' }
      & Pick<InvitationTemplate, 'template'>
    )>, host: (
      { __typename?: 'HostBasicInfo' }
      & Pick<HostBasicInfo, 'avatarUrl' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type ProviderFieldsPublicFragment = (
  { __typename?: 'Provider' }
  & Pick<Provider, 'id' | 'name' | 'url' | 'address' | 'location' | 'coord' | 'bookAllRequired' | 'description' | 'airportCode' | 'timeToAirport' | 'metadata' | 'accomodationTaxRate' | 'venueTaxRate'>
  & { mainImage: Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, images: Array<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )> }
);

export type RoomFieldsPublicFragment = (
  { __typename?: 'RoomOffering' }
  & Pick<RoomOffering, 'description' | 'id' | 'intro' | 'name' | 'price' | 'quantity'>
  & { images: Array<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, mainImage: Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, provider: (
    { __typename?: 'Provider' }
    & ProviderFieldsPublicFragment
  ), room: (
    { __typename?: 'RoomDetails' }
    & Pick<RoomDetails, 'beds' | 'occupancy' | 'size' | 'basic'>
  ), addOns: Array<(
    { __typename?: 'OfferingAddOns' }
    & OfferingAddonsFieldsFragment
  )> }
);

export type PackageFieldsPublicFragment = (
  { __typename?: 'PackageOffering' }
  & Pick<PackageOffering, 'id' | 'name' | 'intro' | 'price' | 'description' | 'quantity'>
  & { images: Array<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, mainImage: Maybe<(
    { __typename?: 'Image' }
    & ImageFieldsFragment
  )>, package: (
    { __typename?: 'PackageDetails' }
    & Pick<PackageDetails, 'baseGuestNumber' | 'basePrice' | 'minimumGuests' | 'basic'>
  ), provider: (
    { __typename?: 'Provider' }
    & ProviderFieldsPublicFragment
  ), addOns: Array<(
    { __typename?: 'OfferingAddOns' }
    & OfferingAddonsFieldsFragment
  )> }
);

export type GetEventComponentsPublicQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type GetEventComponentsPublicQuery = (
  { __typename?: 'RootQueryType' }
  & { getEventComponents: Array<(
    { __typename?: 'EventComponent' }
    & Pick<EventComponent, 'main' | 'capacity' | 'bookingCount' | 'date' | 'duration' | 'id' | 'alias' | 'deadline' | 'guestPays' | 'order' | 'customTexts'>
    & { offering: (
      { __typename: 'PackageOffering' }
      & PackageFieldsPublicFragment
    ) | (
      { __typename: 'RoomOffering' }
      & RoomFieldsPublicFragment
    ), items: Array<(
      { __typename?: 'NegotiationItem' }
      & Pick<NegotiationItem, 'price' | 'deleted' | 'master'>
    )>, tiers: Array<(
      { __typename?: 'Tier' }
      & Pick<Tier, 'id' | 'name'>
    )>, packageCustomization: Array<(
      { __typename?: 'PackageAddons' }
      & PackageAddonsFieldsFragment
    )> }
  )> }
);

export type GetEventComponentPricesQueryVariables = Exact<{
  eventComponentId: Scalars['ID'];
}>;


export type GetEventComponentPricesQuery = (
  { __typename?: 'RootQueryType' }
  & { getEventComponentPrices: (
    { __typename?: 'PriceComponents' }
    & Pick<PriceComponents, 'basePrice' | 'duration' | 'offeringPrice' | 'tax' | 'total'>
  ) }
);

export type ConfirmBookingPublicMutationVariables = Exact<{
  eventComponentId: Scalars['ID'];
  guestData: GuestInput;
  extraNights: Maybe<ExtraNightsAddonPublic>;
  freeGuests: Maybe<Array<Maybe<GuestBookingAddonPublic>> | Maybe<GuestBookingAddonPublic>>;
  paidGuests: Maybe<Array<Maybe<GuestBookingAddonPublic>> | Maybe<GuestBookingAddonPublic>>;
  addonsToDelete: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ConfirmBookingPublicMutation = (
  { __typename?: 'RootMutationType' }
  & { confirmBookingPublic: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type AddAllAddonsToBookingPublicMutationVariables = Exact<{
  eventComponentId: Scalars['ID'];
  guestData: GuestInput;
  extraNights: Maybe<ExtraNightsAddonPublic>;
  freeGuests: Maybe<Array<Maybe<GuestBookingAddonPublic>> | Maybe<GuestBookingAddonPublic>>;
  paidGuests: Maybe<Array<Maybe<GuestBookingAddonPublic>> | Maybe<GuestBookingAddonPublic>>;
  addonsToDelete: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type AddAllAddonsToBookingPublicMutation = (
  { __typename?: 'RootMutationType' }
  & { addAllAddonsToBookingPublic: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetBookingDataPublicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookingDataPublicQuery = (
  { __typename?: 'RootQueryType' }
  & { bookingPublic: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'status' | 'availability' | 'date' | 'duration'>
    & { guest: (
      { __typename?: 'GuestShort' }
      & Pick<GuestShort, 'id' | 'plusOne' | 'firstName' | 'lastName' | 'email' | 'phone'>
    ), guestPrice: (
      { __typename?: 'PriceComponents' }
      & Pick<PriceComponents, 'basePrice' | 'tax' | 'total' | 'offeringPrice' | 'duration'>
    ), bookingAddOn: Array<(
      { __typename?: 'BookingAddOn' }
      & Pick<BookingAddOn, 'id'>
      & { offeringAddOnOption: Maybe<(
        { __typename?: 'OfferingAddOnOptions' }
        & Pick<OfferingAddOnOptions, 'id' | 'label' | 'price' | 'description' | 'quantity' | 'offeringAddOnId'>
      )>, offeringAddOn: (
        { __typename?: 'OfferingAddOns' }
        & Pick<OfferingAddOns, 'id' | 'frequency' | 'label' | 'mandatory' | 'taxable' | 'format' | 'price' | 'type' | 'metadata'>
      ), bookingGuest: Maybe<(
        { __typename?: 'BookingGuest' }
        & Pick<BookingGuest, 'firstName' | 'lastName' | 'phone' | 'email' | 'plusone'>
      )> }
    )>, eventComponent: (
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'id' | 'date' | 'duration' | 'capacity' | 'guestPays' | 'eventId' | 'alias'>
      & { items: Array<(
        { __typename?: 'NegotiationItem' }
        & Pick<NegotiationItem, 'deleted' | 'master' | 'price'>
      )>, packageCustomization: Array<(
        { __typename?: 'PackageAddons' }
        & PackageAddonsFieldsFragment
      )>, offering: (
        { __typename: 'PackageOffering' }
        & Pick<PackageOffering, 'name' | 'intro' | 'description'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, images: Array<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, provider: (
          { __typename?: 'Provider' }
          & Pick<Provider, 'bookAllRequired' | 'id' | 'address' | 'url' | 'name'>
        ), addOns: Array<(
          { __typename?: 'OfferingAddOns' }
          & OfferingAddonsFieldsFragment
        )> }
      ) | (
        { __typename: 'RoomOffering' }
        & Pick<RoomOffering, 'name' | 'id' | 'description' | 'price'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, images: Array<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, provider: (
          { __typename?: 'Provider' }
          & Pick<Provider, 'bookAllRequired' | 'id' | 'address' | 'url' | 'name' | 'accomodationTaxRate' | 'venueTaxRate'>
        ), room: (
          { __typename?: 'RoomDetails' }
          & Pick<RoomDetails, 'beds' | 'occupancy' | 'size' | 'basic'>
        ), addOns: Array<(
          { __typename?: 'OfferingAddOns' }
          & OfferingAddonsFieldsFragment
        )> }
      ) }
    ) }
  )> }
);

export type ValidateGuestPaymentPublicMutationVariables = Exact<{
  paymentIntent: Scalars['String'];
}>;


export type ValidateGuestPaymentPublicMutation = (
  { __typename?: 'RootMutationType' }
  & { validateGuestPaymentPublic: Maybe<(
    { __typename?: 'ValidateGuestPaymentPayload' }
    & { result: Maybe<(
      { __typename?: 'ValidateGuestPayment' }
      & Pick<ValidateGuestPayment, 'paymentStatus'>
      & { booking: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'status'>
      ) }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type InitiateGuestPaymentPublicMutationVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type InitiateGuestPaymentPublicMutation = (
  { __typename?: 'RootMutationType' }
  & { initiateGuestPaymentPublic: Maybe<(
    { __typename?: 'StripeSessionPayload' }
    & { result: Maybe<(
      { __typename?: 'StripeSession' }
      & Pick<StripeSession, 'id' | 'clientSecret' | 'amount'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type CreateTierMutationVariables = Exact<{
  eventId: Scalars['ID'];
  input: TierInput;
}>;


export type CreateTierMutation = (
  { __typename?: 'RootMutationType' }
  & { createTier: Maybe<(
    { __typename?: 'TierPayload' }
    & { result: Maybe<(
      { __typename?: 'Tier' }
      & Pick<Tier, 'id' | 'name'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type UpdateTierMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TierInput;
}>;


export type UpdateTierMutation = (
  { __typename?: 'RootMutationType' }
  & { updateTier: Maybe<(
    { __typename?: 'TierPayload' }
    & { result: Maybe<(
      { __typename?: 'Tier' }
      & Pick<Tier, 'id' | 'name'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type DeleteTierMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTierMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteTier: Maybe<(
    { __typename?: 'TierPayload' }
    & { result: Maybe<(
      { __typename?: 'Tier' }
      & Pick<Tier, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type EventComponentFieldsFragment = (
  { __typename?: 'EventComponent' }
  & Pick<EventComponent, 'id' | 'date' | 'duration' | 'alias' | 'status' | 'capacity' | 'eventId' | 'customTexts' | 'order' | 'main' | 'bookingCount' | 'guestPays'>
  & { booking: Maybe<Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'status'>
  )>>>, packageCustomization: Array<(
    { __typename?: 'PackageAddons' }
    & PackageAddonsFieldsFragment
  )>, tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )>, offering: (
    { __typename: 'PackageOffering' }
    & Pick<PackageOffering, 'name' | 'intro' | 'description'>
    & { mainImage: Maybe<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )>, images: Array<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )>, provider: (
      { __typename?: 'Provider' }
      & Pick<Provider, 'timeToAirport' | 'airportCode' | 'coord' | 'id' | 'url' | 'address' | 'location' | 'name'>
      & { mainImage: Maybe<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )>, images: Array<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )> }
    ) }
  ) | (
    { __typename: 'RoomOffering' }
    & Pick<RoomOffering, 'name' | 'description' | 'price'>
    & { mainImage: Maybe<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )>, images: Array<(
      { __typename?: 'Image' }
      & ImageFieldsFragment
    )>, room: (
      { __typename?: 'RoomDetails' }
      & Pick<RoomDetails, 'beds' | 'occupancy' | 'size' | 'basic'>
    ), provider: (
      { __typename?: 'Provider' }
      & Pick<Provider, 'timeToAirport' | 'airportCode' | 'coord' | 'id' | 'url' | 'address' | 'location' | 'name'>
      & { mainImage: Maybe<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )>, images: Array<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )> }
    ) }
  ) }
);

export type OfferingAddonsFieldsFragment = (
  { __typename?: 'OfferingAddOns' }
  & Pick<OfferingAddOns, 'id' | 'frequency' | 'label' | 'price' | 'mandatory' | 'quantity' | 'metadata' | 'type'>
  & { offeringAddOnOptions: Array<(
    { __typename?: 'OfferingAddOnOptions' }
    & Pick<OfferingAddOnOptions, 'id' | 'description' | 'label' | 'price' | 'quantity' | 'offeringAddOnId'>
  )> }
);

export type GuestMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GuestMeQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<{ __typename?: 'Admin' } | { __typename?: 'Concierge' } | { __typename?: 'Customer' } | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'status' | 'id' | 'email' | 'firstName' | 'lastName' | 'kidsAllowed' | 'plusOne' | 'bookedComponents'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'mainImage' | 'description' | 'video' | 'cover' | 'brideFirstName' | 'brideLastName' | 'groomFirstName' | 'groomLastName'>
      & { invitationTemplate: Maybe<(
        { __typename?: 'InvitationTemplate' }
        & Pick<InvitationTemplate, 'template'>
      )>, host: (
        { __typename?: 'HostBasicInfo' }
        & Pick<HostBasicInfo, 'firstName' | 'lastName'>
      ) }
    ), eventComponents: Maybe<Array<(
      { __typename?: 'EventComponent' }
      & EventComponentFieldsFragment
    )>>, mainComponent: Maybe<(
      { __typename?: 'EventComponent' }
      & EventComponentFieldsFragment
    )> }
  ) | { __typename?: 'UserProfile' } | { __typename?: 'Vendor' }> }
);

export type FlightDataFragment = (
  { __typename?: 'FlightInformation' }
  & Pick<FlightInformation, 'adults' | 'arrivalAirline' | 'arrivalAirport' | 'arrivalDatetime' | 'arrivalFlight' | 'arrivalLocation' | 'departureAirline' | 'departureAirport' | 'departureDatetime' | 'departureFlight' | 'departureLocation' | 'guestNames' | 'id' | 'kids' | 'propertyName'>
);

export type BookingAddonsFieldsFragment = (
  { __typename?: 'BookingAddOn' }
  & Pick<BookingAddOn, 'id'>
  & { offeringAddOnOption: Maybe<(
    { __typename?: 'OfferingAddOnOptions' }
    & Pick<OfferingAddOnOptions, 'id' | 'label' | 'price' | 'description' | 'quantity' | 'offeringAddOnId'>
  )>, offeringAddOn: (
    { __typename?: 'OfferingAddOns' }
    & Pick<OfferingAddOns, 'id' | 'frequency' | 'label' | 'mandatory' | 'taxable' | 'format' | 'price' | 'type' | 'metadata'>
  ), bookingGuest: Maybe<(
    { __typename?: 'BookingGuest' }
    & Pick<BookingGuest, 'firstName' | 'lastName' | 'phone' | 'email' | 'plusone'>
    & { guest: Maybe<(
      { __typename?: 'GuestShort' }
      & Pick<GuestShort, 'id'>
      & { flightInformation: Maybe<(
        { __typename?: 'FlightInformation' }
        & FlightDataFragment
      )> }
    )> }
  )> }
);

export type ProviderFieldsFragment = (
  { __typename?: 'Provider' }
  & Pick<Provider, 'bookAllRequired' | 'airportCode' | 'location' | 'id' | 'address' | 'url' | 'name' | 'accomodationTaxRate' | 'venueTaxRate' | 'cancellationPolicy'>
);

export type SendInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SendInvitationMutation = (
  { __typename?: 'RootMutationType' }
  & { sendInvitation: Maybe<(
    { __typename?: 'GuestPayload' }
    & { result: Maybe<(
      { __typename?: 'Guest' }
      & Pick<Guest, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type SendPreInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SendPreInvitationMutation = (
  { __typename?: 'RootMutationType' }
  & { sendPreInvitation: Maybe<(
    { __typename?: 'GuestPayload' }
    & { result: Maybe<(
      { __typename?: 'Guest' }
      & Pick<Guest, 'id' | 'status' | 'preInvitationSent'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type SendReminderMutationVariables = Exact<{
  guestId: Scalars['ID'];
  template: Scalars['String'];
}>;


export type SendReminderMutation = (
  { __typename?: 'RootMutationType' }
  & { sendReminder: Maybe<(
    { __typename?: 'GuestPayload' }
    & { result: Maybe<(
      { __typename?: 'Guest' }
      & Pick<Guest, 'id' | 'status' | 'reminderSentAt'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type TriggerGuestEventMutationVariables = Exact<{
  guestId: Scalars['ID'];
  event: GuestEvent;
}>;


export type TriggerGuestEventMutation = (
  { __typename?: 'RootMutationType' }
  & { triggerGuestEvent: Maybe<(
    { __typename?: 'GuestPayload' }
    & { result: Maybe<(
      { __typename?: 'Guest' }
      & Pick<Guest, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type UpdateMyInvitationMutationVariables = Exact<{
  guestId: Scalars['ID'];
  event: GuestEvent;
}>;


export type UpdateMyInvitationMutation = (
  { __typename?: 'RootMutationType' }
  & { updateMyInvitation: Maybe<(
    { __typename?: 'GuestPayload' }
    & { result: Maybe<(
      { __typename?: 'Guest' }
      & Pick<Guest, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetMyEventInfoRsvpQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMyEventInfoRsvpQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & CommonEventFieldsRsvpFragment
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & CommonEventFieldsRsvpFragment
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventInfo: (
      { __typename?: 'MyEvent' }
      & CommonEventFieldsRsvpFragment
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type CommonEventFieldsRsvpFragment = (
  { __typename?: 'MyEvent' }
  & Pick<MyEvent, 'defaultKidsAllowed' | 'defaultPlusOne' | 'id' | 'name' | 'mainImage' | 'description' | 'video' | 'cover' | 'brideFirstName' | 'brideLastName' | 'groomFirstName' | 'groomLastName'>
  & { components: Array<(
    { __typename?: 'EventComponent' }
    & EventComponentFieldsFragment
  )>, invitationTemplate: Maybe<(
    { __typename?: 'InvitationTemplate' }
    & Pick<InvitationTemplate, 'template'>
  )> }
);

export type CreateBookingMutationVariables = Exact<{
  eventComponentId: Scalars['ID'];
}>;


export type CreateBookingMutation = (
  { __typename?: 'RootMutationType' }
  & { createBooking: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type CreateBookingForGuestMutationVariables = Exact<{
  eventComponentId: Scalars['ID'];
  guestId: Scalars['ID'];
}>;


export type CreateBookingForGuestMutation = (
  { __typename?: 'RootMutationType' }
  & { createBookingForGuest: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetBookingInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type GetBookingInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & { eventList: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    )>, myEventList: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id' | 'name'>
    )> }
  ) | (
    { __typename?: 'Concierge' }
    & { eventList: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    )>, myEventList: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id' | 'name'>
    )> }
  ) | (
    { __typename?: 'Customer' }
    & { eventList: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name'>
    )>, myEventList: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id' | 'name'>
    )> }
  ) | { __typename?: 'Guest' } | { __typename?: 'UserProfile' } | { __typename?: 'Vendor' }>, booking: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'status' | 'availability' | 'date' | 'duration'>
    & { guest: (
      { __typename?: 'GuestShort' }
      & Pick<GuestShort, 'id' | 'plusOne' | 'plusOneCount' | 'firstName' | 'lastName' | 'email' | 'phone'>
      & { flightInformation: Maybe<(
        { __typename?: 'FlightInformation' }
        & FlightDataFragment
      )> }
    ), guestPrice: (
      { __typename?: 'PriceComponents' }
      & Pick<PriceComponents, 'basePrice' | 'tax' | 'total' | 'offeringPrice' | 'duration'>
    ), bookingAddOn: Array<(
      { __typename?: 'BookingAddOn' }
      & BookingAddonsFieldsFragment
    )>, eventComponent: (
      { __typename?: 'EventComponent' }
      & Pick<EventComponent, 'id' | 'date' | 'duration' | 'capacity' | 'guestPays' | 'eventId' | 'alias' | 'main' | 'deadline' | 'softDeadline' | 'customTexts' | 'bookingCount'>
      & { items: Array<(
        { __typename?: 'NegotiationItem' }
        & Pick<NegotiationItem, 'deleted' | 'master' | 'price'>
      )>, event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name'>
      ), packageCustomization: Array<(
        { __typename?: 'PackageAddons' }
        & PackageAddonsFieldsFragment
      )>, offering: (
        { __typename: 'PackageOffering' }
        & Pick<PackageOffering, 'name' | 'intro' | 'description' | 'price'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, images: Array<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, provider: (
          { __typename?: 'Provider' }
          & ProviderFieldsFragment
        ), addOns: Array<(
          { __typename?: 'OfferingAddOns' }
          & OfferingAddonsFieldsFragment
        )>, package: (
          { __typename?: 'PackageDetails' }
          & Pick<PackageDetails, 'basic'>
        ) }
      ) | (
        { __typename: 'RoomOffering' }
        & Pick<RoomOffering, 'name' | 'id' | 'description' | 'price'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, images: Array<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, provider: (
          { __typename?: 'Provider' }
          & ProviderFieldsFragment
        ), room: (
          { __typename?: 'RoomDetails' }
          & Pick<RoomDetails, 'beds' | 'occupancy' | 'size' | 'basic'>
        ), addOns: Array<(
          { __typename?: 'OfferingAddOns' }
          & OfferingAddonsFieldsFragment
        )> }
      ) }
    ) }
  )> }
);

export type CustomizeBookingDurationMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  date: Maybe<Scalars['NaiveDateTime']>;
  duration: Maybe<Scalars['Int']>;
  addonNightsAfterId: Maybe<Scalars['ID']>;
  optionNightsAfterId: Maybe<Scalars['ID']>;
  addonNightsBeforeId: Maybe<Scalars['ID']>;
  optionNightsBeforeId: Maybe<Scalars['ID']>;
}>;


export type CustomizeBookingDurationMutation = (
  { __typename?: 'RootMutationType' }
  & { customizeBookingDuration: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'date' | 'duration'>
      & { bookingAddOn: Array<(
        { __typename?: 'BookingAddOn' }
        & Pick<BookingAddOn, 'id'>
        & { offeringAddOnOption: Maybe<(
          { __typename?: 'OfferingAddOnOptions' }
          & Pick<OfferingAddOnOptions, 'id' | 'label' | 'price' | 'description' | 'quantity'>
        )>, offeringAddOn: (
          { __typename?: 'OfferingAddOns' }
          & Pick<OfferingAddOns, 'id' | 'frequency' | 'label' | 'mandatory' | 'taxable' | 'format' | 'price' | 'type' | 'metadata'>
        ), bookingGuest: Maybe<(
          { __typename?: 'BookingGuest' }
          & Pick<BookingGuest, 'firstName' | 'lastName' | 'phone' | 'email'>
          & { guest: Maybe<(
            { __typename?: 'GuestShort' }
            & Pick<GuestShort, 'id'>
          )> }
        )> }
      )> }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type ValidateGuestPaymentMutationVariables = Exact<{
  paymentIntent: Scalars['String'];
}>;


export type ValidateGuestPaymentMutation = (
  { __typename?: 'RootMutationType' }
  & { validateGuestPayment: Maybe<(
    { __typename?: 'ValidateGuestPaymentPayload' }
    & { result: Maybe<(
      { __typename?: 'ValidateGuestPayment' }
      & Pick<ValidateGuestPayment, 'paymentStatus'>
      & { booking: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'status'>
      ) }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type InitiateGuestPaymentMutationVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type InitiateGuestPaymentMutation = (
  { __typename?: 'RootMutationType' }
  & { initiateGuestPayment: Maybe<(
    { __typename?: 'StripeSessionPayload' }
    & { result: Maybe<(
      { __typename?: 'StripeSession' }
      & Pick<StripeSession, 'id' | 'clientSecret' | 'amount'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type ConfirmBookingMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  extraNights: Maybe<ExtraNightsAddon>;
  freeGuests: Maybe<Array<Maybe<GuestBookingAddon>> | Maybe<GuestBookingAddon>>;
  paidGuests: Maybe<Array<Maybe<GuestBookingAddon>> | Maybe<GuestBookingAddon>>;
  addonsToDelete: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ConfirmBookingMutation = (
  { __typename?: 'RootMutationType' }
  & { confirmBooking: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type EditBookingMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  freeGuests: Maybe<Array<Maybe<GuestBookingAddon>> | Maybe<GuestBookingAddon>>;
  addonsToDelete: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type EditBookingMutation = (
  { __typename?: 'RootMutationType' }
  & { editBooking: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type DeclineBookingMutationVariables = Exact<{
  id: Scalars['ID'];
  guestId: Maybe<Scalars['ID']>;
  offeringAddOnId: Maybe<Scalars['ID']>;
}>;


export type DeclineBookingMutation = (
  { __typename?: 'RootMutationType' }
  & { declineBooking: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type CancelBookingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelBookingMutation = (
  { __typename?: 'RootMutationType' }
  & { cancelBooking: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetInviatationDataQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type GetInviatationDataQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myBookings: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
      & { eventComponent: (
        { __typename?: 'EventComponent' }
        & Pick<EventComponent, 'id' | 'customTexts'>
        & { offering: { __typename: 'PackageOffering' } | { __typename: 'RoomOffering' } }
      ) }
    )>, myEvents: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id'>
    )>, invitations: Maybe<Array<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'eventId' | 'guestId' | 'status' | 'kidsAllowed' | 'plusOne'>
    )>>, invitation: (
      { __typename?: 'InvitationInfo' }
      & Pick<InvitationInfo, 'id' | 'name' | 'description' | 'mainImage' | 'video' | 'cover' | 'brideFirstName' | 'brideLastName' | 'groomFirstName' | 'groomLastName'>
      & { invitationTemplate: Maybe<(
        { __typename?: 'InvitationTemplate' }
        & Pick<InvitationTemplate, 'template'>
      )>, host: (
        { __typename?: 'HostBasicInfo' }
        & Pick<HostBasicInfo, 'firstName' | 'lastName'>
      ), components: Array<(
        { __typename?: 'EventComponent' }
        & EventComponentFieldsFragment
      )> }
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type AddAddonToBookingMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  offeringAddOnId: Scalars['ID'];
  offeringAddOnOptionId: Maybe<Scalars['ID']>;
  guestBookingInput: Maybe<GuestBookingInput>;
}>;


export type AddAddonToBookingMutation = (
  { __typename?: 'RootMutationType' }
  & { addAddonToBooking: Maybe<(
    { __typename?: 'BookingAddOnPayload' }
    & { result: Maybe<(
      { __typename?: 'BookingAddOn' }
      & Pick<BookingAddOn, 'id'>
      & { offeringAddOnOption: Maybe<(
        { __typename?: 'OfferingAddOnOptions' }
        & Pick<OfferingAddOnOptions, 'id' | 'label' | 'price' | 'description' | 'quantity'>
      )>, offeringAddOn: (
        { __typename?: 'OfferingAddOns' }
        & Pick<OfferingAddOns, 'id' | 'frequency' | 'label' | 'mandatory' | 'price' | 'taxable' | 'format' | 'type' | 'metadata'>
      ), bookingGuest: Maybe<(
        { __typename?: 'BookingGuest' }
        & Pick<BookingGuest, 'firstName' | 'lastName' | 'phone' | 'email' | 'plusone'>
        & { guest: Maybe<(
          { __typename?: 'GuestShort' }
          & Pick<GuestShort, 'id'>
        )> }
      )> }
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type RemoveAddonFromBookingMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  bookingAddonId: Scalars['ID'];
}>;


export type RemoveAddonFromBookingMutation = (
  { __typename?: 'RootMutationType' }
  & { removeAddonFromBooking: Maybe<(
    { __typename?: 'BookingAddOnPayload' }
    & { result: Maybe<(
      { __typename?: 'BookingAddOn' }
      & Pick<BookingAddOn, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetBookingsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetBookingsByIdsQuery = (
  { __typename?: 'RootQueryType' }
  & { bookings: Array<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'status' | 'date' | 'duration'>
    & { guestPrice: (
      { __typename?: 'PriceComponents' }
      & Pick<PriceComponents, 'total'>
    ), bookingAddOn: Array<(
      { __typename?: 'BookingAddOn' }
      & Pick<BookingAddOn, 'id'>
      & { bookingGuest: Maybe<(
        { __typename?: 'BookingGuest' }
        & Pick<BookingGuest, 'email' | 'firstName' | 'lastName' | 'phone' | 'plusone'>
      )>, offeringAddOn: (
        { __typename?: 'OfferingAddOns' }
        & Pick<OfferingAddOns, 'metadata'>
      ), offeringAddOnOption: Maybe<(
        { __typename?: 'OfferingAddOnOptions' }
        & Pick<OfferingAddOnOptions, 'quantity'>
      )> }
    )>, eventComponent: (
      { __typename: 'EventComponent' }
      & Pick<EventComponent, 'id' | 'date' | 'alias' | 'duration' | 'guestPays'>
      & { offering: (
        { __typename?: 'PackageOffering' }
        & Pick<PackageOffering, 'id' | 'name'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, provider: (
          { __typename?: 'Provider' }
          & Pick<Provider, 'name'>
        ) }
      ) | (
        { __typename?: 'RoomOffering' }
        & Pick<RoomOffering, 'id' | 'name'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, provider: (
          { __typename?: 'Provider' }
          & Pick<Provider, 'name'>
        ) }
      ) }
    ) }
  )> }
);

export type AddAllAddonsToBookingMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  extraNights: Maybe<ExtraNightsAddon>;
  freeGuests: Maybe<Array<Maybe<GuestBookingAddon>> | Maybe<GuestBookingAddon>>;
  paidGuests: Maybe<Array<Maybe<GuestBookingAddon>> | Maybe<GuestBookingAddon>>;
  addonsToDelete: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type AddAllAddonsToBookingMutation = (
  { __typename?: 'RootMutationType' }
  & { addAllAddonsToBooking: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type AddFlightInformationMutationVariables = Exact<{
  guestId: Scalars['ID'];
  input: FlightInformationInput;
}>;


export type AddFlightInformationMutation = (
  { __typename?: 'RootMutationType' }
  & { addFlightInformation: Maybe<(
    { __typename?: 'FlightInformationPayload' }
    & { result: Maybe<(
      { __typename?: 'FlightInformation' }
      & FlightDataFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type UpdateFlightInformationMutationVariables = Exact<{
  guestId: Scalars['ID'];
  input: FlightInformationInput;
}>;


export type UpdateFlightInformationMutation = (
  { __typename?: 'RootMutationType' }
  & { updateFlightInformation: Maybe<(
    { __typename?: 'FlightInformationPayload' }
    & { result: Maybe<(
      { __typename?: 'FlightInformation' }
      & FlightDataFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type ManageGuestBookingMutationVariables = Exact<{
  id: Scalars['ID'];
  eventId: Scalars['ID'];
  action: HostBookingAction;
}>;


export type ManageGuestBookingMutation = (
  { __typename?: 'RootMutationType' }
  & { manageGuestBooking: Maybe<(
    { __typename?: 'BookingPayload' }
    & { result: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type UserProfileFragment = (
  { __typename?: 'UserProfile' }
  & Pick<UserProfile, 'avatarUrl' | 'firstName' | 'id' | 'lastName'>
);

export type CommentsFieldsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'body' | 'id' | 'insertedAt'>
  & { user: (
    { __typename?: 'UserProfile' }
    & UserProfileFragment
  ) }
);

export type PostsFieldsFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'body' | 'commentCount' | 'eventId' | 'id' | 'insertedAt' | 'deadline' | 'video' | 'pinned'>
  & { commentsPreview: Array<(
    { __typename?: 'Comment' }
    & CommentsFieldsFragment
  )>, user: (
    { __typename?: 'UserProfile' }
    & UserProfileFragment
  ), tiers: Array<(
    { __typename?: 'Tier' }
    & Pick<Tier, 'id' | 'name'>
  )>, poll: Maybe<(
    { __typename?: 'Poll' }
    & Pick<Poll, 'multi'>
    & { options: Array<(
      { __typename?: 'PollOption' }
      & Pick<PollOption, 'id' | 'name' | 'percent' | 'voted'>
    )> }
  )> }
);

export type FeedQueryVariables = Exact<{
  eventIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  cursor: Maybe<CursorInput>;
}>;


export type FeedQuery = (
  { __typename?: 'RootQueryType' }
  & { feed: (
    { __typename?: 'PaginatedPosts' }
    & Pick<PaginatedPosts, 'cursor'>
    & { entries: Maybe<Array<Maybe<(
      { __typename?: 'Post' }
      & PostsFieldsFragment
    )>>> }
  ), me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  )> }
);

export type CommentsQueryVariables = Exact<{
  postId: Scalars['ID'];
  cursor: Maybe<Scalars['String']>;
}>;


export type CommentsQuery = (
  { __typename?: 'RootQueryType' }
  & { comments: (
    { __typename?: 'PaginatedComments' }
    & Pick<PaginatedComments, 'cursor'>
    & { entries: Array<(
      { __typename?: 'Comment' }
      & CommentsFieldsFragment
    )> }
  ) }
);

export type CreatePostMutationVariables = Exact<{
  eventId: Scalars['ID'];
  tierIds: Array<Scalars['ID']> | Scalars['ID'];
  input: PostInput;
}>;


export type CreatePostMutation = (
  { __typename?: 'RootMutationType' }
  & { createPost: Maybe<(
    { __typename?: 'PostPayload' }
    & { result: Maybe<(
      { __typename?: 'Post' }
      & PostsFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type CreateCommentPostMutationVariables = Exact<{
  postId: Scalars['ID'];
  input: CommentInput;
}>;


export type CreateCommentPostMutation = (
  { __typename?: 'RootMutationType' }
  & { createComment: Maybe<(
    { __typename?: 'CommentPayload' }
    & { result: Maybe<(
      { __typename?: 'Comment' }
      & CommentsFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type PostAddedSubscriptionVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type PostAddedSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & Pick<RootSubscriptionType, 'postAdded'>
);

export type DeletePostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePostMutation = (
  { __typename?: 'RootMutationType' }
  & { deletePost: Maybe<(
    { __typename?: 'PostPayload' }
    & { result: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>> }
  )> }
);

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteComment: Maybe<(
    { __typename?: 'CommentPayload' }
    & { result: Maybe<(
      { __typename?: 'Comment' }
      & CommentsFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>> }
  )> }
);

export type UpdatePostMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PostInput;
  tierIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdatePostMutation = (
  { __typename?: 'RootMutationType' }
  & { updatePost: Maybe<(
    { __typename?: 'PostPayload' }
    & { result: Maybe<(
      { __typename?: 'Post' }
      & PostsFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type PinPostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PinPostMutation = (
  { __typename?: 'RootMutationType' }
  & { pinPost: Maybe<(
    { __typename?: 'PostPayload' }
    & { result: Maybe<(
      { __typename?: 'Post' }
      & PostsFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type UnpinPostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnpinPostMutation = (
  { __typename?: 'RootMutationType' }
  & { unpinPost: Maybe<(
    { __typename?: 'PostPayload' }
    & { result: Maybe<(
      { __typename?: 'Post' }
      & PostsFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CommentInput;
}>;


export type UpdateCommentMutation = (
  { __typename?: 'RootMutationType' }
  & { updateComment: Maybe<(
    { __typename?: 'CommentPayload' }
    & { result: Maybe<(
      { __typename?: 'Comment' }
      & CommentsFieldsFragment
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type VoteMutationVariables = Exact<{
  postId: Scalars['ID'];
  optionId: Scalars['ID'];
}>;


export type VoteMutation = (
  { __typename?: 'RootMutationType' }
  & { vote: Maybe<(
    { __typename?: 'PollOptionListPayload' }
    & { result: Maybe<Array<(
      { __typename?: 'PollOption' }
      & Pick<PollOption, 'id' | 'name' | 'percent' | 'voted'>
    )>>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type VotesQueryVariables = Exact<{
  optionId: Scalars['ID'];
  postId: Scalars['ID'];
}>;


export type VotesQuery = (
  { __typename?: 'RootQueryType' }
  & { votes: Array<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'avatarUrl' | 'firstName' | 'lastName'>
  )> }
);

export type GetBookedEventComponentsQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type GetBookedEventComponentsQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id'>
    )>, eventInfo: (
      { __typename?: 'Event' }
      & GetBookedEventComponentsCommonEventFragment
    ), myBookings: Array<(
      { __typename?: 'Booking' }
      & GetBookedEventComponentsCommonBookingFragment
    )>, invitation: (
      { __typename?: 'InvitationInfo' }
      & Pick<InvitationInfo, 'id'>
      & { components: Array<(
        { __typename?: 'EventComponent' }
        & EventComponentFieldsFragment
      )> }
    ) }
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id'>
    )>, eventInfo: (
      { __typename?: 'Event' }
      & GetBookedEventComponentsCommonEventFragment
    ), myBookings: Array<(
      { __typename?: 'Booking' }
      & GetBookedEventComponentsCommonBookingFragment
    )>, invitation: (
      { __typename?: 'InvitationInfo' }
      & Pick<InvitationInfo, 'id'>
      & { components: Array<(
        { __typename?: 'EventComponent' }
        & EventComponentFieldsFragment
      )> }
    ) }
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { myEventList: Array<(
      { __typename?: 'MyEvent' }
      & Pick<MyEvent, 'id'>
    )>, eventInfo: (
      { __typename?: 'Event' }
      & GetBookedEventComponentsCommonEventFragment
    ), myBookings: Array<(
      { __typename?: 'Booking' }
      & GetBookedEventComponentsCommonBookingFragment
    )>, invitation: (
      { __typename?: 'InvitationInfo' }
      & Pick<InvitationInfo, 'id'>
      & { components: Array<(
        { __typename?: 'EventComponent' }
        & EventComponentFieldsFragment
      )> }
    ) }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetBookedEventComponentsCommonEventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id'>
  & { guestProfile: (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
    & { flightInformation: Maybe<(
      { __typename?: 'FlightInformation' }
      & FlightDataFragment
    )> }
  ) }
);

export type GetBookedEventComponentsCommonBookingFragment = (
  { __typename?: 'Booking' }
  & Pick<Booking, 'id' | 'status' | 'date' | 'duration'>
  & { bookingAddOn: Array<(
    { __typename?: 'BookingAddOn' }
    & Pick<BookingAddOn, 'id'>
    & { offeringAddOn: (
      { __typename?: 'OfferingAddOns' }
      & Pick<OfferingAddOns, 'metadata'>
    ), offeringAddOnOption: Maybe<(
      { __typename?: 'OfferingAddOnOptions' }
      & Pick<OfferingAddOnOptions, 'quantity'>
    )> }
  )>, eventComponent: (
    { __typename?: 'EventComponent' }
    & Pick<EventComponent, 'id' | 'alias' | 'date' | 'duration' | 'bookingCount' | 'capacity'>
    & { booking: Maybe<Array<Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    )>>>, offering: (
      { __typename: 'PackageOffering' }
      & Pick<PackageOffering, 'id' | 'name'>
      & { mainImage: Maybe<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )>, provider: (
        { __typename?: 'Provider' }
        & Pick<Provider, 'airportCode' | 'name' | 'id'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, images: Array<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )> }
      ) }
    ) | (
      { __typename: 'RoomOffering' }
      & Pick<RoomOffering, 'id' | 'name'>
      & { mainImage: Maybe<(
        { __typename?: 'Image' }
        & ImageFieldsFragment
      )>, provider: (
        { __typename?: 'Provider' }
        & Pick<Provider, 'airportCode' | 'name' | 'id'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )>, images: Array<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )> }
      ) }
    ) }
  ) }
);

export type GetMyMainInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyMainInformationQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserBasicData;
}>;


export type UpdateUserMutation = (
  { __typename?: 'RootMutationType' }
  & { updateUser: Maybe<(
    { __typename?: 'UserPayload' }
    & { result: Maybe<(
      { __typename?: 'Admin' }
      & Pick<Admin, 'id' | 'avatarUrl' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'Concierge' }
      & Pick<Concierge, 'id' | 'avatarUrl' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'avatarUrl' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'Guest' }
      & Pick<Guest, 'id' | 'avatarUrl' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'avatarUrl' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'Vendor' }
      & Pick<Vendor, 'id' | 'avatarUrl' | 'firstName' | 'lastName'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'key' | 'message'>
    )>>> }
  )> }
);

export type GetMyInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyInvitationsQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { invitations: Maybe<Array<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'eventId' | 'eventName' | 'status' | 'guestId'>
    )>> }
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetMyProvidersQueryVariables = Exact<{
  cursor: Maybe<Scalars['String']>;
}>;


export type GetMyProvidersQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
    & { myProviders: Maybe<(
      { __typename?: 'PaginatedMyProviders' }
      & Pick<PaginatedMyProviders, 'cursor'>
      & { entries: Array<(
        { __typename?: 'MyProvider' }
        & Pick<MyProvider, 'id' | 'name' | 'url' | 'address'>
      )> }
    )> }
  )> }
);

export type GetNegotiationMessagesVendorQueryVariables = Exact<{
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
  cursor: Maybe<Scalars['String']>;
}>;


export type GetNegotiationMessagesVendorQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
    & { negotiation: (
      { __typename?: 'Negotiation' }
      & { quotes: Array<(
        { __typename?: 'Quote' }
        & { eventComponent: (
          { __typename?: 'EventComponent' }
          & Pick<EventComponent, 'id'>
          & { offering: (
            { __typename: 'PackageOffering' }
            & Pick<PackageOffering, 'name'>
          ) | (
            { __typename: 'RoomOffering' }
            & Pick<RoomOffering, 'name'>
          ) }
        ) }
      )>, messages: Maybe<(
        { __typename?: 'PaginatedNegotiationMessages' }
        & Pick<PaginatedNegotiationMessages, 'cursor'>
        & { entries: Array<(
          { __typename?: 'NegotiationMessage' }
          & MessagesFieldsFragment
        )> }
      )> }
    ) }
  )> }
);

export type GetNegotiationQuotesVendorQueryVariables = Exact<{
  eventId: Scalars['ID'];
  providerId: Scalars['ID'];
}>;


export type GetNegotiationQuotesVendorQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
    & { negotiation: (
      { __typename?: 'Negotiation' }
      & { provider: (
        { __typename?: 'ProviderPreview' }
        & Pick<ProviderPreview, 'id' | 'name'>
        & { mainImage: Maybe<(
          { __typename?: 'Image' }
          & ImageFieldsFragment
        )> }
      ), event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name'>
        & { host: (
          { __typename?: 'HostBasicInfo' }
          & Pick<HostBasicInfo, 'firstName' | 'lastName' | 'avatarUrl'>
        ) }
      ), quotes: Array<(
        { __typename?: 'Quote' }
        & { eventComponent: (
          { __typename?: 'EventComponent' }
          & Pick<EventComponent, 'id' | 'status' | 'date' | 'duration'>
          & { offering: (
            { __typename: 'PackageOffering' }
            & { addOns: Array<(
              { __typename?: 'OfferingAddOns' }
              & Pick<OfferingAddOns, 'id' | 'frequency' | 'label' | 'price' | 'mandatory'>
              & { offeringAddOnOptions: Array<(
                { __typename?: 'OfferingAddOnOptions' }
                & Pick<OfferingAddOnOptions, 'id' | 'description' | 'label' | 'price'>
              )> }
            )> }
          ) | { __typename: 'RoomOffering' }, packageCustomization: Array<(
            { __typename?: 'PackageAddons' }
            & PackageAddonsFieldsFragment
          )> }
        ), items: Array<(
          { __typename?: 'NegotiationItem' }
          & NegotiationItemFieldsFragment
        )> }
      )> }
    ) }
  )> }
);

export type GetNegotiationsPreviewVendorQueryVariables = Exact<{
  cursor: Maybe<Scalars['String']>;
}>;


export type GetNegotiationsPreviewVendorQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id'>
  ) | (
    { __typename?: 'Concierge' }
    & Pick<Concierge, 'id'>
  ) | (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
  ) | (
    { __typename?: 'Guest' }
    & Pick<Guest, 'id'>
  ) | (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
  ) | (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
    & { negotiations: Maybe<(
      { __typename?: 'PaginatedNegotiationPreviews' }
      & Pick<PaginatedNegotiationPreviews, 'cursor'>
      & { entries: Array<(
        { __typename?: 'NegotiationPreview' }
        & Pick<NegotiationPreview, 'lastUpdate'>
        & { event: (
          { __typename?: 'Event' }
          & Pick<Event, 'id' | 'name'>
        ), provider: (
          { __typename?: 'ProviderPreview' }
          & Pick<ProviderPreview, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export const GuestFieldsFragmentDoc = gql`
    fragment GuestFields on Guest {
  avatarUrl
  canPost
  email
  event {
    id
    name
  }
  firstName
  id
  kidsAllowed
  lastName
  phone
  plusOne
  status
  tiers {
    id
    name
  }
}
    `;
export const MyEventFieldsCommonFragmentDoc = gql`
    fragment myEventFieldsCommon on MyEvent {
  defaultKidsAllowed
  defaultPlusOne
  guestCanPost
  mainImage
  id
  name
  tiers {
    id
    name
  }
  guests(tierIds: $tierIds) {
    ...GuestFields
  }
  paidComponents(eventId: $id)
}
    ${GuestFieldsFragmentDoc}`;
export const EventFieldsFragmentDoc = gql`
    fragment EventFields on MyEvent {
  guests {
    ...GuestFields
  }
  defaultKidsAllowed
  defaultPlusOne
  guestCanPost
  id
  name
  customUrl
  isPrivate
  description
  tiers {
    id
    name
  }
  mainImage
}
    ${GuestFieldsFragmentDoc}`;
export const ImageFieldsFragmentDoc = gql`
    fragment ImageFields on Image {
  assocId
  assocType
  ext
  id
  metadata
}
    `;
export const CommonEventFieldsFragmentDoc = gql`
    fragment commonEventFields on MyEvent {
  ...EventFields
  paidComponents(eventId: $id)
  emailCustomText
  components {
    main
    date
    offering {
      __typename
      ... on PackageOffering {
        provider {
          name
          address
          location
          mainImage {
            ...ImageFields
          }
        }
      }
      ... on RoomOffering {
        provider {
          name
          address
          location
          mainImage {
            ...ImageFields
          }
        }
      }
    }
  }
}
    ${EventFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const GetAllEventsCommonMeFragmentDoc = gql`
    fragment getAllEventsCommonME on MyEvent {
  defaultKidsAllowed
  defaultPlusOne
  guestCanPost
  id
  name
  mainImage
  tiers {
    id
    name
  }
}
    `;
export const GetAllEventsCommonEFragmentDoc = gql`
    fragment getAllEventsCommonE on Event {
  id
  name
  mainImage
  guestProfile {
    id
    canPost
  }
  host {
    avatarUrl
  }
  tiers {
    id
    name
  }
}
    `;
export const GetMyEventsCommonFragmentDoc = gql`
    fragment getMyEventsCommon on MyEvent {
  id
  name
  components {
    offering {
      __typename
      ... on PackageOffering {
        id
      }
      ... on RoomOffering {
        id
      }
    }
  }
}
    `;
export const GetEventBalancesCommonFragmentDoc = gql`
    fragment getEventBalancesCommon on MyEvent {
  id
  balances(eventId: $eventId, providers: $providers) {
    balance
    provider
    lastMessage {
      seen
      user {
        id
      }
    }
    quotes {
      eventComponent {
        id
        status
        date
        offering {
          __typename
        }
      }
      items {
        deleted
        price
      }
    }
  }
}
    `;
export const PackageFieldsFragmentDoc = gql`
    fragment PackageFields on PackageOffering {
  description
  id
  images {
    ...ImageFields
  }
  intro
  mainImage {
    ...ImageFields
  }
  name
  package {
    baseGuestNumber
    basePrice
    minimumGuests
  }
  price
  provider {
    name
    id
    url
    images {
      ...ImageFields
    }
    mainImage {
      ...ImageFields
    }
  }
  quantity
}
    ${ImageFieldsFragmentDoc}`;
export const RoomFieldsFragmentDoc = gql`
    fragment RoomFields on RoomOffering {
  description
  id
  images {
    ...ImageFields
  }
  intro
  mainImage {
    ...ImageFields
  }
  name
  price
  marketPrice
  provider {
    name
    id
    url
    images {
      ...ImageFields
    }
    mainImage {
      ...ImageFields
    }
  }
  quantity
  room {
    beds
    occupancy
    size
  }
}
    ${ImageFieldsFragmentDoc}`;
export const PackageAddonsFieldsFragmentDoc = gql`
    fragment PackageAddonsFields on PackageAddons {
  location
  time
  id
  eventComponentId
  offeringAddOn {
    id
    label
    description
    images {
      ...ImageFields
    }
  }
  offeringAddOnOption {
    id
    label
    description
  }
}
    ${ImageFieldsFragmentDoc}`;
export const GetEventComponentsCommonFragmentDoc = gql`
    fragment getEventComponentsCommon on MyEvent {
  id
  name
  tiers {
    id
    name
  }
  components(filterRecent: true) {
    main
    capacity
    date
    duration
    id
    guestPays
    tiers {
      id
    }
    offering {
      __typename
      ... on PackageOffering {
        ...PackageFields
      }
      ... on RoomOffering {
        ...RoomFields
      }
    }
    status
    main
    items {
      price
      deleted
    }
    packageCustomization {
      ...PackageAddonsFields
    }
  }
}
    ${PackageFieldsFragmentDoc}
${RoomFieldsFragmentDoc}
${PackageAddonsFieldsFragmentDoc}`;
export const MessagesFieldsFragmentDoc = gql`
    fragment MessagesFields on NegotiationMessage {
  body
  id
  insertedAt
  item {
    deleted
    id
    price
    title
    eventComponentId
  }
  user {
    avatarUrl
    firstName
    id
    lastName
  }
  seen
}
    `;
export const GetNegotiationMessagesCommonFragmentDoc = gql`
    fragment getNegotiationMessagesCommon on MyEvent {
  id
  negotiation(eventId: $eventId, providerId: $providerId) {
    quotes {
      eventComponent {
        id
        offering {
          __typename
          ... on PackageOffering {
            name
          }
          ... on RoomOffering {
            name
          }
        }
      }
    }
    messages(cursor: {cursor: $cursor, limit: 15}) {
      cursor
      entries {
        ...MessagesFields
      }
    }
  }
}
    ${MessagesFieldsFragmentDoc}`;
export const NegotiationItemFieldsFragmentDoc = gql`
    fragment negotiationItemFields on NegotiationItem {
  deleted
  id
  master
  price
  title
}
    `;
export const GetNegotiationQuotesCommonFragmentDoc = gql`
    fragment getNegotiationQuotesCommon on MyEvent {
  id
  negotiation(eventId: $eventId, providerId: $providerId) {
    balance
    provider {
      id
      name
      mainImage {
        ...ImageFields
      }
      vendor {
        avatarUrl
      }
    }
    event {
      id
      name
    }
    quotes {
      eventComponent {
        id
        status
        date
        alias
        duration
        offering {
          __typename
          ... on PackageOffering {
            addOns {
              id
              frequency
              label
              price
              mandatory
              offeringAddOnOptions {
                id
                description
                label
                price
              }
            }
          }
        }
        packageCustomization {
          ...PackageAddonsFields
        }
      }
      items {
        ...negotiationItemFields
      }
    }
  }
}
    ${ImageFieldsFragmentDoc}
${PackageAddonsFieldsFragmentDoc}
${NegotiationItemFieldsFragmentDoc}`;
export const GetNegotiationsPreviewCommonFragmentDoc = gql`
    fragment getNegotiationsPreviewCommon on MyEvent {
  id
  negotiations(cursor: {cursor: $cursor, limit: 5}) {
    cursor
    entries {
      event {
        id
        name
      }
      lastUpdate
      provider {
        id
        name
      }
    }
  }
}
    `;
export const FlightDataFragmentDoc = gql`
    fragment flightData on FlightInformation {
  adults
  arrivalAirline
  arrivalAirport
  arrivalDatetime
  arrivalFlight
  arrivalLocation
  departureAirline
  departureAirport
  departureDatetime
  departureFlight
  departureLocation
  guestNames
  id
  kids
  propertyName
}
    `;
export const GuestFieldsWithBookingFragmentDoc = gql`
    fragment GuestFieldsWithBooking on Guest {
  avatarUrl
  canPost
  email
  firstName
  id
  kidsAllowed
  lastName
  phone
  plusOne
  status
  seen
  preInvitationSent
  reminderSentAt
  tiers {
    id
    name
  }
  flightInformation {
    ...flightData
  }
  booking {
    id
    status
    guest {
      id
    }
    bookingAddOn {
      id
      bookingGuest {
        email
        firstName
        lastName
        plusone
        guest {
          id
        }
      }
    }
    eventComponent {
      id
      offering {
        __typename
      }
    }
  }
}
    ${FlightDataFragmentDoc}`;
export const EventFieldsCommonFragmentDoc = gql`
    fragment EventFieldsCommon on MyEvent {
  defaultKidsAllowed
  defaultPlusOne
  guestCanPost
  mainImage
  id
  name
  tiers {
    id
    name
  }
  guests(tierIds: $tierIds) {
    ...GuestFieldsWithBooking
    guests {
      ...GuestFieldsWithBooking
    }
    role {
      id
      name
    }
  }
  paidComponents(eventId: $id)
}
    ${GuestFieldsWithBookingFragmentDoc}`;
export const NotificationFieldsFragmentDoc = gql`
    fragment NotificationFields on Notification {
  dismissed
  id
  payload {
    ... on EventComponentAddedBody {
      eventComponentId
      eventId
      image {
        assocId
        assocType
        ext
        id
      }
      message
    }
    ... on NoneBody {
      body
    }
    ... on RsvpBody {
      eventComponentId
      eventComponentName
      guestId
      guestName
    }
    ... on SimpleNotificationBody {
      message
      meta
    }
  }
  seen
  volatile
}
    `;
export const ProviderFieldsPublicFragmentDoc = gql`
    fragment ProviderFieldsPublic on Provider {
  id
  name
  url
  mainImage {
    ...ImageFields
  }
  images {
    ...ImageFields
  }
  address
  location
  coord
  bookAllRequired
  description
  airportCode
  timeToAirport
  metadata
  accomodationTaxRate
  venueTaxRate
}
    ${ImageFieldsFragmentDoc}`;
export const OfferingAddonsFieldsFragmentDoc = gql`
    fragment OfferingAddonsFields on OfferingAddOns {
  id
  frequency
  label
  price
  mandatory
  quantity
  metadata
  type
  offeringAddOnOptions {
    id
    description
    label
    price
    quantity
    offeringAddOnId
  }
}
    `;
export const RoomFieldsPublicFragmentDoc = gql`
    fragment RoomFieldsPublic on RoomOffering {
  description
  id
  images {
    ...ImageFields
  }
  intro
  mainImage {
    ...ImageFields
  }
  name
  price
  provider {
    ...ProviderFieldsPublic
  }
  quantity
  room {
    beds
    occupancy
    size
    basic
  }
  addOns {
    ...OfferingAddonsFields
  }
}
    ${ImageFieldsFragmentDoc}
${ProviderFieldsPublicFragmentDoc}
${OfferingAddonsFieldsFragmentDoc}`;
export const PackageFieldsPublicFragmentDoc = gql`
    fragment PackageFieldsPublic on PackageOffering {
  id
  name
  intro
  price
  description
  images {
    ...ImageFields
  }
  mainImage {
    ...ImageFields
  }
  package {
    baseGuestNumber
    basePrice
    minimumGuests
    basic
  }
  provider {
    ...ProviderFieldsPublic
  }
  quantity
  addOns {
    ...OfferingAddonsFields
  }
}
    ${ImageFieldsFragmentDoc}
${ProviderFieldsPublicFragmentDoc}
${OfferingAddonsFieldsFragmentDoc}`;
export const BookingAddonsFieldsFragmentDoc = gql`
    fragment BookingAddonsFields on BookingAddOn {
  id
  offeringAddOnOption {
    id
    label
    price
    description
    quantity
    offeringAddOnId
  }
  offeringAddOn {
    id
    frequency
    label
    mandatory
    taxable
    format
    price
    type
    metadata
  }
  bookingGuest {
    firstName
    lastName
    phone
    email
    plusone
    guest {
      id
      flightInformation {
        ...flightData
      }
    }
  }
}
    ${FlightDataFragmentDoc}`;
export const ProviderFieldsFragmentDoc = gql`
    fragment ProviderFields on Provider {
  bookAllRequired
  airportCode
  location
  id
  address
  url
  name
  accomodationTaxRate
  venueTaxRate
  cancellationPolicy
}
    `;
export const EventComponentFieldsFragmentDoc = gql`
    fragment EventComponentFields on EventComponent {
  id
  date
  duration
  alias
  status
  capacity
  eventId
  customTexts
  order
  booking {
    id
    status
  }
  main
  packageCustomization {
    ...PackageAddonsFields
  }
  tiers {
    id
    name
  }
  bookingCount
  guestPays
  offering {
    __typename
    ... on PackageOffering {
      name
      intro
      description
      mainImage {
        ...ImageFields
      }
      images {
        ...ImageFields
      }
      provider {
        timeToAirport
        airportCode
        coord
        id
        url
        address
        location
        name
        mainImage {
          ...ImageFields
        }
        images {
          ...ImageFields
        }
      }
    }
    ... on RoomOffering {
      name
      description
      mainImage {
        ...ImageFields
      }
      images {
        ...ImageFields
      }
      price
      room {
        beds
        occupancy
        size
        basic
      }
      provider {
        timeToAirport
        airportCode
        coord
        id
        url
        address
        location
        name
        mainImage {
          ...ImageFields
        }
        images {
          ...ImageFields
        }
      }
    }
  }
}
    ${PackageAddonsFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const CommonEventFieldsRsvpFragmentDoc = gql`
    fragment commonEventFieldsRSVP on MyEvent {
  defaultKidsAllowed
  defaultPlusOne
  id
  name
  mainImage
  description
  components {
    ...EventComponentFields
  }
  video
  cover
  brideFirstName
  brideLastName
  groomFirstName
  groomLastName
  invitationTemplate {
    template
  }
}
    ${EventComponentFieldsFragmentDoc}`;
export const UserProfileFragmentDoc = gql`
    fragment userProfile on UserProfile {
  avatarUrl
  firstName
  id
  lastName
}
    `;
export const CommentsFieldsFragmentDoc = gql`
    fragment commentsFields on Comment {
  body
  id
  insertedAt
  user {
    ...userProfile
  }
}
    ${UserProfileFragmentDoc}`;
export const PostsFieldsFragmentDoc = gql`
    fragment postsFields on Post {
  body
  commentCount
  commentsPreview {
    ...commentsFields
  }
  eventId
  id
  insertedAt
  user {
    ...userProfile
  }
  tiers {
    id
    name
  }
  deadline
  video
  pinned
  poll {
    multi
    options {
      id
      name
      percent
      voted
    }
  }
}
    ${CommentsFieldsFragmentDoc}
${UserProfileFragmentDoc}`;
export const GetBookedEventComponentsCommonEventFragmentDoc = gql`
    fragment getBookedEventComponentsCommonEvent on Event {
  id
  guestProfile {
    id
    flightInformation {
      ...flightData
    }
  }
}
    ${FlightDataFragmentDoc}`;
export const GetBookedEventComponentsCommonBookingFragmentDoc = gql`
    fragment getBookedEventComponentsCommonBooking on Booking {
  id
  status
  date
  duration
  bookingAddOn {
    id
    offeringAddOn {
      metadata
    }
    offeringAddOnOption {
      quantity
    }
  }
  eventComponent {
    id
    alias
    date
    duration
    bookingCount
    capacity
    booking {
      id
      status
    }
    offering {
      __typename
      ... on PackageOffering {
        id
        name
        mainImage {
          ...ImageFields
        }
        provider {
          airportCode
          name
          id
          mainImage {
            ...ImageFields
          }
          images {
            ...ImageFields
          }
        }
      }
      ... on RoomOffering {
        id
        name
        mainImage {
          ...ImageFields
        }
        provider {
          airportCode
          name
          id
          mainImage {
            ...ImageFields
          }
          images {
            ...ImageFields
          }
        }
      }
    }
  }
}
    ${ImageFieldsFragmentDoc}`;
export const CreateTokenDocument = gql`
    mutation createToken($email: String!, $password: String!) {
  createToken(email: $email, password: $password) {
    result {
      accessToken
      avatarUrl
      firstName
      lastName
      refreshToken
      role
      userId
      email
    }
    errors {
      key
      message
    }
  }
}
    `;
export type CreateTokenMutationFn = Apollo.MutationFunction<CreateTokenMutation, CreateTokenMutationVariables>;

/**
 * __useCreateTokenMutation__
 *
 * To run a mutation, you first call `useCreateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTokenMutation, { data, loading, error }] = useCreateTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateTokenMutation, CreateTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTokenMutation, CreateTokenMutationVariables>(CreateTokenDocument, options);
      }
export type CreateTokenMutationHookResult = ReturnType<typeof useCreateTokenMutation>;
export type CreateTokenMutationResult = Apollo.MutationResult<CreateTokenMutation>;
export type CreateTokenMutationOptions = Apollo.BaseMutationOptions<CreateTokenMutation, CreateTokenMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($token: String!) {
  refreshToken(token: $token) {
    result {
      accessToken
      refreshToken
    }
    errors {
      key
      message
    }
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: PasswordInput!) {
  resetPassword(input: $input) {
    result {
      accessToken
      accessTokenExpiresAt
      avatarUrl
      firstName
      lastName
      refreshToken
      refreshTokenExpiresAt
    }
    errors {
      key
      message
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const VerifyAccountDocument = gql`
    mutation verifyAccount($secret: String!, $event: String) {
  verifyAccount(secret: $secret, event: $event) {
    errors {
      key
      message
    }
    result {
      isVerified
      token {
        accessToken
        avatarUrl
        firstName
        lastName
        refreshToken
        role
        userId
      }
    }
  }
}
    `;
export type VerifyAccountMutationFn = Apollo.MutationFunction<VerifyAccountMutation, VerifyAccountMutationVariables>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      secret: // value for 'secret'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useVerifyAccountMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAccountMutation, VerifyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAccountMutation, VerifyAccountMutationVariables>(VerifyAccountDocument, options);
      }
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>;
export type VerifyAccountMutationResult = Apollo.MutationResult<VerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<VerifyAccountMutation, VerifyAccountMutationVariables>;
export const PasswordResetLinkDocument = gql`
    mutation passwordResetLink($email: String) {
  passwordResetLink(email: $email) {
    errors {
      key
      message
    }
  }
}
    `;
export type PasswordResetLinkMutationFn = Apollo.MutationFunction<PasswordResetLinkMutation, PasswordResetLinkMutationVariables>;

/**
 * __usePasswordResetLinkMutation__
 *
 * To run a mutation, you first call `usePasswordResetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetLinkMutation, { data, loading, error }] = usePasswordResetLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordResetLinkMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetLinkMutation, PasswordResetLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordResetLinkMutation, PasswordResetLinkMutationVariables>(PasswordResetLinkDocument, options);
      }
export type PasswordResetLinkMutationHookResult = ReturnType<typeof usePasswordResetLinkMutation>;
export type PasswordResetLinkMutationResult = Apollo.MutationResult<PasswordResetLinkMutation>;
export type PasswordResetLinkMutationOptions = Apollo.BaseMutationOptions<PasswordResetLinkMutation, PasswordResetLinkMutationVariables>;
export const SetPasswordLandingUserDocument = gql`
    mutation setPasswordLandingUser($token: String!, $password: String!, $event: ID) {
  setPasswordLandingUser(token: $token, password: $password, event: $event) {
    result {
      isVerified
      token {
        accessToken
        avatarUrl
        firstName
        lastName
        refreshToken
        role
        userId
      }
    }
    errors {
      key
      message
    }
  }
}
    `;
export type SetPasswordLandingUserMutationFn = Apollo.MutationFunction<SetPasswordLandingUserMutation, SetPasswordLandingUserMutationVariables>;

/**
 * __useSetPasswordLandingUserMutation__
 *
 * To run a mutation, you first call `useSetPasswordLandingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordLandingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordLandingUserMutation, { data, loading, error }] = useSetPasswordLandingUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useSetPasswordLandingUserMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordLandingUserMutation, SetPasswordLandingUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordLandingUserMutation, SetPasswordLandingUserMutationVariables>(SetPasswordLandingUserDocument, options);
      }
export type SetPasswordLandingUserMutationHookResult = ReturnType<typeof useSetPasswordLandingUserMutation>;
export type SetPasswordLandingUserMutationResult = Apollo.MutationResult<SetPasswordLandingUserMutation>;
export type SetPasswordLandingUserMutationOptions = Apollo.BaseMutationOptions<SetPasswordLandingUserMutation, SetPasswordLandingUserMutationVariables>;
export const GetUserTokenDocument = gql`
    mutation getUserToken($token: String!) {
  getUserToken(token: $token) {
    errors {
      key
      message
    }
    result {
      accessToken
      avatarUrl
      firstName
      lastName
      refreshToken
      role
      userId
    }
  }
}
    `;
export type GetUserTokenMutationFn = Apollo.MutationFunction<GetUserTokenMutation, GetUserTokenMutationVariables>;

/**
 * __useGetUserTokenMutation__
 *
 * To run a mutation, you first call `useGetUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUserTokenMutation, { data, loading, error }] = useGetUserTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetUserTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetUserTokenMutation, GetUserTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUserTokenMutation, GetUserTokenMutationVariables>(GetUserTokenDocument, options);
      }
export type GetUserTokenMutationHookResult = ReturnType<typeof useGetUserTokenMutation>;
export type GetUserTokenMutationResult = Apollo.MutationResult<GetUserTokenMutation>;
export type GetUserTokenMutationOptions = Apollo.BaseMutationOptions<GetUserTokenMutation, GetUserTokenMutationVariables>;
export const GetBlogPostsDocument = gql`
    query getBlogPosts($limit: Int = 16, $cursor: String) {
  blog(cursor: {cursor: $cursor, limit: $limit}) {
    entries {
      id
      body
      title
      url
      location
      images {
        ...ImageFields
      }
    }
    cursor
  }
}
    ${ImageFieldsFragmentDoc}`;

/**
 * __useGetBlogPostsQuery__
 *
 * To run a query within a React component, call `useGetBlogPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetBlogPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, options);
      }
export function useGetBlogPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, options);
        }
export type GetBlogPostsQueryHookResult = ReturnType<typeof useGetBlogPostsQuery>;
export type GetBlogPostsLazyQueryHookResult = ReturnType<typeof useGetBlogPostsLazyQuery>;
export type GetBlogPostsQueryResult = Apollo.QueryResult<GetBlogPostsQuery, GetBlogPostsQueryVariables>;
export const GetBlogPostDocument = gql`
    query getBlogPost($url: String) {
  blog(url: $url) {
    entries {
      author
      body
      credits {
        business
        service
      }
      id
      insertedAt
      location
      title
      url
      images {
        ...ImageFields
      }
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

/**
 * __useGetBlogPostQuery__
 *
 * To run a query within a React component, call `useGetBlogPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogPostQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetBlogPostQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogPostQuery, GetBlogPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogPostQuery, GetBlogPostQueryVariables>(GetBlogPostDocument, options);
      }
export function useGetBlogPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogPostQuery, GetBlogPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogPostQuery, GetBlogPostQueryVariables>(GetBlogPostDocument, options);
        }
export type GetBlogPostQueryHookResult = ReturnType<typeof useGetBlogPostQuery>;
export type GetBlogPostLazyQueryHookResult = ReturnType<typeof useGetBlogPostLazyQuery>;
export type GetBlogPostQueryResult = Apollo.QueryResult<GetBlogPostQuery, GetBlogPostQueryVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($input: EventInput!) {
  createEvent(input: $input) {
    result {
      id
      defaultKidsAllowed
      defaultPlusOne
      guestCanPost
      name
      tiers {
        id
        name
      }
      description
      emailCustomText
      mainImage
    }
    errors {
      key
      message
    }
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($id: ID!, $input: EventInput!) {
  updateEvent(id: $id, input: $input) {
    result {
      id
      name
      defaultKidsAllowed
      defaultPlusOne
      description
      emailCustomText
      mainImage
      isPrivate
      customUrl
    }
    errors {
      key
      message
    }
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: ID!) {
  deleteEvent(id: $id) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const GetMyEventDocument = gql`
    query getMyEvent($id: ID!, $tierIds: [ID!]) {
  me {
    id
    ... on Customer {
      myEventInfo(id: $id) {
        ...myEventFieldsCommon
      }
    }
    ... on Concierge {
      myEventInfo(id: $id) {
        ...myEventFieldsCommon
      }
    }
    ... on Admin {
      myEventInfo(id: $id) {
        ...myEventFieldsCommon
      }
    }
  }
}
    ${MyEventFieldsCommonFragmentDoc}`;

/**
 * __useGetMyEventQuery__
 *
 * To run a query within a React component, call `useGetMyEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tierIds: // value for 'tierIds'
 *   },
 * });
 */
export function useGetMyEventQuery(baseOptions: Apollo.QueryHookOptions<GetMyEventQuery, GetMyEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEventQuery, GetMyEventQueryVariables>(GetMyEventDocument, options);
      }
export function useGetMyEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEventQuery, GetMyEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEventQuery, GetMyEventQueryVariables>(GetMyEventDocument, options);
        }
export type GetMyEventQueryHookResult = ReturnType<typeof useGetMyEventQuery>;
export type GetMyEventLazyQueryHookResult = ReturnType<typeof useGetMyEventLazyQuery>;
export type GetMyEventQueryResult = Apollo.QueryResult<GetMyEventQuery, GetMyEventQueryVariables>;
export const GetMyEventInfoDocument = gql`
    query getMyEventInfo($id: ID!) {
  me {
    id
    ... on Customer {
      myEventInfo(id: $id) {
        ...commonEventFields
      }
    }
    ... on Concierge {
      myEventInfo(id: $id) {
        ...commonEventFields
      }
    }
    ... on Admin {
      myEventInfo(id: $id) {
        ...commonEventFields
      }
    }
  }
}
    ${CommonEventFieldsFragmentDoc}`;

/**
 * __useGetMyEventInfoQuery__
 *
 * To run a query within a React component, call `useGetMyEventInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEventInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEventInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMyEventInfoQuery(baseOptions: Apollo.QueryHookOptions<GetMyEventInfoQuery, GetMyEventInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEventInfoQuery, GetMyEventInfoQueryVariables>(GetMyEventInfoDocument, options);
      }
export function useGetMyEventInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEventInfoQuery, GetMyEventInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEventInfoQuery, GetMyEventInfoQueryVariables>(GetMyEventInfoDocument, options);
        }
export type GetMyEventInfoQueryHookResult = ReturnType<typeof useGetMyEventInfoQuery>;
export type GetMyEventInfoLazyQueryHookResult = ReturnType<typeof useGetMyEventInfoLazyQuery>;
export type GetMyEventInfoQueryResult = Apollo.QueryResult<GetMyEventInfoQuery, GetMyEventInfoQueryVariables>;
export const GetAllEventsDocument = gql`
    query getAllEvents {
  me {
    id
    ... on Customer {
      myEventList {
        ...getAllEventsCommonME
      }
      eventList {
        ...getAllEventsCommonE
      }
    }
    ... on Admin {
      myEventList {
        ...getAllEventsCommonME
      }
    }
    ... on Concierge {
      myEventList {
        ...getAllEventsCommonME
      }
    }
  }
}
    ${GetAllEventsCommonMeFragmentDoc}
${GetAllEventsCommonEFragmentDoc}`;

/**
 * __useGetAllEventsQuery__
 *
 * To run a query within a React component, call `useGetAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(GetAllEventsDocument, options);
      }
export function useGetAllEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(GetAllEventsDocument, options);
        }
export type GetAllEventsQueryHookResult = ReturnType<typeof useGetAllEventsQuery>;
export type GetAllEventsLazyQueryHookResult = ReturnType<typeof useGetAllEventsLazyQuery>;
export type GetAllEventsQueryResult = Apollo.QueryResult<GetAllEventsQuery, GetAllEventsQueryVariables>;
export const GetMyEventsIdsDocument = gql`
    query getMyEventsIds {
  me {
    id
    ... on Customer {
      myEventList {
        id
      }
    }
    ... on Concierge {
      myEventList {
        id
      }
    }
    ... on Admin {
      myEventList {
        id
      }
    }
  }
}
    `;

/**
 * __useGetMyEventsIdsQuery__
 *
 * To run a query within a React component, call `useGetMyEventsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEventsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEventsIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyEventsIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyEventsIdsQuery, GetMyEventsIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEventsIdsQuery, GetMyEventsIdsQueryVariables>(GetMyEventsIdsDocument, options);
      }
export function useGetMyEventsIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEventsIdsQuery, GetMyEventsIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEventsIdsQuery, GetMyEventsIdsQueryVariables>(GetMyEventsIdsDocument, options);
        }
export type GetMyEventsIdsQueryHookResult = ReturnType<typeof useGetMyEventsIdsQuery>;
export type GetMyEventsIdsLazyQueryHookResult = ReturnType<typeof useGetMyEventsIdsLazyQuery>;
export type GetMyEventsIdsQueryResult = Apollo.QueryResult<GetMyEventsIdsQuery, GetMyEventsIdsQueryVariables>;
export const GetMyEventsDocument = gql`
    query getMyEvents {
  me {
    id
    ... on Customer {
      myEventList {
        ...getMyEventsCommon
      }
    }
    ... on Concierge {
      myEventList {
        ...getMyEventsCommon
      }
    }
    ... on Admin {
      myEventList {
        ...getMyEventsCommon
      }
    }
  }
}
    ${GetMyEventsCommonFragmentDoc}`;

/**
 * __useGetMyEventsQuery__
 *
 * To run a query within a React component, call `useGetMyEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyEventsQuery, GetMyEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEventsQuery, GetMyEventsQueryVariables>(GetMyEventsDocument, options);
      }
export function useGetMyEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEventsQuery, GetMyEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEventsQuery, GetMyEventsQueryVariables>(GetMyEventsDocument, options);
        }
export type GetMyEventsQueryHookResult = ReturnType<typeof useGetMyEventsQuery>;
export type GetMyEventsLazyQueryHookResult = ReturnType<typeof useGetMyEventsLazyQuery>;
export type GetMyEventsQueryResult = Apollo.QueryResult<GetMyEventsQuery, GetMyEventsQueryVariables>;
export const GetEventBalancesDocument = gql`
    query getEventBalances($eventId: ID!, $providers: [ID!]!) {
  me {
    id
    ... on Customer {
      id
      myEventInfo(id: $eventId) {
        ...getEventBalancesCommon
      }
    }
    ... on Concierge {
      id
      myEventInfo(id: $eventId) {
        ...getEventBalancesCommon
      }
    }
    ... on Admin {
      id
      myEventInfo(id: $eventId) {
        ...getEventBalancesCommon
      }
    }
  }
}
    ${GetEventBalancesCommonFragmentDoc}`;

/**
 * __useGetEventBalancesQuery__
 *
 * To run a query within a React component, call `useGetEventBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventBalancesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      providers: // value for 'providers'
 *   },
 * });
 */
export function useGetEventBalancesQuery(baseOptions: Apollo.QueryHookOptions<GetEventBalancesQuery, GetEventBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventBalancesQuery, GetEventBalancesQueryVariables>(GetEventBalancesDocument, options);
      }
export function useGetEventBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventBalancesQuery, GetEventBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventBalancesQuery, GetEventBalancesQueryVariables>(GetEventBalancesDocument, options);
        }
export type GetEventBalancesQueryHookResult = ReturnType<typeof useGetEventBalancesQuery>;
export type GetEventBalancesLazyQueryHookResult = ReturnType<typeof useGetEventBalancesLazyQuery>;
export type GetEventBalancesQueryResult = Apollo.QueryResult<GetEventBalancesQuery, GetEventBalancesQueryVariables>;
export const GetEventComponentsDocument = gql`
    query getEventComponents($id: ID!) {
  me {
    id
    ... on Customer {
      id
      myEventInfo(id: $id) {
        ...getEventComponentsCommon
      }
    }
    ... on Concierge {
      id
      myEventInfo(id: $id) {
        ...getEventComponentsCommon
      }
    }
    ... on Admin {
      id
      myEventInfo(id: $id) {
        ...getEventComponentsCommon
      }
    }
  }
}
    ${GetEventComponentsCommonFragmentDoc}`;

/**
 * __useGetEventComponentsQuery__
 *
 * To run a query within a React component, call `useGetEventComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventComponentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventComponentsQuery(baseOptions: Apollo.QueryHookOptions<GetEventComponentsQuery, GetEventComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventComponentsQuery, GetEventComponentsQueryVariables>(GetEventComponentsDocument, options);
      }
export function useGetEventComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventComponentsQuery, GetEventComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventComponentsQuery, GetEventComponentsQueryVariables>(GetEventComponentsDocument, options);
        }
export type GetEventComponentsQueryHookResult = ReturnType<typeof useGetEventComponentsQuery>;
export type GetEventComponentsLazyQueryHookResult = ReturnType<typeof useGetEventComponentsLazyQuery>;
export type GetEventComponentsQueryResult = Apollo.QueryResult<GetEventComponentsQuery, GetEventComponentsQueryVariables>;
export const CreateEventComponentDocument = gql`
    mutation createEventComponent($eventId: ID!, $offeringId: ID!, $input: EventComponentInput!) {
  createEventComponent(eventId: $eventId, offeringId: $offeringId, input: $input) {
    result {
      capacity
      date
      duration
      id
      tiers {
        id
      }
      guestPays
      offering {
        __typename
        ... on PackageOffering {
          ...PackageFields
        }
        ... on RoomOffering {
          ...RoomFields
        }
      }
      status
      main
    }
    errors {
      key
      message
    }
  }
}
    ${PackageFieldsFragmentDoc}
${RoomFieldsFragmentDoc}`;
export type CreateEventComponentMutationFn = Apollo.MutationFunction<CreateEventComponentMutation, CreateEventComponentMutationVariables>;

/**
 * __useCreateEventComponentMutation__
 *
 * To run a mutation, you first call `useCreateEventComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventComponentMutation, { data, loading, error }] = useCreateEventComponentMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      offeringId: // value for 'offeringId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventComponentMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventComponentMutation, CreateEventComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventComponentMutation, CreateEventComponentMutationVariables>(CreateEventComponentDocument, options);
      }
export type CreateEventComponentMutationHookResult = ReturnType<typeof useCreateEventComponentMutation>;
export type CreateEventComponentMutationResult = Apollo.MutationResult<CreateEventComponentMutation>;
export type CreateEventComponentMutationOptions = Apollo.BaseMutationOptions<CreateEventComponentMutation, CreateEventComponentMutationVariables>;
export const UpdateEventComponentDocument = gql`
    mutation updateEventComponent($id: ID!, $input: EventComponentInput!) {
  updateEventComponent(id: $id, input: $input) {
    result {
      capacity
      date
      duration
      id
      guestPays
      tiers {
        id
      }
      offering {
        __typename
        ... on PackageOffering {
          ...PackageFields
        }
        ... on RoomOffering {
          ...RoomFields
        }
      }
      status
      main
    }
    errors {
      key
      message
    }
  }
}
    ${PackageFieldsFragmentDoc}
${RoomFieldsFragmentDoc}`;
export type UpdateEventComponentMutationFn = Apollo.MutationFunction<UpdateEventComponentMutation, UpdateEventComponentMutationVariables>;

/**
 * __useUpdateEventComponentMutation__
 *
 * To run a mutation, you first call `useUpdateEventComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventComponentMutation, { data, loading, error }] = useUpdateEventComponentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventComponentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventComponentMutation, UpdateEventComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventComponentMutation, UpdateEventComponentMutationVariables>(UpdateEventComponentDocument, options);
      }
export type UpdateEventComponentMutationHookResult = ReturnType<typeof useUpdateEventComponentMutation>;
export type UpdateEventComponentMutationResult = Apollo.MutationResult<UpdateEventComponentMutation>;
export type UpdateEventComponentMutationOptions = Apollo.BaseMutationOptions<UpdateEventComponentMutation, UpdateEventComponentMutationVariables>;
export const AliasEventComponentDocument = gql`
    mutation aliasEventComponent($id: ID!, $input: EventComponentInput!) {
  updateEventComponent(id: $id, input: $input) {
    result {
      alias
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type AliasEventComponentMutationFn = Apollo.MutationFunction<AliasEventComponentMutation, AliasEventComponentMutationVariables>;

/**
 * __useAliasEventComponentMutation__
 *
 * To run a mutation, you first call `useAliasEventComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAliasEventComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aliasEventComponentMutation, { data, loading, error }] = useAliasEventComponentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAliasEventComponentMutation(baseOptions?: Apollo.MutationHookOptions<AliasEventComponentMutation, AliasEventComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AliasEventComponentMutation, AliasEventComponentMutationVariables>(AliasEventComponentDocument, options);
      }
export type AliasEventComponentMutationHookResult = ReturnType<typeof useAliasEventComponentMutation>;
export type AliasEventComponentMutationResult = Apollo.MutationResult<AliasEventComponentMutation>;
export type AliasEventComponentMutationOptions = Apollo.BaseMutationOptions<AliasEventComponentMutation, AliasEventComponentMutationVariables>;
export const SetMainComponentDocument = gql`
    mutation setMainComponent($id: ID!) {
  setMainComponent(id: $id) {
    result {
      id
      main
    }
    errors {
      key
      message
    }
  }
}
    `;
export type SetMainComponentMutationFn = Apollo.MutationFunction<SetMainComponentMutation, SetMainComponentMutationVariables>;

/**
 * __useSetMainComponentMutation__
 *
 * To run a mutation, you first call `useSetMainComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMainComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMainComponentMutation, { data, loading, error }] = useSetMainComponentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetMainComponentMutation(baseOptions?: Apollo.MutationHookOptions<SetMainComponentMutation, SetMainComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMainComponentMutation, SetMainComponentMutationVariables>(SetMainComponentDocument, options);
      }
export type SetMainComponentMutationHookResult = ReturnType<typeof useSetMainComponentMutation>;
export type SetMainComponentMutationResult = Apollo.MutationResult<SetMainComponentMutation>;
export type SetMainComponentMutationOptions = Apollo.BaseMutationOptions<SetMainComponentMutation, SetMainComponentMutationVariables>;
export const TriggerEventComponentEventDocument = gql`
    mutation triggerEventComponentEvent($id: ID, $event: EventComponentEvent) {
  triggerEventComponentEvent(id: $id, event: $event) {
    result {
      capacity
      date
      duration
      id
      offering {
        __typename
        ... on PackageOffering {
          ...PackageFields
        }
        ... on RoomOffering {
          ...RoomFields
        }
      }
      status
      main
    }
    errors {
      key
      message
    }
  }
}
    ${PackageFieldsFragmentDoc}
${RoomFieldsFragmentDoc}`;
export type TriggerEventComponentEventMutationFn = Apollo.MutationFunction<TriggerEventComponentEventMutation, TriggerEventComponentEventMutationVariables>;

/**
 * __useTriggerEventComponentEventMutation__
 *
 * To run a mutation, you first call `useTriggerEventComponentEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerEventComponentEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerEventComponentEventMutation, { data, loading, error }] = useTriggerEventComponentEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTriggerEventComponentEventMutation(baseOptions?: Apollo.MutationHookOptions<TriggerEventComponentEventMutation, TriggerEventComponentEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerEventComponentEventMutation, TriggerEventComponentEventMutationVariables>(TriggerEventComponentEventDocument, options);
      }
export type TriggerEventComponentEventMutationHookResult = ReturnType<typeof useTriggerEventComponentEventMutation>;
export type TriggerEventComponentEventMutationResult = Apollo.MutationResult<TriggerEventComponentEventMutation>;
export type TriggerEventComponentEventMutationOptions = Apollo.BaseMutationOptions<TriggerEventComponentEventMutation, TriggerEventComponentEventMutationVariables>;
export const AddRoomsToNegotiationDocument = gql`
    mutation addRoomsToNegotiation($eventId: ID!, $providerUrl: String!) {
  addRoomsToNegotiation(eventId: $eventId, providerUrl: $providerUrl) {
    result {
      eventId
      providerUrl
    }
    errors {
      key
      message
    }
  }
}
    `;
export type AddRoomsToNegotiationMutationFn = Apollo.MutationFunction<AddRoomsToNegotiationMutation, AddRoomsToNegotiationMutationVariables>;

/**
 * __useAddRoomsToNegotiationMutation__
 *
 * To run a mutation, you first call `useAddRoomsToNegotiationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoomsToNegotiationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoomsToNegotiationMutation, { data, loading, error }] = useAddRoomsToNegotiationMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      providerUrl: // value for 'providerUrl'
 *   },
 * });
 */
export function useAddRoomsToNegotiationMutation(baseOptions?: Apollo.MutationHookOptions<AddRoomsToNegotiationMutation, AddRoomsToNegotiationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoomsToNegotiationMutation, AddRoomsToNegotiationMutationVariables>(AddRoomsToNegotiationDocument, options);
      }
export type AddRoomsToNegotiationMutationHookResult = ReturnType<typeof useAddRoomsToNegotiationMutation>;
export type AddRoomsToNegotiationMutationResult = Apollo.MutationResult<AddRoomsToNegotiationMutation>;
export type AddRoomsToNegotiationMutationOptions = Apollo.BaseMutationOptions<AddRoomsToNegotiationMutation, AddRoomsToNegotiationMutationVariables>;
export const GetNegotiationMessagesDocument = gql`
    query getNegotiationMessages($eventId: ID!, $providerId: ID!, $cursor: String) {
  me {
    id
    ... on Customer {
      id
      myEventInfo(id: $eventId) {
        ...getNegotiationMessagesCommon
      }
    }
    ... on Concierge {
      id
      myEventInfo(id: $eventId) {
        ...getNegotiationMessagesCommon
      }
    }
    ... on Admin {
      id
      myEventInfo(id: $eventId) {
        ...getNegotiationMessagesCommon
      }
    }
  }
}
    ${GetNegotiationMessagesCommonFragmentDoc}`;

/**
 * __useGetNegotiationMessagesQuery__
 *
 * To run a query within a React component, call `useGetNegotiationMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiationMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiationMessagesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      providerId: // value for 'providerId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNegotiationMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetNegotiationMessagesQuery, GetNegotiationMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNegotiationMessagesQuery, GetNegotiationMessagesQueryVariables>(GetNegotiationMessagesDocument, options);
      }
export function useGetNegotiationMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNegotiationMessagesQuery, GetNegotiationMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNegotiationMessagesQuery, GetNegotiationMessagesQueryVariables>(GetNegotiationMessagesDocument, options);
        }
export type GetNegotiationMessagesQueryHookResult = ReturnType<typeof useGetNegotiationMessagesQuery>;
export type GetNegotiationMessagesLazyQueryHookResult = ReturnType<typeof useGetNegotiationMessagesLazyQuery>;
export type GetNegotiationMessagesQueryResult = Apollo.QueryResult<GetNegotiationMessagesQuery, GetNegotiationMessagesQueryVariables>;
export const GetNegotiationQuotesDocument = gql`
    query getNegotiationQuotes($eventId: ID!, $providerId: ID!) {
  me {
    id
    ... on Customer {
      id
      myEventInfo(id: $eventId) {
        ...getNegotiationQuotesCommon
      }
    }
    ... on Concierge {
      id
      myEventInfo(id: $eventId) {
        ...getNegotiationQuotesCommon
      }
    }
    ... on Admin {
      id
      myEventInfo(id: $eventId) {
        ...getNegotiationQuotesCommon
      }
    }
  }
}
    ${GetNegotiationQuotesCommonFragmentDoc}`;

/**
 * __useGetNegotiationQuotesQuery__
 *
 * To run a query within a React component, call `useGetNegotiationQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiationQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiationQuotesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetNegotiationQuotesQuery(baseOptions: Apollo.QueryHookOptions<GetNegotiationQuotesQuery, GetNegotiationQuotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNegotiationQuotesQuery, GetNegotiationQuotesQueryVariables>(GetNegotiationQuotesDocument, options);
      }
export function useGetNegotiationQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNegotiationQuotesQuery, GetNegotiationQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNegotiationQuotesQuery, GetNegotiationQuotesQueryVariables>(GetNegotiationQuotesDocument, options);
        }
export type GetNegotiationQuotesQueryHookResult = ReturnType<typeof useGetNegotiationQuotesQuery>;
export type GetNegotiationQuotesLazyQueryHookResult = ReturnType<typeof useGetNegotiationQuotesLazyQuery>;
export type GetNegotiationQuotesQueryResult = Apollo.QueryResult<GetNegotiationQuotesQuery, GetNegotiationQuotesQueryVariables>;
export const OnEventComponentsUpdateDocument = gql`
    subscription OnEventComponentsUpdate($eventId: ID!) {
  eventComponentsUpdate(eventId: $eventId) {
    ...PackageAddonsFields
  }
}
    ${PackageAddonsFieldsFragmentDoc}`;

/**
 * __useOnEventComponentsUpdateSubscription__
 *
 * To run a query within a React component, call `useOnEventComponentsUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnEventComponentsUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnEventComponentsUpdateSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useOnEventComponentsUpdateSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnEventComponentsUpdateSubscription, OnEventComponentsUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnEventComponentsUpdateSubscription, OnEventComponentsUpdateSubscriptionVariables>(OnEventComponentsUpdateDocument, options);
      }
export type OnEventComponentsUpdateSubscriptionHookResult = ReturnType<typeof useOnEventComponentsUpdateSubscription>;
export type OnEventComponentsUpdateSubscriptionResult = Apollo.SubscriptionResult<OnEventComponentsUpdateSubscription>;
export const OnEventComponentsAddonsDeleteDocument = gql`
    subscription OnEventComponentsAddonsDelete($eventId: ID!) {
  eventComponentsAddonsDelete(eventId: $eventId) {
    id
    eventComponentId
  }
}
    `;

/**
 * __useOnEventComponentsAddonsDeleteSubscription__
 *
 * To run a query within a React component, call `useOnEventComponentsAddonsDeleteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnEventComponentsAddonsDeleteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnEventComponentsAddonsDeleteSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useOnEventComponentsAddonsDeleteSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnEventComponentsAddonsDeleteSubscription, OnEventComponentsAddonsDeleteSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnEventComponentsAddonsDeleteSubscription, OnEventComponentsAddonsDeleteSubscriptionVariables>(OnEventComponentsAddonsDeleteDocument, options);
      }
export type OnEventComponentsAddonsDeleteSubscriptionHookResult = ReturnType<typeof useOnEventComponentsAddonsDeleteSubscription>;
export type OnEventComponentsAddonsDeleteSubscriptionResult = Apollo.SubscriptionResult<OnEventComponentsAddonsDeleteSubscription>;
export const OnNegotiationMessagesDocument = gql`
    subscription OnNegotiationMessages($eventId: ID!, $providerId: ID!) {
  negotiationMessages(eventId: $eventId, providerId: $providerId) {
    ...MessagesFields
  }
}
    ${MessagesFieldsFragmentDoc}`;

/**
 * __useOnNegotiationMessagesSubscription__
 *
 * To run a query within a React component, call `useOnNegotiationMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnNegotiationMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNegotiationMessagesSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useOnNegotiationMessagesSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnNegotiationMessagesSubscription, OnNegotiationMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnNegotiationMessagesSubscription, OnNegotiationMessagesSubscriptionVariables>(OnNegotiationMessagesDocument, options);
      }
export type OnNegotiationMessagesSubscriptionHookResult = ReturnType<typeof useOnNegotiationMessagesSubscription>;
export type OnNegotiationMessagesSubscriptionResult = Apollo.SubscriptionResult<OnNegotiationMessagesSubscription>;
export const GetNegotiationsPreviewDocument = gql`
    query getNegotiationsPreview($eventId: ID!, $cursor: String) {
  me {
    id
    ... on Customer {
      id
      myEventInfo(id: $eventId) {
        ...getNegotiationsPreviewCommon
      }
    }
    ... on Concierge {
      id
      myEventInfo(id: $eventId) {
        ...getNegotiationsPreviewCommon
      }
    }
    ... on Admin {
      id
      myEventInfo(id: $eventId) {
        ...getNegotiationsPreviewCommon
      }
    }
  }
}
    ${GetNegotiationsPreviewCommonFragmentDoc}`;

/**
 * __useGetNegotiationsPreviewQuery__
 *
 * To run a query within a React component, call `useGetNegotiationsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiationsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiationsPreviewQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNegotiationsPreviewQuery(baseOptions: Apollo.QueryHookOptions<GetNegotiationsPreviewQuery, GetNegotiationsPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNegotiationsPreviewQuery, GetNegotiationsPreviewQueryVariables>(GetNegotiationsPreviewDocument, options);
      }
export function useGetNegotiationsPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNegotiationsPreviewQuery, GetNegotiationsPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNegotiationsPreviewQuery, GetNegotiationsPreviewQueryVariables>(GetNegotiationsPreviewDocument, options);
        }
export type GetNegotiationsPreviewQueryHookResult = ReturnType<typeof useGetNegotiationsPreviewQuery>;
export type GetNegotiationsPreviewLazyQueryHookResult = ReturnType<typeof useGetNegotiationsPreviewLazyQuery>;
export type GetNegotiationsPreviewQueryResult = Apollo.QueryResult<GetNegotiationsPreviewQuery, GetNegotiationsPreviewQueryVariables>;
export const CreateNegotiationMessageDocument = gql`
    mutation createNegotiationMessage($eventId: ID!, $input: NegotiationMessageInput!, $providerId: ID!) {
  createNegotiationMessage(
    eventId: $eventId
    input: $input
    providerId: $providerId
  ) {
    result {
      ...MessagesFields
    }
    errors {
      key
      message
    }
  }
}
    ${MessagesFieldsFragmentDoc}`;
export type CreateNegotiationMessageMutationFn = Apollo.MutationFunction<CreateNegotiationMessageMutation, CreateNegotiationMessageMutationVariables>;

/**
 * __useCreateNegotiationMessageMutation__
 *
 * To run a mutation, you first call `useCreateNegotiationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNegotiationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNegotiationMessageMutation, { data, loading, error }] = useCreateNegotiationMessageMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      input: // value for 'input'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useCreateNegotiationMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateNegotiationMessageMutation, CreateNegotiationMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNegotiationMessageMutation, CreateNegotiationMessageMutationVariables>(CreateNegotiationMessageDocument, options);
      }
export type CreateNegotiationMessageMutationHookResult = ReturnType<typeof useCreateNegotiationMessageMutation>;
export type CreateNegotiationMessageMutationResult = Apollo.MutationResult<CreateNegotiationMessageMutation>;
export type CreateNegotiationMessageMutationOptions = Apollo.BaseMutationOptions<CreateNegotiationMessageMutation, CreateNegotiationMessageMutationVariables>;
export const CreateNegotiationItemDocument = gql`
    mutation createNegotiationItem($eventComponentId: ID!, $input: NegotiationItemInput!) {
  createNegotiationItem(eventComponentId: $eventComponentId, input: $input) {
    result {
      ...negotiationItemFields
    }
    errors {
      key
      message
    }
  }
}
    ${NegotiationItemFieldsFragmentDoc}`;
export type CreateNegotiationItemMutationFn = Apollo.MutationFunction<CreateNegotiationItemMutation, CreateNegotiationItemMutationVariables>;

/**
 * __useCreateNegotiationItemMutation__
 *
 * To run a mutation, you first call `useCreateNegotiationItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNegotiationItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNegotiationItemMutation, { data, loading, error }] = useCreateNegotiationItemMutation({
 *   variables: {
 *      eventComponentId: // value for 'eventComponentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNegotiationItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateNegotiationItemMutation, CreateNegotiationItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNegotiationItemMutation, CreateNegotiationItemMutationVariables>(CreateNegotiationItemDocument, options);
      }
export type CreateNegotiationItemMutationHookResult = ReturnType<typeof useCreateNegotiationItemMutation>;
export type CreateNegotiationItemMutationResult = Apollo.MutationResult<CreateNegotiationItemMutation>;
export type CreateNegotiationItemMutationOptions = Apollo.BaseMutationOptions<CreateNegotiationItemMutation, CreateNegotiationItemMutationVariables>;
export const DeleteNegotiationItemDocument = gql`
    mutation deleteNegotiationItem($negotiationItemId: ID!) {
  deleteNegotiationItem(negotiationItemId: $negotiationItemId) {
    result {
      ...negotiationItemFields
    }
    errors {
      key
      message
    }
  }
}
    ${NegotiationItemFieldsFragmentDoc}`;
export type DeleteNegotiationItemMutationFn = Apollo.MutationFunction<DeleteNegotiationItemMutation, DeleteNegotiationItemMutationVariables>;

/**
 * __useDeleteNegotiationItemMutation__
 *
 * To run a mutation, you first call `useDeleteNegotiationItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNegotiationItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNegotiationItemMutation, { data, loading, error }] = useDeleteNegotiationItemMutation({
 *   variables: {
 *      negotiationItemId: // value for 'negotiationItemId'
 *   },
 * });
 */
export function useDeleteNegotiationItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNegotiationItemMutation, DeleteNegotiationItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNegotiationItemMutation, DeleteNegotiationItemMutationVariables>(DeleteNegotiationItemDocument, options);
      }
export type DeleteNegotiationItemMutationHookResult = ReturnType<typeof useDeleteNegotiationItemMutation>;
export type DeleteNegotiationItemMutationResult = Apollo.MutationResult<DeleteNegotiationItemMutation>;
export type DeleteNegotiationItemMutationOptions = Apollo.BaseMutationOptions<DeleteNegotiationItemMutation, DeleteNegotiationItemMutationVariables>;
export const InitiateHostPaymentDocument = gql`
    mutation initiateHostPayment($amount: Decimal!, $eventId: ID!, $providerId: ID!) {
  initiateHostPayment(eventId: $eventId, amount: $amount, providerId: $providerId) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type InitiateHostPaymentMutationFn = Apollo.MutationFunction<InitiateHostPaymentMutation, InitiateHostPaymentMutationVariables>;

/**
 * __useInitiateHostPaymentMutation__
 *
 * To run a mutation, you first call `useInitiateHostPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateHostPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateHostPaymentMutation, { data, loading, error }] = useInitiateHostPaymentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      eventId: // value for 'eventId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useInitiateHostPaymentMutation(baseOptions?: Apollo.MutationHookOptions<InitiateHostPaymentMutation, InitiateHostPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateHostPaymentMutation, InitiateHostPaymentMutationVariables>(InitiateHostPaymentDocument, options);
      }
export type InitiateHostPaymentMutationHookResult = ReturnType<typeof useInitiateHostPaymentMutation>;
export type InitiateHostPaymentMutationResult = Apollo.MutationResult<InitiateHostPaymentMutation>;
export type InitiateHostPaymentMutationOptions = Apollo.BaseMutationOptions<InitiateHostPaymentMutation, InitiateHostPaymentMutationVariables>;
export const OnBalanceUpdatesDocument = gql`
    subscription OnBalanceUpdates($eventId: ID!, $providerId: ID!) {
  balanceUpdates(eventId: $eventId, providerId: $providerId) {
    balance
  }
}
    `;

/**
 * __useOnBalanceUpdatesSubscription__
 *
 * To run a query within a React component, call `useOnBalanceUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnBalanceUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnBalanceUpdatesSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useOnBalanceUpdatesSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnBalanceUpdatesSubscription, OnBalanceUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnBalanceUpdatesSubscription, OnBalanceUpdatesSubscriptionVariables>(OnBalanceUpdatesDocument, options);
      }
export type OnBalanceUpdatesSubscriptionHookResult = ReturnType<typeof useOnBalanceUpdatesSubscription>;
export type OnBalanceUpdatesSubscriptionResult = Apollo.SubscriptionResult<OnBalanceUpdatesSubscription>;
export const FeedComponentGroupsDocument = gql`
    query feedComponentGroups($eventId: ID!, $cursor: String) {
  me {
    id
    ... on Customer {
      myBookings(eventId: $eventId) {
        id
        status
        eventComponent {
          id
          customTexts
          offering {
            __typename
          }
        }
      }
    }
  }
  feedComponentGroups(eventIds: [$eventId], cursor: {cursor: $cursor, limit: 10}) {
    entries {
      event {
        id
        host {
          avatarUrl
          firstName
        }
      }
      components {
        capacity
        date
        duration
        id
        status
        alias
        main
        bookingCount
        booking {
          id
          status
        }
        packageCustomization {
          ...PackageAddonsFields
        }
        offering {
          __typename
          ... on PackageOffering {
            name
            intro
            description
            mainImage {
              ...ImageFields
            }
            images {
              ...ImageFields
            }
            provider {
              mainImage {
                ...ImageFields
              }
              location
              id
              address
              url
              name
            }
          }
          ... on RoomOffering {
            name
            description
            mainImage {
              ...ImageFields
            }
            images {
              ...ImageFields
            }
            provider {
              mainImage {
                ...ImageFields
              }
              location
              id
              address
              url
              name
            }
            price
            room {
              beds
              occupancy
              size
            }
          }
        }
      }
    }
    cursor
  }
}
    ${PackageAddonsFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;

/**
 * __useFeedComponentGroupsQuery__
 *
 * To run a query within a React component, call `useFeedComponentGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedComponentGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedComponentGroupsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useFeedComponentGroupsQuery(baseOptions: Apollo.QueryHookOptions<FeedComponentGroupsQuery, FeedComponentGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedComponentGroupsQuery, FeedComponentGroupsQueryVariables>(FeedComponentGroupsDocument, options);
      }
export function useFeedComponentGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedComponentGroupsQuery, FeedComponentGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedComponentGroupsQuery, FeedComponentGroupsQueryVariables>(FeedComponentGroupsDocument, options);
        }
export type FeedComponentGroupsQueryHookResult = ReturnType<typeof useFeedComponentGroupsQuery>;
export type FeedComponentGroupsLazyQueryHookResult = ReturnType<typeof useFeedComponentGroupsLazyQuery>;
export type FeedComponentGroupsQueryResult = Apollo.QueryResult<FeedComponentGroupsQuery, FeedComponentGroupsQueryVariables>;
export const AddTierToEventComponentDocument = gql`
    mutation addTierToEventComponent($id: ID!, $tierId: ID!) {
  addTierToEventComponent(id: $id, tierId: $tierId) {
    result {
      id
      tiers {
        id
        name
      }
    }
    errors {
      key
      message
    }
  }
}
    `;
export type AddTierToEventComponentMutationFn = Apollo.MutationFunction<AddTierToEventComponentMutation, AddTierToEventComponentMutationVariables>;

/**
 * __useAddTierToEventComponentMutation__
 *
 * To run a mutation, you first call `useAddTierToEventComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTierToEventComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTierToEventComponentMutation, { data, loading, error }] = useAddTierToEventComponentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tierId: // value for 'tierId'
 *   },
 * });
 */
export function useAddTierToEventComponentMutation(baseOptions?: Apollo.MutationHookOptions<AddTierToEventComponentMutation, AddTierToEventComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTierToEventComponentMutation, AddTierToEventComponentMutationVariables>(AddTierToEventComponentDocument, options);
      }
export type AddTierToEventComponentMutationHookResult = ReturnType<typeof useAddTierToEventComponentMutation>;
export type AddTierToEventComponentMutationResult = Apollo.MutationResult<AddTierToEventComponentMutation>;
export type AddTierToEventComponentMutationOptions = Apollo.BaseMutationOptions<AddTierToEventComponentMutation, AddTierToEventComponentMutationVariables>;
export const RemoveTierFromEventComponentDocument = gql`
    mutation removeTierFromEventComponent($id: ID!, $tierId: ID!) {
  removeTierFromEventComponent(id: $id, tierId: $tierId) {
    result {
      id
      tiers {
        id
        name
      }
    }
    errors {
      key
      message
    }
  }
}
    `;
export type RemoveTierFromEventComponentMutationFn = Apollo.MutationFunction<RemoveTierFromEventComponentMutation, RemoveTierFromEventComponentMutationVariables>;

/**
 * __useRemoveTierFromEventComponentMutation__
 *
 * To run a mutation, you first call `useRemoveTierFromEventComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTierFromEventComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTierFromEventComponentMutation, { data, loading, error }] = useRemoveTierFromEventComponentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tierId: // value for 'tierId'
 *   },
 * });
 */
export function useRemoveTierFromEventComponentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTierFromEventComponentMutation, RemoveTierFromEventComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTierFromEventComponentMutation, RemoveTierFromEventComponentMutationVariables>(RemoveTierFromEventComponentDocument, options);
      }
export type RemoveTierFromEventComponentMutationHookResult = ReturnType<typeof useRemoveTierFromEventComponentMutation>;
export type RemoveTierFromEventComponentMutationResult = Apollo.MutationResult<RemoveTierFromEventComponentMutation>;
export type RemoveTierFromEventComponentMutationOptions = Apollo.BaseMutationOptions<RemoveTierFromEventComponentMutation, RemoveTierFromEventComponentMutationVariables>;
export const MarkMessagesAsSeenDocument = gql`
    mutation markMessagesAsSeen($eventId: ID!, $providerId: ID!) {
  markMessagesAsSeen(eventId: $eventId, providerId: $providerId) {
    result {
      marked
    }
    errors {
      key
      message
    }
  }
}
    `;
export type MarkMessagesAsSeenMutationFn = Apollo.MutationFunction<MarkMessagesAsSeenMutation, MarkMessagesAsSeenMutationVariables>;

/**
 * __useMarkMessagesAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkMessagesAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessagesAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessagesAsSeenMutation, { data, loading, error }] = useMarkMessagesAsSeenMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useMarkMessagesAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkMessagesAsSeenMutation, MarkMessagesAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkMessagesAsSeenMutation, MarkMessagesAsSeenMutationVariables>(MarkMessagesAsSeenDocument, options);
      }
export type MarkMessagesAsSeenMutationHookResult = ReturnType<typeof useMarkMessagesAsSeenMutation>;
export type MarkMessagesAsSeenMutationResult = Apollo.MutationResult<MarkMessagesAsSeenMutation>;
export type MarkMessagesAsSeenMutationOptions = Apollo.BaseMutationOptions<MarkMessagesAsSeenMutation, MarkMessagesAsSeenMutationVariables>;
export const GetGuestListByTierIdDocument = gql`
    query getGuestListByTierId($tierId: ID, $eventId: ID) {
  guestList(tierId: $tierId, eventId: $eventId) {
    id
    firstName
    lastName
    avatarUrl
  }
}
    `;

/**
 * __useGetGuestListByTierIdQuery__
 *
 * To run a query within a React component, call `useGetGuestListByTierIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuestListByTierIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuestListByTierIdQuery({
 *   variables: {
 *      tierId: // value for 'tierId'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetGuestListByTierIdQuery(baseOptions?: Apollo.QueryHookOptions<GetGuestListByTierIdQuery, GetGuestListByTierIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuestListByTierIdQuery, GetGuestListByTierIdQueryVariables>(GetGuestListByTierIdDocument, options);
      }
export function useGetGuestListByTierIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuestListByTierIdQuery, GetGuestListByTierIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuestListByTierIdQuery, GetGuestListByTierIdQueryVariables>(GetGuestListByTierIdDocument, options);
        }
export type GetGuestListByTierIdQueryHookResult = ReturnType<typeof useGetGuestListByTierIdQuery>;
export type GetGuestListByTierIdLazyQueryHookResult = ReturnType<typeof useGetGuestListByTierIdLazyQuery>;
export type GetGuestListByTierIdQueryResult = Apollo.QueryResult<GetGuestListByTierIdQuery, GetGuestListByTierIdQueryVariables>;
export const GetGuestlistWithBookingsDocument = gql`
    query getGuestlistWithBookings($id: ID!, $tierIds: [ID!]) {
  me {
    id
    ... on Customer {
      myEventInfo(id: $id) {
        ...EventFieldsCommon
      }
    }
    ... on Concierge {
      myEventInfo(id: $id) {
        ...EventFieldsCommon
      }
    }
    ... on Admin {
      myEventInfo(id: $id) {
        ...EventFieldsCommon
      }
    }
  }
  availablePackages(eventId: $id) {
    id
    alias
    offering {
      __typename
      ... on PackageOffering {
        name
      }
    }
  }
}
    ${EventFieldsCommonFragmentDoc}`;

/**
 * __useGetGuestlistWithBookingsQuery__
 *
 * To run a query within a React component, call `useGetGuestlistWithBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuestlistWithBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuestlistWithBookingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tierIds: // value for 'tierIds'
 *   },
 * });
 */
export function useGetGuestlistWithBookingsQuery(baseOptions: Apollo.QueryHookOptions<GetGuestlistWithBookingsQuery, GetGuestlistWithBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuestlistWithBookingsQuery, GetGuestlistWithBookingsQueryVariables>(GetGuestlistWithBookingsDocument, options);
      }
export function useGetGuestlistWithBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuestlistWithBookingsQuery, GetGuestlistWithBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuestlistWithBookingsQuery, GetGuestlistWithBookingsQueryVariables>(GetGuestlistWithBookingsDocument, options);
        }
export type GetGuestlistWithBookingsQueryHookResult = ReturnType<typeof useGetGuestlistWithBookingsQuery>;
export type GetGuestlistWithBookingsLazyQueryHookResult = ReturnType<typeof useGetGuestlistWithBookingsLazyQuery>;
export type GetGuestlistWithBookingsQueryResult = Apollo.QueryResult<GetGuestlistWithBookingsQuery, GetGuestlistWithBookingsQueryVariables>;
export const SavePackageCustomizationDocument = gql`
    mutation savePackageCustomization($input: PackageAddonsInput!) {
  savePackageCustomization(input: $input) {
    result {
      id
      eventComponentId
      location
      offeringAddOn {
        id
      }
      offeringAddOnOption {
        id
      }
      time
    }
    errors {
      key
      message
    }
  }
}
    `;
export type SavePackageCustomizationMutationFn = Apollo.MutationFunction<SavePackageCustomizationMutation, SavePackageCustomizationMutationVariables>;

/**
 * __useSavePackageCustomizationMutation__
 *
 * To run a mutation, you first call `useSavePackageCustomizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePackageCustomizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePackageCustomizationMutation, { data, loading, error }] = useSavePackageCustomizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePackageCustomizationMutation(baseOptions?: Apollo.MutationHookOptions<SavePackageCustomizationMutation, SavePackageCustomizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePackageCustomizationMutation, SavePackageCustomizationMutationVariables>(SavePackageCustomizationDocument, options);
      }
export type SavePackageCustomizationMutationHookResult = ReturnType<typeof useSavePackageCustomizationMutation>;
export type SavePackageCustomizationMutationResult = Apollo.MutationResult<SavePackageCustomizationMutation>;
export type SavePackageCustomizationMutationOptions = Apollo.BaseMutationOptions<SavePackageCustomizationMutation, SavePackageCustomizationMutationVariables>;
export const EditPackageCustomizationDocument = gql`
    mutation editPackageCustomization($id: ID!, $input: PackageAddonsInput!) {
  editPackageCustomization(id: $id, input: $input) {
    result {
      id
      eventComponentId
      location
      offeringAddOn {
        id
      }
      offeringAddOnOption {
        id
      }
      time
    }
    errors {
      key
      message
    }
  }
}
    `;
export type EditPackageCustomizationMutationFn = Apollo.MutationFunction<EditPackageCustomizationMutation, EditPackageCustomizationMutationVariables>;

/**
 * __useEditPackageCustomizationMutation__
 *
 * To run a mutation, you first call `useEditPackageCustomizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPackageCustomizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPackageCustomizationMutation, { data, loading, error }] = useEditPackageCustomizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPackageCustomizationMutation(baseOptions?: Apollo.MutationHookOptions<EditPackageCustomizationMutation, EditPackageCustomizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPackageCustomizationMutation, EditPackageCustomizationMutationVariables>(EditPackageCustomizationDocument, options);
      }
export type EditPackageCustomizationMutationHookResult = ReturnType<typeof useEditPackageCustomizationMutation>;
export type EditPackageCustomizationMutationResult = Apollo.MutationResult<EditPackageCustomizationMutation>;
export type EditPackageCustomizationMutationOptions = Apollo.BaseMutationOptions<EditPackageCustomizationMutation, EditPackageCustomizationMutationVariables>;
export const DeletePackageCustomizationDocument = gql`
    mutation deletePackageCustomization($id: ID!) {
  deletePackageCustomization(id: $id) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type DeletePackageCustomizationMutationFn = Apollo.MutationFunction<DeletePackageCustomizationMutation, DeletePackageCustomizationMutationVariables>;

/**
 * __useDeletePackageCustomizationMutation__
 *
 * To run a mutation, you first call `useDeletePackageCustomizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePackageCustomizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePackageCustomizationMutation, { data, loading, error }] = useDeletePackageCustomizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePackageCustomizationMutation(baseOptions?: Apollo.MutationHookOptions<DeletePackageCustomizationMutation, DeletePackageCustomizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePackageCustomizationMutation, DeletePackageCustomizationMutationVariables>(DeletePackageCustomizationDocument, options);
      }
export type DeletePackageCustomizationMutationHookResult = ReturnType<typeof useDeletePackageCustomizationMutation>;
export type DeletePackageCustomizationMutationResult = Apollo.MutationResult<DeletePackageCustomizationMutation>;
export type DeletePackageCustomizationMutationOptions = Apollo.BaseMutationOptions<DeletePackageCustomizationMutation, DeletePackageCustomizationMutationVariables>;
export const GetAvailablePackagesDocument = gql`
    query getAvailablePackages($eventId: ID!, $guestId: ID) {
  availablePackages(eventId: $eventId, guestId: $guestId) {
    id
    main
    date
    alias
    eventId
    capacity
    offering {
      __typename
      ... on PackageOffering {
        name
        mainImage {
          ...ImageFields
        }
        price
        provider {
          name
        }
        package {
          basePrice
        }
      }
    }
    customTexts
    bookingCount
    guestPays
    tiers {
      id
      name
    }
    items {
      price
      deleted
    }
    packageCustomization {
      ...PackageAddonsFields
    }
  }
}
    ${ImageFieldsFragmentDoc}
${PackageAddonsFieldsFragmentDoc}`;

/**
 * __useGetAvailablePackagesQuery__
 *
 * To run a query within a React component, call `useGetAvailablePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailablePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailablePackagesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      guestId: // value for 'guestId'
 *   },
 * });
 */
export function useGetAvailablePackagesQuery(baseOptions: Apollo.QueryHookOptions<GetAvailablePackagesQuery, GetAvailablePackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailablePackagesQuery, GetAvailablePackagesQueryVariables>(GetAvailablePackagesDocument, options);
      }
export function useGetAvailablePackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailablePackagesQuery, GetAvailablePackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailablePackagesQuery, GetAvailablePackagesQueryVariables>(GetAvailablePackagesDocument, options);
        }
export type GetAvailablePackagesQueryHookResult = ReturnType<typeof useGetAvailablePackagesQuery>;
export type GetAvailablePackagesLazyQueryHookResult = ReturnType<typeof useGetAvailablePackagesLazyQuery>;
export type GetAvailablePackagesQueryResult = Apollo.QueryResult<GetAvailablePackagesQuery, GetAvailablePackagesQueryVariables>;
export const CheckRoleDocument = gql`
    query checkRole($eventId: ID!) {
  checkRole(eventId: $eventId) {
    id
    role {
      id
      name
    }
  }
}
    `;

/**
 * __useCheckRoleQuery__
 *
 * To run a query within a React component, call `useCheckRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRoleQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useCheckRoleQuery(baseOptions: Apollo.QueryHookOptions<CheckRoleQuery, CheckRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckRoleQuery, CheckRoleQueryVariables>(CheckRoleDocument, options);
      }
export function useCheckRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckRoleQuery, CheckRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckRoleQuery, CheckRoleQueryVariables>(CheckRoleDocument, options);
        }
export type CheckRoleQueryHookResult = ReturnType<typeof useCheckRoleQuery>;
export type CheckRoleLazyQueryHookResult = ReturnType<typeof useCheckRoleLazyQuery>;
export type CheckRoleQueryResult = Apollo.QueryResult<CheckRoleQuery, CheckRoleQueryVariables>;
export const CreateGuestDocument = gql`
    mutation createGuest($eventId: ID!, $input: GuestInput!, $tierIds: [ID!]) {
  createGuest(eventId: $eventId, input: $input, tierIds: $tierIds) {
    result {
      ...GuestFields
    }
    errors {
      key
      message
    }
  }
}
    ${GuestFieldsFragmentDoc}`;
export type CreateGuestMutationFn = Apollo.MutationFunction<CreateGuestMutation, CreateGuestMutationVariables>;

/**
 * __useCreateGuestMutation__
 *
 * To run a mutation, you first call `useCreateGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuestMutation, { data, loading, error }] = useCreateGuestMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      input: // value for 'input'
 *      tierIds: // value for 'tierIds'
 *   },
 * });
 */
export function useCreateGuestMutation(baseOptions?: Apollo.MutationHookOptions<CreateGuestMutation, CreateGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGuestMutation, CreateGuestMutationVariables>(CreateGuestDocument, options);
      }
export type CreateGuestMutationHookResult = ReturnType<typeof useCreateGuestMutation>;
export type CreateGuestMutationResult = Apollo.MutationResult<CreateGuestMutation>;
export type CreateGuestMutationOptions = Apollo.BaseMutationOptions<CreateGuestMutation, CreateGuestMutationVariables>;
export const UpdateGuestDocument = gql`
    mutation updateGuest($id: ID!, $input: GuestInput!, $tierIds: [ID!]) {
  updateGuest(id: $id, input: $input, tierIds: $tierIds) {
    result {
      ...GuestFields
    }
    errors {
      key
      message
    }
  }
}
    ${GuestFieldsFragmentDoc}`;
export type UpdateGuestMutationFn = Apollo.MutationFunction<UpdateGuestMutation, UpdateGuestMutationVariables>;

/**
 * __useUpdateGuestMutation__
 *
 * To run a mutation, you first call `useUpdateGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGuestMutation, { data, loading, error }] = useUpdateGuestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      tierIds: // value for 'tierIds'
 *   },
 * });
 */
export function useUpdateGuestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGuestMutation, UpdateGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGuestMutation, UpdateGuestMutationVariables>(UpdateGuestDocument, options);
      }
export type UpdateGuestMutationHookResult = ReturnType<typeof useUpdateGuestMutation>;
export type UpdateGuestMutationResult = Apollo.MutationResult<UpdateGuestMutation>;
export type UpdateGuestMutationOptions = Apollo.BaseMutationOptions<UpdateGuestMutation, UpdateGuestMutationVariables>;
export const DeleteGuestDocument = gql`
    mutation deleteGuest($id: ID!) {
  deleteGuest(id: $id) {
    result {
      id
      invitedBy {
        id
      }
      event {
        id
      }
    }
    errors {
      key
      message
    }
  }
}
    `;
export type DeleteGuestMutationFn = Apollo.MutationFunction<DeleteGuestMutation, DeleteGuestMutationVariables>;

/**
 * __useDeleteGuestMutation__
 *
 * To run a mutation, you first call `useDeleteGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGuestMutation, { data, loading, error }] = useDeleteGuestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGuestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGuestMutation, DeleteGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGuestMutation, DeleteGuestMutationVariables>(DeleteGuestDocument, options);
      }
export type DeleteGuestMutationHookResult = ReturnType<typeof useDeleteGuestMutation>;
export type DeleteGuestMutationResult = Apollo.MutationResult<DeleteGuestMutation>;
export type DeleteGuestMutationOptions = Apollo.BaseMutationOptions<DeleteGuestMutation, DeleteGuestMutationVariables>;
export const CreateMarketingFormDocument = gql`
    mutation createMarketingForm($input: MarketingFormInput!) {
  createMarketingForm(input: $input) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type CreateMarketingFormMutationFn = Apollo.MutationFunction<CreateMarketingFormMutation, CreateMarketingFormMutationVariables>;

/**
 * __useCreateMarketingFormMutation__
 *
 * To run a mutation, you first call `useCreateMarketingFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketingFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketingFormMutation, { data, loading, error }] = useCreateMarketingFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketingFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateMarketingFormMutation, CreateMarketingFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMarketingFormMutation, CreateMarketingFormMutationVariables>(CreateMarketingFormDocument, options);
      }
export type CreateMarketingFormMutationHookResult = ReturnType<typeof useCreateMarketingFormMutation>;
export type CreateMarketingFormMutationResult = Apollo.MutationResult<CreateMarketingFormMutation>;
export type CreateMarketingFormMutationOptions = Apollo.BaseMutationOptions<CreateMarketingFormMutation, CreateMarketingFormMutationVariables>;
export const OnNotificationsPushDocument = gql`
    subscription OnNotificationsPush {
  notificationsPush {
    ...NotificationFields
  }
}
    ${NotificationFieldsFragmentDoc}`;

/**
 * __useOnNotificationsPushSubscription__
 *
 * To run a query within a React component, call `useOnNotificationsPushSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnNotificationsPushSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNotificationsPushSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnNotificationsPushSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnNotificationsPushSubscription, OnNotificationsPushSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnNotificationsPushSubscription, OnNotificationsPushSubscriptionVariables>(OnNotificationsPushDocument, options);
      }
export type OnNotificationsPushSubscriptionHookResult = ReturnType<typeof useOnNotificationsPushSubscription>;
export type OnNotificationsPushSubscriptionResult = Apollo.SubscriptionResult<OnNotificationsPushSubscription>;
export const GetNotificationsDocument = gql`
    query getNotifications($cursor: String, $limit: Int = 5) {
  notifications(cursor: {cursor: $cursor, limit: $limit}) {
    cursor
    entries {
      ...NotificationFields
    }
  }
}
    ${NotificationFieldsFragmentDoc}`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const NotificationSeenDocument = gql`
    mutation notificationSeen($id: ID!) {
  notificationSeen(id: $id) {
    errors {
      key
      message
    }
    result {
      ...NotificationFields
    }
  }
}
    ${NotificationFieldsFragmentDoc}`;
export type NotificationSeenMutationFn = Apollo.MutationFunction<NotificationSeenMutation, NotificationSeenMutationVariables>;

/**
 * __useNotificationSeenMutation__
 *
 * To run a mutation, you first call `useNotificationSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSeenMutation, { data, loading, error }] = useNotificationSeenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationSeenMutation(baseOptions?: Apollo.MutationHookOptions<NotificationSeenMutation, NotificationSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationSeenMutation, NotificationSeenMutationVariables>(NotificationSeenDocument, options);
      }
export type NotificationSeenMutationHookResult = ReturnType<typeof useNotificationSeenMutation>;
export type NotificationSeenMutationResult = Apollo.MutationResult<NotificationSeenMutation>;
export type NotificationSeenMutationOptions = Apollo.BaseMutationOptions<NotificationSeenMutation, NotificationSeenMutationVariables>;
export const NotificationDismissedDocument = gql`
    mutation notificationDismissed($id: ID!) {
  notificationDismissed(id: $id) {
    errors {
      key
      message
    }
    result {
      ...NotificationFields
    }
  }
}
    ${NotificationFieldsFragmentDoc}`;
export type NotificationDismissedMutationFn = Apollo.MutationFunction<NotificationDismissedMutation, NotificationDismissedMutationVariables>;

/**
 * __useNotificationDismissedMutation__
 *
 * To run a mutation, you first call `useNotificationDismissedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationDismissedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationDismissedMutation, { data, loading, error }] = useNotificationDismissedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationDismissedMutation(baseOptions?: Apollo.MutationHookOptions<NotificationDismissedMutation, NotificationDismissedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationDismissedMutation, NotificationDismissedMutationVariables>(NotificationDismissedDocument, options);
      }
export type NotificationDismissedMutationHookResult = ReturnType<typeof useNotificationDismissedMutation>;
export type NotificationDismissedMutationResult = Apollo.MutationResult<NotificationDismissedMutation>;
export type NotificationDismissedMutationOptions = Apollo.BaseMutationOptions<NotificationDismissedMutation, NotificationDismissedMutationVariables>;
export const TrackViewDocument = gql`
    mutation trackView($guestId: ID, $eventId: ID, $source: String!, $destination: String!) {
  trackView(
    guestId: $guestId
    eventId: $eventId
    source: $source
    destination: $destination
  ) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type TrackViewMutationFn = Apollo.MutationFunction<TrackViewMutation, TrackViewMutationVariables>;

/**
 * __useTrackViewMutation__
 *
 * To run a mutation, you first call `useTrackViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackViewMutation, { data, loading, error }] = useTrackViewMutation({
 *   variables: {
 *      guestId: // value for 'guestId'
 *      eventId: // value for 'eventId'
 *      source: // value for 'source'
 *      destination: // value for 'destination'
 *   },
 * });
 */
export function useTrackViewMutation(baseOptions?: Apollo.MutationHookOptions<TrackViewMutation, TrackViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackViewMutation, TrackViewMutationVariables>(TrackViewDocument, options);
      }
export type TrackViewMutationHookResult = ReturnType<typeof useTrackViewMutation>;
export type TrackViewMutationResult = Apollo.MutationResult<TrackViewMutation>;
export type TrackViewMutationOptions = Apollo.BaseMutationOptions<TrackViewMutation, TrackViewMutationVariables>;
export const GetLocationsDocument = gql`
    query getLocations($limit: Int = 9, $cursor: String, $where: Location, $what: String, $maxPackagePrice: Decimal, $maxRoomPrice: Decimal, $groupSize: Int = 1) {
  marketplace(
    where: $where
    what: $what
    maxPackagePrice: $maxPackagePrice
    maxRoomPrice: $maxRoomPrice
    groupSize: $groupSize
    cursor: {cursor: $cursor, limit: $limit}
  ) {
    entries {
      id
      url
      name
      location
      address
      room {
        price
        sleeps
      }
      package {
        capacity
        price
        baseNumber
        minimumGuests
      }
      mainImage {
        ...ImageFields
      }
      coord
    }
    cursor
  }
}
    ${ImageFieldsFragmentDoc}`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *      what: // value for 'what'
 *      maxPackagePrice: // value for 'maxPackagePrice'
 *      maxRoomPrice: // value for 'maxRoomPrice'
 *      groupSize: // value for 'groupSize'
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetLocationDocument = gql`
    query getLocation($url: String!) {
  provider(url: $url) {
    address
    airportCode
    bookAllRequired
    description
    id
    url
    name
    location
    coord
    metadata
    cancellationPolicy
    package {
      capacity
      price
      minimumGuests
    }
    room {
      price
      sleeps
    }
    packages {
      ...PackageFields
    }
    rooms {
      ...RoomFields
    }
    timeToAirport
    images {
      ...ImageFields
    }
  }
}
    ${PackageFieldsFragmentDoc}
${RoomFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const GetLocationsByIdsDocument = gql`
    query getLocationsByIds($ids: [ID!]!) {
  providers(ids: $ids) {
    address
    airportCode
    bookAllRequired
    description
    id
    url
    name
    location
    coord
    metadata
    cancellationPolicy
    package {
      capacity
      price
      minimumGuests
    }
    room {
      price
      sleeps
    }
    packages {
      ...PackageFields
    }
    rooms {
      ...RoomFields
    }
    timeToAirport
    images {
      ...ImageFields
    }
    mainImage {
      ...ImageFields
    }
  }
}
    ${PackageFieldsFragmentDoc}
${RoomFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;

/**
 * __useGetLocationsByIdsQuery__
 *
 * To run a query within a React component, call `useGetLocationsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetLocationsByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetLocationsByIdsQuery, GetLocationsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsByIdsQuery, GetLocationsByIdsQueryVariables>(GetLocationsByIdsDocument, options);
      }
export function useGetLocationsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsByIdsQuery, GetLocationsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsByIdsQuery, GetLocationsByIdsQueryVariables>(GetLocationsByIdsDocument, options);
        }
export type GetLocationsByIdsQueryHookResult = ReturnType<typeof useGetLocationsByIdsQuery>;
export type GetLocationsByIdsLazyQueryHookResult = ReturnType<typeof useGetLocationsByIdsLazyQuery>;
export type GetLocationsByIdsQueryResult = Apollo.QueryResult<GetLocationsByIdsQuery, GetLocationsByIdsQueryVariables>;
export const GetLocationsFeedDocument = gql`
    query getLocationsFeed($limit: Int = 10, $cursor: String, $where: Location, $what: String = "Kiss&Tell Founder's Collection", $maxPackagePrice: Decimal, $maxRoomPrice: Decimal, $groupSize: Int = 0) {
  marketplace(
    where: $where
    what: $what
    maxPackagePrice: $maxPackagePrice
    maxRoomPrice: $maxRoomPrice
    groupSize: $groupSize
    cursor: {cursor: $cursor, limit: $limit}
  ) {
    entries {
      id
      name
      description
      location
      address
      airportCode
      timeToAirport
      url
      room {
        sleeps
      }
      package {
        capacity
      }
      packages {
        mainImage {
          ...ImageFields
        }
      }
      mainImage {
        ...ImageFields
      }
      images {
        ...ImageFields
      }
    }
    cursor
  }
}
    ${ImageFieldsFragmentDoc}`;

/**
 * __useGetLocationsFeedQuery__
 *
 * To run a query within a React component, call `useGetLocationsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsFeedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      where: // value for 'where'
 *      what: // value for 'what'
 *      maxPackagePrice: // value for 'maxPackagePrice'
 *      maxRoomPrice: // value for 'maxRoomPrice'
 *      groupSize: // value for 'groupSize'
 *   },
 * });
 */
export function useGetLocationsFeedQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsFeedQuery, GetLocationsFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsFeedQuery, GetLocationsFeedQueryVariables>(GetLocationsFeedDocument, options);
      }
export function useGetLocationsFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsFeedQuery, GetLocationsFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsFeedQuery, GetLocationsFeedQueryVariables>(GetLocationsFeedDocument, options);
        }
export type GetLocationsFeedQueryHookResult = ReturnType<typeof useGetLocationsFeedQuery>;
export type GetLocationsFeedLazyQueryHookResult = ReturnType<typeof useGetLocationsFeedLazyQuery>;
export type GetLocationsFeedQueryResult = Apollo.QueryResult<GetLocationsFeedQuery, GetLocationsFeedQueryVariables>;
export const GetProviderMainImagesDocument = gql`
    query getProviderMainImages($ids: [ID!]!) {
  providers(ids: $ids) {
    id
    name
    mainImage {
      ...ImageFields
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

/**
 * __useGetProviderMainImagesQuery__
 *
 * To run a query within a React component, call `useGetProviderMainImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderMainImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderMainImagesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetProviderMainImagesQuery(baseOptions: Apollo.QueryHookOptions<GetProviderMainImagesQuery, GetProviderMainImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderMainImagesQuery, GetProviderMainImagesQueryVariables>(GetProviderMainImagesDocument, options);
      }
export function useGetProviderMainImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderMainImagesQuery, GetProviderMainImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderMainImagesQuery, GetProviderMainImagesQueryVariables>(GetProviderMainImagesDocument, options);
        }
export type GetProviderMainImagesQueryHookResult = ReturnType<typeof useGetProviderMainImagesQuery>;
export type GetProviderMainImagesLazyQueryHookResult = ReturnType<typeof useGetProviderMainImagesLazyQuery>;
export type GetProviderMainImagesQueryResult = Apollo.QueryResult<GetProviderMainImagesQuery, GetProviderMainImagesQueryVariables>;
export const MarketingFormByAssocIdDocument = gql`
    query marketingFormByAssocId($assocId: ID!, $formName: String!) {
  me {
    ... on Customer {
      eventList {
        id
      }
    }
    ... on Concierge {
      eventList {
        id
      }
    }
    ... on Admin {
      eventList {
        id
      }
    }
  }
  marketingFormByAssocId(assocId: $assocId, formName: $formName) {
    assocId
    formData
    formName
  }
}
    `;

/**
 * __useMarketingFormByAssocIdQuery__
 *
 * To run a query within a React component, call `useMarketingFormByAssocIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingFormByAssocIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingFormByAssocIdQuery({
 *   variables: {
 *      assocId: // value for 'assocId'
 *      formName: // value for 'formName'
 *   },
 * });
 */
export function useMarketingFormByAssocIdQuery(baseOptions: Apollo.QueryHookOptions<MarketingFormByAssocIdQuery, MarketingFormByAssocIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketingFormByAssocIdQuery, MarketingFormByAssocIdQueryVariables>(MarketingFormByAssocIdDocument, options);
      }
export function useMarketingFormByAssocIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketingFormByAssocIdQuery, MarketingFormByAssocIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketingFormByAssocIdQuery, MarketingFormByAssocIdQueryVariables>(MarketingFormByAssocIdDocument, options);
        }
export type MarketingFormByAssocIdQueryHookResult = ReturnType<typeof useMarketingFormByAssocIdQuery>;
export type MarketingFormByAssocIdLazyQueryHookResult = ReturnType<typeof useMarketingFormByAssocIdLazyQuery>;
export type MarketingFormByAssocIdQueryResult = Apollo.QueryResult<MarketingFormByAssocIdQuery, MarketingFormByAssocIdQueryVariables>;
export const CreateMarketingFormExistingUserDocument = gql`
    mutation createMarketingFormExistingUser($input: MarketingFormInput!) {
  createMarketingFormExistingUser(input: $input) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type CreateMarketingFormExistingUserMutationFn = Apollo.MutationFunction<CreateMarketingFormExistingUserMutation, CreateMarketingFormExistingUserMutationVariables>;

/**
 * __useCreateMarketingFormExistingUserMutation__
 *
 * To run a mutation, you first call `useCreateMarketingFormExistingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketingFormExistingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketingFormExistingUserMutation, { data, loading, error }] = useCreateMarketingFormExistingUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketingFormExistingUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateMarketingFormExistingUserMutation, CreateMarketingFormExistingUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMarketingFormExistingUserMutation, CreateMarketingFormExistingUserMutationVariables>(CreateMarketingFormExistingUserDocument, options);
      }
export type CreateMarketingFormExistingUserMutationHookResult = ReturnType<typeof useCreateMarketingFormExistingUserMutation>;
export type CreateMarketingFormExistingUserMutationResult = Apollo.MutationResult<CreateMarketingFormExistingUserMutation>;
export type CreateMarketingFormExistingUserMutationOptions = Apollo.BaseMutationOptions<CreateMarketingFormExistingUserMutation, CreateMarketingFormExistingUserMutationVariables>;
export const GetEventDocument = gql`
    query getEvent($customUrl: String!) {
  getEvent(customUrl: $customUrl) {
    id
    name
    description
    defaultKidsAllowed
    brideFirstName
    brideLastName
    groomFirstName
    groomLastName
    video
    cover
    invitationTemplate {
      template
    }
    mainImage
    host {
      avatarUrl
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      customUrl: // value for 'customUrl'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetEventComponentsPublicDocument = gql`
    query getEventComponentsPublic($eventId: ID!) {
  getEventComponents(eventId: $eventId) {
    main
    capacity
    bookingCount
    date
    duration
    id
    alias
    deadline
    guestPays
    order
    customTexts
    offering {
      __typename
      ... on PackageOffering {
        ...PackageFieldsPublic
      }
      ... on RoomOffering {
        ...RoomFieldsPublic
      }
    }
    main
    items {
      price
      deleted
      master
    }
    tiers {
      id
      name
    }
    packageCustomization {
      ...PackageAddonsFields
    }
  }
}
    ${PackageFieldsPublicFragmentDoc}
${RoomFieldsPublicFragmentDoc}
${PackageAddonsFieldsFragmentDoc}`;

/**
 * __useGetEventComponentsPublicQuery__
 *
 * To run a query within a React component, call `useGetEventComponentsPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventComponentsPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventComponentsPublicQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetEventComponentsPublicQuery(baseOptions: Apollo.QueryHookOptions<GetEventComponentsPublicQuery, GetEventComponentsPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventComponentsPublicQuery, GetEventComponentsPublicQueryVariables>(GetEventComponentsPublicDocument, options);
      }
export function useGetEventComponentsPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventComponentsPublicQuery, GetEventComponentsPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventComponentsPublicQuery, GetEventComponentsPublicQueryVariables>(GetEventComponentsPublicDocument, options);
        }
export type GetEventComponentsPublicQueryHookResult = ReturnType<typeof useGetEventComponentsPublicQuery>;
export type GetEventComponentsPublicLazyQueryHookResult = ReturnType<typeof useGetEventComponentsPublicLazyQuery>;
export type GetEventComponentsPublicQueryResult = Apollo.QueryResult<GetEventComponentsPublicQuery, GetEventComponentsPublicQueryVariables>;
export const GetEventComponentPricesDocument = gql`
    query getEventComponentPrices($eventComponentId: ID!) {
  getEventComponentPrices(eventComponentId: $eventComponentId) {
    basePrice
    duration
    offeringPrice
    tax
    total
  }
}
    `;

/**
 * __useGetEventComponentPricesQuery__
 *
 * To run a query within a React component, call `useGetEventComponentPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventComponentPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventComponentPricesQuery({
 *   variables: {
 *      eventComponentId: // value for 'eventComponentId'
 *   },
 * });
 */
export function useGetEventComponentPricesQuery(baseOptions: Apollo.QueryHookOptions<GetEventComponentPricesQuery, GetEventComponentPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventComponentPricesQuery, GetEventComponentPricesQueryVariables>(GetEventComponentPricesDocument, options);
      }
export function useGetEventComponentPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventComponentPricesQuery, GetEventComponentPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventComponentPricesQuery, GetEventComponentPricesQueryVariables>(GetEventComponentPricesDocument, options);
        }
export type GetEventComponentPricesQueryHookResult = ReturnType<typeof useGetEventComponentPricesQuery>;
export type GetEventComponentPricesLazyQueryHookResult = ReturnType<typeof useGetEventComponentPricesLazyQuery>;
export type GetEventComponentPricesQueryResult = Apollo.QueryResult<GetEventComponentPricesQuery, GetEventComponentPricesQueryVariables>;
export const ConfirmBookingPublicDocument = gql`
    mutation confirmBookingPublic($eventComponentId: ID!, $guestData: GuestInput!, $extraNights: ExtraNightsAddonPublic, $freeGuests: [GuestBookingAddonPublic], $paidGuests: [GuestBookingAddonPublic], $addonsToDelete: [ID!]) {
  confirmBookingPublic(
    eventComponentId: $eventComponentId
    guestData: $guestData
    freeGuests: $freeGuests
    paidGuests: $paidGuests
    extraNights: $extraNights
    addonsToDelete: $addonsToDelete
  ) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type ConfirmBookingPublicMutationFn = Apollo.MutationFunction<ConfirmBookingPublicMutation, ConfirmBookingPublicMutationVariables>;

/**
 * __useConfirmBookingPublicMutation__
 *
 * To run a mutation, you first call `useConfirmBookingPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBookingPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBookingPublicMutation, { data, loading, error }] = useConfirmBookingPublicMutation({
 *   variables: {
 *      eventComponentId: // value for 'eventComponentId'
 *      guestData: // value for 'guestData'
 *      extraNights: // value for 'extraNights'
 *      freeGuests: // value for 'freeGuests'
 *      paidGuests: // value for 'paidGuests'
 *      addonsToDelete: // value for 'addonsToDelete'
 *   },
 * });
 */
export function useConfirmBookingPublicMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmBookingPublicMutation, ConfirmBookingPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmBookingPublicMutation, ConfirmBookingPublicMutationVariables>(ConfirmBookingPublicDocument, options);
      }
export type ConfirmBookingPublicMutationHookResult = ReturnType<typeof useConfirmBookingPublicMutation>;
export type ConfirmBookingPublicMutationResult = Apollo.MutationResult<ConfirmBookingPublicMutation>;
export type ConfirmBookingPublicMutationOptions = Apollo.BaseMutationOptions<ConfirmBookingPublicMutation, ConfirmBookingPublicMutationVariables>;
export const AddAllAddonsToBookingPublicDocument = gql`
    mutation addAllAddonsToBookingPublic($eventComponentId: ID!, $guestData: GuestInput!, $extraNights: ExtraNightsAddonPublic, $freeGuests: [GuestBookingAddonPublic], $paidGuests: [GuestBookingAddonPublic], $addonsToDelete: [ID!]) {
  addAllAddonsToBookingPublic(
    eventComponentId: $eventComponentId
    guestData: $guestData
    freeGuests: $freeGuests
    paidGuests: $paidGuests
    extraNights: $extraNights
    addonsToDelete: $addonsToDelete
  ) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type AddAllAddonsToBookingPublicMutationFn = Apollo.MutationFunction<AddAllAddonsToBookingPublicMutation, AddAllAddonsToBookingPublicMutationVariables>;

/**
 * __useAddAllAddonsToBookingPublicMutation__
 *
 * To run a mutation, you first call `useAddAllAddonsToBookingPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAllAddonsToBookingPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAllAddonsToBookingPublicMutation, { data, loading, error }] = useAddAllAddonsToBookingPublicMutation({
 *   variables: {
 *      eventComponentId: // value for 'eventComponentId'
 *      guestData: // value for 'guestData'
 *      extraNights: // value for 'extraNights'
 *      freeGuests: // value for 'freeGuests'
 *      paidGuests: // value for 'paidGuests'
 *      addonsToDelete: // value for 'addonsToDelete'
 *   },
 * });
 */
export function useAddAllAddonsToBookingPublicMutation(baseOptions?: Apollo.MutationHookOptions<AddAllAddonsToBookingPublicMutation, AddAllAddonsToBookingPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAllAddonsToBookingPublicMutation, AddAllAddonsToBookingPublicMutationVariables>(AddAllAddonsToBookingPublicDocument, options);
      }
export type AddAllAddonsToBookingPublicMutationHookResult = ReturnType<typeof useAddAllAddonsToBookingPublicMutation>;
export type AddAllAddonsToBookingPublicMutationResult = Apollo.MutationResult<AddAllAddonsToBookingPublicMutation>;
export type AddAllAddonsToBookingPublicMutationOptions = Apollo.BaseMutationOptions<AddAllAddonsToBookingPublicMutation, AddAllAddonsToBookingPublicMutationVariables>;
export const GetBookingDataPublicDocument = gql`
    query getBookingDataPublic($id: ID!) {
  bookingPublic(id: $id) {
    id
    status
    availability
    date
    duration
    guest {
      id
      plusOne
      firstName
      lastName
      email
      phone
    }
    guestPrice {
      basePrice
      tax
      total
      offeringPrice
      duration
    }
    bookingAddOn {
      id
      offeringAddOnOption {
        id
        label
        price
        description
        quantity
        offeringAddOnId
      }
      offeringAddOn {
        id
        frequency
        label
        mandatory
        taxable
        format
        price
        type
        metadata
      }
      bookingGuest {
        firstName
        lastName
        phone
        email
        plusone
      }
    }
    eventComponent {
      id
      date
      duration
      capacity
      guestPays
      eventId
      alias
      items {
        deleted
        master
        price
      }
      packageCustomization {
        ...PackageAddonsFields
      }
      offering {
        __typename
        ... on PackageOffering {
          name
          intro
          description
          mainImage {
            ...ImageFields
          }
          images {
            ...ImageFields
          }
          provider {
            bookAllRequired
            id
            address
            url
            name
          }
          addOns {
            ...OfferingAddonsFields
          }
        }
        ... on RoomOffering {
          name
          id
          description
          mainImage {
            ...ImageFields
          }
          images {
            ...ImageFields
          }
          provider {
            bookAllRequired
            id
            address
            url
            name
            accomodationTaxRate
            venueTaxRate
          }
          price
          room {
            beds
            occupancy
            size
            basic
          }
          addOns {
            ...OfferingAddonsFields
          }
        }
      }
    }
  }
}
    ${PackageAddonsFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${OfferingAddonsFieldsFragmentDoc}`;

/**
 * __useGetBookingDataPublicQuery__
 *
 * To run a query within a React component, call `useGetBookingDataPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingDataPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingDataPublicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingDataPublicQuery(baseOptions: Apollo.QueryHookOptions<GetBookingDataPublicQuery, GetBookingDataPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingDataPublicQuery, GetBookingDataPublicQueryVariables>(GetBookingDataPublicDocument, options);
      }
export function useGetBookingDataPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingDataPublicQuery, GetBookingDataPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingDataPublicQuery, GetBookingDataPublicQueryVariables>(GetBookingDataPublicDocument, options);
        }
export type GetBookingDataPublicQueryHookResult = ReturnType<typeof useGetBookingDataPublicQuery>;
export type GetBookingDataPublicLazyQueryHookResult = ReturnType<typeof useGetBookingDataPublicLazyQuery>;
export type GetBookingDataPublicQueryResult = Apollo.QueryResult<GetBookingDataPublicQuery, GetBookingDataPublicQueryVariables>;
export const ValidateGuestPaymentPublicDocument = gql`
    mutation validateGuestPaymentPublic($paymentIntent: String!) {
  validateGuestPaymentPublic(paymentIntent: $paymentIntent) {
    result {
      paymentStatus
      booking {
        id
        status
      }
    }
    errors {
      key
      message
    }
  }
}
    `;
export type ValidateGuestPaymentPublicMutationFn = Apollo.MutationFunction<ValidateGuestPaymentPublicMutation, ValidateGuestPaymentPublicMutationVariables>;

/**
 * __useValidateGuestPaymentPublicMutation__
 *
 * To run a mutation, you first call `useValidateGuestPaymentPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateGuestPaymentPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateGuestPaymentPublicMutation, { data, loading, error }] = useValidateGuestPaymentPublicMutation({
 *   variables: {
 *      paymentIntent: // value for 'paymentIntent'
 *   },
 * });
 */
export function useValidateGuestPaymentPublicMutation(baseOptions?: Apollo.MutationHookOptions<ValidateGuestPaymentPublicMutation, ValidateGuestPaymentPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateGuestPaymentPublicMutation, ValidateGuestPaymentPublicMutationVariables>(ValidateGuestPaymentPublicDocument, options);
      }
export type ValidateGuestPaymentPublicMutationHookResult = ReturnType<typeof useValidateGuestPaymentPublicMutation>;
export type ValidateGuestPaymentPublicMutationResult = Apollo.MutationResult<ValidateGuestPaymentPublicMutation>;
export type ValidateGuestPaymentPublicMutationOptions = Apollo.BaseMutationOptions<ValidateGuestPaymentPublicMutation, ValidateGuestPaymentPublicMutationVariables>;
export const InitiateGuestPaymentPublicDocument = gql`
    mutation initiateGuestPaymentPublic($bookingId: ID!) {
  initiateGuestPaymentPublic(bookingId: $bookingId) {
    result {
      id
      clientSecret
      amount
    }
    errors {
      key
      message
    }
  }
}
    `;
export type InitiateGuestPaymentPublicMutationFn = Apollo.MutationFunction<InitiateGuestPaymentPublicMutation, InitiateGuestPaymentPublicMutationVariables>;

/**
 * __useInitiateGuestPaymentPublicMutation__
 *
 * To run a mutation, you first call `useInitiateGuestPaymentPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateGuestPaymentPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateGuestPaymentPublicMutation, { data, loading, error }] = useInitiateGuestPaymentPublicMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useInitiateGuestPaymentPublicMutation(baseOptions?: Apollo.MutationHookOptions<InitiateGuestPaymentPublicMutation, InitiateGuestPaymentPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateGuestPaymentPublicMutation, InitiateGuestPaymentPublicMutationVariables>(InitiateGuestPaymentPublicDocument, options);
      }
export type InitiateGuestPaymentPublicMutationHookResult = ReturnType<typeof useInitiateGuestPaymentPublicMutation>;
export type InitiateGuestPaymentPublicMutationResult = Apollo.MutationResult<InitiateGuestPaymentPublicMutation>;
export type InitiateGuestPaymentPublicMutationOptions = Apollo.BaseMutationOptions<InitiateGuestPaymentPublicMutation, InitiateGuestPaymentPublicMutationVariables>;
export const CreateTierDocument = gql`
    mutation createTier($eventId: ID!, $input: TierInput!) {
  createTier(eventId: $eventId, input: $input) {
    result {
      id
      name
    }
    errors {
      key
      message
    }
  }
}
    `;
export type CreateTierMutationFn = Apollo.MutationFunction<CreateTierMutation, CreateTierMutationVariables>;

/**
 * __useCreateTierMutation__
 *
 * To run a mutation, you first call `useCreateTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTierMutation, { data, loading, error }] = useCreateTierMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTierMutation(baseOptions?: Apollo.MutationHookOptions<CreateTierMutation, CreateTierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTierMutation, CreateTierMutationVariables>(CreateTierDocument, options);
      }
export type CreateTierMutationHookResult = ReturnType<typeof useCreateTierMutation>;
export type CreateTierMutationResult = Apollo.MutationResult<CreateTierMutation>;
export type CreateTierMutationOptions = Apollo.BaseMutationOptions<CreateTierMutation, CreateTierMutationVariables>;
export const UpdateTierDocument = gql`
    mutation updateTier($id: ID!, $input: TierInput!) {
  updateTier(id: $id, input: $input) {
    result {
      id
      name
    }
    errors {
      key
      message
    }
  }
}
    `;
export type UpdateTierMutationFn = Apollo.MutationFunction<UpdateTierMutation, UpdateTierMutationVariables>;

/**
 * __useUpdateTierMutation__
 *
 * To run a mutation, you first call `useUpdateTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTierMutation, { data, loading, error }] = useUpdateTierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTierMutation, UpdateTierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTierMutation, UpdateTierMutationVariables>(UpdateTierDocument, options);
      }
export type UpdateTierMutationHookResult = ReturnType<typeof useUpdateTierMutation>;
export type UpdateTierMutationResult = Apollo.MutationResult<UpdateTierMutation>;
export type UpdateTierMutationOptions = Apollo.BaseMutationOptions<UpdateTierMutation, UpdateTierMutationVariables>;
export const DeleteTierDocument = gql`
    mutation deleteTier($id: ID!) {
  deleteTier(id: $id) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type DeleteTierMutationFn = Apollo.MutationFunction<DeleteTierMutation, DeleteTierMutationVariables>;

/**
 * __useDeleteTierMutation__
 *
 * To run a mutation, you first call `useDeleteTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTierMutation, { data, loading, error }] = useDeleteTierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTierMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTierMutation, DeleteTierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTierMutation, DeleteTierMutationVariables>(DeleteTierDocument, options);
      }
export type DeleteTierMutationHookResult = ReturnType<typeof useDeleteTierMutation>;
export type DeleteTierMutationResult = Apollo.MutationResult<DeleteTierMutation>;
export type DeleteTierMutationOptions = Apollo.BaseMutationOptions<DeleteTierMutation, DeleteTierMutationVariables>;
export const GuestMeDocument = gql`
    query guestMe {
  me {
    ... on Guest {
      event {
        id
        name
        mainImage
        description
        video
        cover
        brideFirstName
        brideLastName
        groomFirstName
        groomLastName
        invitationTemplate {
          template
        }
        host {
          firstName
          lastName
        }
      }
      status
      id
      email
      firstName
      lastName
      kidsAllowed
      plusOne
      bookedComponents
      eventComponents {
        ...EventComponentFields
      }
      mainComponent {
        ...EventComponentFields
      }
    }
  }
}
    ${EventComponentFieldsFragmentDoc}`;

/**
 * __useGuestMeQuery__
 *
 * To run a query within a React component, call `useGuestMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGuestMeQuery(baseOptions?: Apollo.QueryHookOptions<GuestMeQuery, GuestMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuestMeQuery, GuestMeQueryVariables>(GuestMeDocument, options);
      }
export function useGuestMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuestMeQuery, GuestMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuestMeQuery, GuestMeQueryVariables>(GuestMeDocument, options);
        }
export type GuestMeQueryHookResult = ReturnType<typeof useGuestMeQuery>;
export type GuestMeLazyQueryHookResult = ReturnType<typeof useGuestMeLazyQuery>;
export type GuestMeQueryResult = Apollo.QueryResult<GuestMeQuery, GuestMeQueryVariables>;
export const SendInvitationDocument = gql`
    mutation sendInvitation($id: ID!) {
  sendInvitation(id: $id) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type SendInvitationMutationFn = Apollo.MutationFunction<SendInvitationMutation, SendInvitationMutationVariables>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitationMutation, SendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvitationMutation, SendInvitationMutationVariables>(SendInvitationDocument, options);
      }
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>;
export type SendInvitationMutationResult = Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<SendInvitationMutation, SendInvitationMutationVariables>;
export const SendPreInvitationDocument = gql`
    mutation sendPreInvitation($id: ID!) {
  sendPreInvitation(id: $id) {
    result {
      id
      status
      preInvitationSent
    }
    errors {
      key
      message
    }
  }
}
    `;
export type SendPreInvitationMutationFn = Apollo.MutationFunction<SendPreInvitationMutation, SendPreInvitationMutationVariables>;

/**
 * __useSendPreInvitationMutation__
 *
 * To run a mutation, you first call `useSendPreInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPreInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPreInvitationMutation, { data, loading, error }] = useSendPreInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendPreInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendPreInvitationMutation, SendPreInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPreInvitationMutation, SendPreInvitationMutationVariables>(SendPreInvitationDocument, options);
      }
export type SendPreInvitationMutationHookResult = ReturnType<typeof useSendPreInvitationMutation>;
export type SendPreInvitationMutationResult = Apollo.MutationResult<SendPreInvitationMutation>;
export type SendPreInvitationMutationOptions = Apollo.BaseMutationOptions<SendPreInvitationMutation, SendPreInvitationMutationVariables>;
export const SendReminderDocument = gql`
    mutation sendReminder($guestId: ID!, $template: String!) {
  sendReminder(guestId: $guestId, template: $template) {
    result {
      id
      status
      reminderSentAt
    }
    errors {
      key
      message
    }
  }
}
    `;
export type SendReminderMutationFn = Apollo.MutationFunction<SendReminderMutation, SendReminderMutationVariables>;

/**
 * __useSendReminderMutation__
 *
 * To run a mutation, you first call `useSendReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReminderMutation, { data, loading, error }] = useSendReminderMutation({
 *   variables: {
 *      guestId: // value for 'guestId'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useSendReminderMutation(baseOptions?: Apollo.MutationHookOptions<SendReminderMutation, SendReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendReminderMutation, SendReminderMutationVariables>(SendReminderDocument, options);
      }
export type SendReminderMutationHookResult = ReturnType<typeof useSendReminderMutation>;
export type SendReminderMutationResult = Apollo.MutationResult<SendReminderMutation>;
export type SendReminderMutationOptions = Apollo.BaseMutationOptions<SendReminderMutation, SendReminderMutationVariables>;
export const TriggerGuestEventDocument = gql`
    mutation triggerGuestEvent($guestId: ID!, $event: GuestEvent!) {
  triggerGuestEvent(guestId: $guestId, event: $event) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type TriggerGuestEventMutationFn = Apollo.MutationFunction<TriggerGuestEventMutation, TriggerGuestEventMutationVariables>;

/**
 * __useTriggerGuestEventMutation__
 *
 * To run a mutation, you first call `useTriggerGuestEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerGuestEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerGuestEventMutation, { data, loading, error }] = useTriggerGuestEventMutation({
 *   variables: {
 *      guestId: // value for 'guestId'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTriggerGuestEventMutation(baseOptions?: Apollo.MutationHookOptions<TriggerGuestEventMutation, TriggerGuestEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerGuestEventMutation, TriggerGuestEventMutationVariables>(TriggerGuestEventDocument, options);
      }
export type TriggerGuestEventMutationHookResult = ReturnType<typeof useTriggerGuestEventMutation>;
export type TriggerGuestEventMutationResult = Apollo.MutationResult<TriggerGuestEventMutation>;
export type TriggerGuestEventMutationOptions = Apollo.BaseMutationOptions<TriggerGuestEventMutation, TriggerGuestEventMutationVariables>;
export const UpdateMyInvitationDocument = gql`
    mutation updateMyInvitation($guestId: ID!, $event: GuestEvent!) {
  updateMyInvitation(guestId: $guestId, event: $event) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type UpdateMyInvitationMutationFn = Apollo.MutationFunction<UpdateMyInvitationMutation, UpdateMyInvitationMutationVariables>;

/**
 * __useUpdateMyInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateMyInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyInvitationMutation, { data, loading, error }] = useUpdateMyInvitationMutation({
 *   variables: {
 *      guestId: // value for 'guestId'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateMyInvitationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyInvitationMutation, UpdateMyInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyInvitationMutation, UpdateMyInvitationMutationVariables>(UpdateMyInvitationDocument, options);
      }
export type UpdateMyInvitationMutationHookResult = ReturnType<typeof useUpdateMyInvitationMutation>;
export type UpdateMyInvitationMutationResult = Apollo.MutationResult<UpdateMyInvitationMutation>;
export type UpdateMyInvitationMutationOptions = Apollo.BaseMutationOptions<UpdateMyInvitationMutation, UpdateMyInvitationMutationVariables>;
export const GetMyEventInfoRsvpDocument = gql`
    query getMyEventInfoRSVP($id: ID!) {
  me {
    id
    ... on Customer {
      myEventInfo(id: $id) {
        ...commonEventFieldsRSVP
      }
    }
    ... on Concierge {
      myEventInfo(id: $id) {
        ...commonEventFieldsRSVP
      }
    }
    ... on Admin {
      myEventInfo(id: $id) {
        ...commonEventFieldsRSVP
      }
    }
  }
}
    ${CommonEventFieldsRsvpFragmentDoc}`;

/**
 * __useGetMyEventInfoRsvpQuery__
 *
 * To run a query within a React component, call `useGetMyEventInfoRsvpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEventInfoRsvpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEventInfoRsvpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMyEventInfoRsvpQuery(baseOptions: Apollo.QueryHookOptions<GetMyEventInfoRsvpQuery, GetMyEventInfoRsvpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEventInfoRsvpQuery, GetMyEventInfoRsvpQueryVariables>(GetMyEventInfoRsvpDocument, options);
      }
export function useGetMyEventInfoRsvpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEventInfoRsvpQuery, GetMyEventInfoRsvpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEventInfoRsvpQuery, GetMyEventInfoRsvpQueryVariables>(GetMyEventInfoRsvpDocument, options);
        }
export type GetMyEventInfoRsvpQueryHookResult = ReturnType<typeof useGetMyEventInfoRsvpQuery>;
export type GetMyEventInfoRsvpLazyQueryHookResult = ReturnType<typeof useGetMyEventInfoRsvpLazyQuery>;
export type GetMyEventInfoRsvpQueryResult = Apollo.QueryResult<GetMyEventInfoRsvpQuery, GetMyEventInfoRsvpQueryVariables>;
export const CreateBookingDocument = gql`
    mutation createBooking($eventComponentId: ID!) {
  createBooking(eventComponentId: $eventComponentId) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type CreateBookingMutationFn = Apollo.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      eventComponentId: // value for 'eventComponentId'
 *   },
 * });
 */
export function useCreateBookingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, options);
      }
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<CreateBookingMutation, CreateBookingMutationVariables>;
export const CreateBookingForGuestDocument = gql`
    mutation createBookingForGuest($eventComponentId: ID!, $guestId: ID!) {
  createBookingForGuest(eventComponentId: $eventComponentId, guestId: $guestId) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type CreateBookingForGuestMutationFn = Apollo.MutationFunction<CreateBookingForGuestMutation, CreateBookingForGuestMutationVariables>;

/**
 * __useCreateBookingForGuestMutation__
 *
 * To run a mutation, you first call `useCreateBookingForGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingForGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingForGuestMutation, { data, loading, error }] = useCreateBookingForGuestMutation({
 *   variables: {
 *      eventComponentId: // value for 'eventComponentId'
 *      guestId: // value for 'guestId'
 *   },
 * });
 */
export function useCreateBookingForGuestMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingForGuestMutation, CreateBookingForGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingForGuestMutation, CreateBookingForGuestMutationVariables>(CreateBookingForGuestDocument, options);
      }
export type CreateBookingForGuestMutationHookResult = ReturnType<typeof useCreateBookingForGuestMutation>;
export type CreateBookingForGuestMutationResult = Apollo.MutationResult<CreateBookingForGuestMutation>;
export type CreateBookingForGuestMutationOptions = Apollo.BaseMutationOptions<CreateBookingForGuestMutation, CreateBookingForGuestMutationVariables>;
export const GetBookingInfoDocument = gql`
    query getBookingInfo($id: ID!, $userId: ID!) {
  me {
    ... on Customer {
      eventList {
        id
        name
      }
      myEventList {
        id
        name
      }
    }
    ... on Concierge {
      eventList {
        id
        name
      }
      myEventList {
        id
        name
      }
    }
    ... on Admin {
      eventList {
        id
        name
      }
      myEventList {
        id
        name
      }
    }
  }
  booking(id: $id, userId: $userId) {
    id
    status
    availability
    date
    duration
    guest {
      id
      plusOne
      plusOneCount
      firstName
      lastName
      email
      phone
      flightInformation {
        ...flightData
      }
    }
    guestPrice {
      basePrice
      tax
      total
      offeringPrice
      duration
    }
    bookingAddOn {
      ...BookingAddonsFields
    }
    eventComponent {
      id
      date
      duration
      capacity
      guestPays
      eventId
      alias
      main
      deadline
      softDeadline
      items {
        deleted
        master
        price
      }
      event {
        id
        name
      }
      customTexts
      packageCustomization {
        ...PackageAddonsFields
      }
      bookingCount
      offering {
        __typename
        ... on PackageOffering {
          name
          intro
          description
          mainImage {
            ...ImageFields
          }
          images {
            ...ImageFields
          }
          provider {
            ...ProviderFields
          }
          price
          addOns {
            ...OfferingAddonsFields
          }
          package {
            basic
          }
        }
        ... on RoomOffering {
          name
          id
          description
          mainImage {
            ...ImageFields
          }
          images {
            ...ImageFields
          }
          provider {
            ...ProviderFields
          }
          price
          room {
            beds
            occupancy
            size
            basic
          }
          addOns {
            ...OfferingAddonsFields
          }
        }
      }
    }
  }
}
    ${FlightDataFragmentDoc}
${BookingAddonsFieldsFragmentDoc}
${PackageAddonsFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${ProviderFieldsFragmentDoc}
${OfferingAddonsFieldsFragmentDoc}`;

/**
 * __useGetBookingInfoQuery__
 *
 * To run a query within a React component, call `useGetBookingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBookingInfoQuery(baseOptions: Apollo.QueryHookOptions<GetBookingInfoQuery, GetBookingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingInfoQuery, GetBookingInfoQueryVariables>(GetBookingInfoDocument, options);
      }
export function useGetBookingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingInfoQuery, GetBookingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingInfoQuery, GetBookingInfoQueryVariables>(GetBookingInfoDocument, options);
        }
export type GetBookingInfoQueryHookResult = ReturnType<typeof useGetBookingInfoQuery>;
export type GetBookingInfoLazyQueryHookResult = ReturnType<typeof useGetBookingInfoLazyQuery>;
export type GetBookingInfoQueryResult = Apollo.QueryResult<GetBookingInfoQuery, GetBookingInfoQueryVariables>;
export const CustomizeBookingDurationDocument = gql`
    mutation customizeBookingDuration($bookingId: ID!, $date: NaiveDateTime, $duration: Int, $addonNightsAfterId: ID, $optionNightsAfterId: ID, $addonNightsBeforeId: ID, $optionNightsBeforeId: ID) {
  customizeBookingDuration(
    bookingId: $bookingId
    date: $date
    duration: $duration
    addonNightsAfterId: $addonNightsAfterId
    optionNightsAfterId: $optionNightsAfterId
    addonNightsBeforeId: $addonNightsBeforeId
    optionNightsBeforeId: $optionNightsBeforeId
  ) {
    result {
      id
      status
      date
      duration
      bookingAddOn {
        id
        offeringAddOnOption {
          id
          label
          price
          description
          quantity
        }
        offeringAddOn {
          id
          frequency
          label
          mandatory
          taxable
          format
          price
          type
          metadata
        }
        bookingGuest {
          firstName
          lastName
          phone
          email
          guest {
            id
          }
        }
      }
    }
    errors {
      key
      message
    }
  }
}
    `;
export type CustomizeBookingDurationMutationFn = Apollo.MutationFunction<CustomizeBookingDurationMutation, CustomizeBookingDurationMutationVariables>;

/**
 * __useCustomizeBookingDurationMutation__
 *
 * To run a mutation, you first call `useCustomizeBookingDurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomizeBookingDurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customizeBookingDurationMutation, { data, loading, error }] = useCustomizeBookingDurationMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      date: // value for 'date'
 *      duration: // value for 'duration'
 *      addonNightsAfterId: // value for 'addonNightsAfterId'
 *      optionNightsAfterId: // value for 'optionNightsAfterId'
 *      addonNightsBeforeId: // value for 'addonNightsBeforeId'
 *      optionNightsBeforeId: // value for 'optionNightsBeforeId'
 *   },
 * });
 */
export function useCustomizeBookingDurationMutation(baseOptions?: Apollo.MutationHookOptions<CustomizeBookingDurationMutation, CustomizeBookingDurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomizeBookingDurationMutation, CustomizeBookingDurationMutationVariables>(CustomizeBookingDurationDocument, options);
      }
export type CustomizeBookingDurationMutationHookResult = ReturnType<typeof useCustomizeBookingDurationMutation>;
export type CustomizeBookingDurationMutationResult = Apollo.MutationResult<CustomizeBookingDurationMutation>;
export type CustomizeBookingDurationMutationOptions = Apollo.BaseMutationOptions<CustomizeBookingDurationMutation, CustomizeBookingDurationMutationVariables>;
export const ValidateGuestPaymentDocument = gql`
    mutation validateGuestPayment($paymentIntent: String!) {
  validateGuestPayment(paymentIntent: $paymentIntent) {
    result {
      paymentStatus
      booking {
        id
        status
      }
    }
    errors {
      key
      message
    }
  }
}
    `;
export type ValidateGuestPaymentMutationFn = Apollo.MutationFunction<ValidateGuestPaymentMutation, ValidateGuestPaymentMutationVariables>;

/**
 * __useValidateGuestPaymentMutation__
 *
 * To run a mutation, you first call `useValidateGuestPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateGuestPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateGuestPaymentMutation, { data, loading, error }] = useValidateGuestPaymentMutation({
 *   variables: {
 *      paymentIntent: // value for 'paymentIntent'
 *   },
 * });
 */
export function useValidateGuestPaymentMutation(baseOptions?: Apollo.MutationHookOptions<ValidateGuestPaymentMutation, ValidateGuestPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateGuestPaymentMutation, ValidateGuestPaymentMutationVariables>(ValidateGuestPaymentDocument, options);
      }
export type ValidateGuestPaymentMutationHookResult = ReturnType<typeof useValidateGuestPaymentMutation>;
export type ValidateGuestPaymentMutationResult = Apollo.MutationResult<ValidateGuestPaymentMutation>;
export type ValidateGuestPaymentMutationOptions = Apollo.BaseMutationOptions<ValidateGuestPaymentMutation, ValidateGuestPaymentMutationVariables>;
export const InitiateGuestPaymentDocument = gql`
    mutation initiateGuestPayment($bookingId: ID!) {
  initiateGuestPayment(bookingId: $bookingId) {
    result {
      id
      clientSecret
      amount
    }
    errors {
      key
      message
    }
  }
}
    `;
export type InitiateGuestPaymentMutationFn = Apollo.MutationFunction<InitiateGuestPaymentMutation, InitiateGuestPaymentMutationVariables>;

/**
 * __useInitiateGuestPaymentMutation__
 *
 * To run a mutation, you first call `useInitiateGuestPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateGuestPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateGuestPaymentMutation, { data, loading, error }] = useInitiateGuestPaymentMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useInitiateGuestPaymentMutation(baseOptions?: Apollo.MutationHookOptions<InitiateGuestPaymentMutation, InitiateGuestPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateGuestPaymentMutation, InitiateGuestPaymentMutationVariables>(InitiateGuestPaymentDocument, options);
      }
export type InitiateGuestPaymentMutationHookResult = ReturnType<typeof useInitiateGuestPaymentMutation>;
export type InitiateGuestPaymentMutationResult = Apollo.MutationResult<InitiateGuestPaymentMutation>;
export type InitiateGuestPaymentMutationOptions = Apollo.BaseMutationOptions<InitiateGuestPaymentMutation, InitiateGuestPaymentMutationVariables>;
export const ConfirmBookingDocument = gql`
    mutation confirmBooking($bookingId: ID!, $extraNights: ExtraNightsAddon, $freeGuests: [GuestBookingAddon], $paidGuests: [GuestBookingAddon], $addonsToDelete: [ID!]) {
  confirmBooking(
    bookingId: $bookingId
    freeGuests: $freeGuests
    paidGuests: $paidGuests
    extraNights: $extraNights
    addonsToDelete: $addonsToDelete
  ) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type ConfirmBookingMutationFn = Apollo.MutationFunction<ConfirmBookingMutation, ConfirmBookingMutationVariables>;

/**
 * __useConfirmBookingMutation__
 *
 * To run a mutation, you first call `useConfirmBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBookingMutation, { data, loading, error }] = useConfirmBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      extraNights: // value for 'extraNights'
 *      freeGuests: // value for 'freeGuests'
 *      paidGuests: // value for 'paidGuests'
 *      addonsToDelete: // value for 'addonsToDelete'
 *   },
 * });
 */
export function useConfirmBookingMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmBookingMutation, ConfirmBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmBookingMutation, ConfirmBookingMutationVariables>(ConfirmBookingDocument, options);
      }
export type ConfirmBookingMutationHookResult = ReturnType<typeof useConfirmBookingMutation>;
export type ConfirmBookingMutationResult = Apollo.MutationResult<ConfirmBookingMutation>;
export type ConfirmBookingMutationOptions = Apollo.BaseMutationOptions<ConfirmBookingMutation, ConfirmBookingMutationVariables>;
export const EditBookingDocument = gql`
    mutation editBooking($bookingId: ID!, $freeGuests: [GuestBookingAddon], $addonsToDelete: [ID!]) {
  editBooking(
    bookingId: $bookingId
    freeGuests: $freeGuests
    addonsToDelete: $addonsToDelete
  ) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type EditBookingMutationFn = Apollo.MutationFunction<EditBookingMutation, EditBookingMutationVariables>;

/**
 * __useEditBookingMutation__
 *
 * To run a mutation, you first call `useEditBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBookingMutation, { data, loading, error }] = useEditBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      freeGuests: // value for 'freeGuests'
 *      addonsToDelete: // value for 'addonsToDelete'
 *   },
 * });
 */
export function useEditBookingMutation(baseOptions?: Apollo.MutationHookOptions<EditBookingMutation, EditBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditBookingMutation, EditBookingMutationVariables>(EditBookingDocument, options);
      }
export type EditBookingMutationHookResult = ReturnType<typeof useEditBookingMutation>;
export type EditBookingMutationResult = Apollo.MutationResult<EditBookingMutation>;
export type EditBookingMutationOptions = Apollo.BaseMutationOptions<EditBookingMutation, EditBookingMutationVariables>;
export const DeclineBookingDocument = gql`
    mutation declineBooking($id: ID!, $guestId: ID, $offeringAddOnId: ID) {
  declineBooking(id: $id, guestId: $guestId, offeringAddOnId: $offeringAddOnId) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type DeclineBookingMutationFn = Apollo.MutationFunction<DeclineBookingMutation, DeclineBookingMutationVariables>;

/**
 * __useDeclineBookingMutation__
 *
 * To run a mutation, you first call `useDeclineBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineBookingMutation, { data, loading, error }] = useDeclineBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      guestId: // value for 'guestId'
 *      offeringAddOnId: // value for 'offeringAddOnId'
 *   },
 * });
 */
export function useDeclineBookingMutation(baseOptions?: Apollo.MutationHookOptions<DeclineBookingMutation, DeclineBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineBookingMutation, DeclineBookingMutationVariables>(DeclineBookingDocument, options);
      }
export type DeclineBookingMutationHookResult = ReturnType<typeof useDeclineBookingMutation>;
export type DeclineBookingMutationResult = Apollo.MutationResult<DeclineBookingMutation>;
export type DeclineBookingMutationOptions = Apollo.BaseMutationOptions<DeclineBookingMutation, DeclineBookingMutationVariables>;
export const CancelBookingDocument = gql`
    mutation cancelBooking($id: ID!) {
  cancelBooking(id: $id) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type CancelBookingMutationFn = Apollo.MutationFunction<CancelBookingMutation, CancelBookingMutationVariables>;

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelBookingMutation(baseOptions?: Apollo.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(CancelBookingDocument, options);
      }
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>;
export type CancelBookingMutationResult = Apollo.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = Apollo.BaseMutationOptions<CancelBookingMutation, CancelBookingMutationVariables>;
export const GetInviatationDataDocument = gql`
    query getInviatationData($eventId: ID!) {
  me {
    id
    ... on Customer {
      myBookings(eventId: $eventId) {
        id
        status
        eventComponent {
          id
          customTexts
          offering {
            __typename
          }
        }
      }
      myEvents {
        id
      }
      invitations {
        eventId
        guestId
        status
        kidsAllowed
        plusOne
      }
      invitation(eventId: $eventId) {
        id
        name
        description
        mainImage
        video
        cover
        brideFirstName
        brideLastName
        groomFirstName
        groomLastName
        invitationTemplate {
          template
        }
        host {
          firstName
          lastName
        }
        components {
          ...EventComponentFields
        }
      }
    }
  }
}
    ${EventComponentFieldsFragmentDoc}`;

/**
 * __useGetInviatationDataQuery__
 *
 * To run a query within a React component, call `useGetInviatationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviatationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviatationDataQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetInviatationDataQuery(baseOptions: Apollo.QueryHookOptions<GetInviatationDataQuery, GetInviatationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInviatationDataQuery, GetInviatationDataQueryVariables>(GetInviatationDataDocument, options);
      }
export function useGetInviatationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInviatationDataQuery, GetInviatationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInviatationDataQuery, GetInviatationDataQueryVariables>(GetInviatationDataDocument, options);
        }
export type GetInviatationDataQueryHookResult = ReturnType<typeof useGetInviatationDataQuery>;
export type GetInviatationDataLazyQueryHookResult = ReturnType<typeof useGetInviatationDataLazyQuery>;
export type GetInviatationDataQueryResult = Apollo.QueryResult<GetInviatationDataQuery, GetInviatationDataQueryVariables>;
export const AddAddonToBookingDocument = gql`
    mutation addAddonToBooking($bookingId: ID!, $offeringAddOnId: ID!, $offeringAddOnOptionId: ID, $guestBookingInput: GuestBookingInput) {
  addAddonToBooking(
    bookingId: $bookingId
    offeringAddOnId: $offeringAddOnId
    offeringAddOnOptionId: $offeringAddOnOptionId
    guestBookingInput: $guestBookingInput
  ) {
    result {
      id
      offeringAddOnOption {
        id
        label
        price
        description
        quantity
      }
      offeringAddOn {
        id
        frequency
        label
        mandatory
        price
        taxable
        format
        type
        metadata
      }
      bookingGuest {
        firstName
        lastName
        phone
        email
        plusone
        guest {
          id
        }
      }
    }
    errors {
      key
      message
    }
  }
}
    `;
export type AddAddonToBookingMutationFn = Apollo.MutationFunction<AddAddonToBookingMutation, AddAddonToBookingMutationVariables>;

/**
 * __useAddAddonToBookingMutation__
 *
 * To run a mutation, you first call `useAddAddonToBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddonToBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddonToBookingMutation, { data, loading, error }] = useAddAddonToBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      offeringAddOnId: // value for 'offeringAddOnId'
 *      offeringAddOnOptionId: // value for 'offeringAddOnOptionId'
 *      guestBookingInput: // value for 'guestBookingInput'
 *   },
 * });
 */
export function useAddAddonToBookingMutation(baseOptions?: Apollo.MutationHookOptions<AddAddonToBookingMutation, AddAddonToBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAddonToBookingMutation, AddAddonToBookingMutationVariables>(AddAddonToBookingDocument, options);
      }
export type AddAddonToBookingMutationHookResult = ReturnType<typeof useAddAddonToBookingMutation>;
export type AddAddonToBookingMutationResult = Apollo.MutationResult<AddAddonToBookingMutation>;
export type AddAddonToBookingMutationOptions = Apollo.BaseMutationOptions<AddAddonToBookingMutation, AddAddonToBookingMutationVariables>;
export const RemoveAddonFromBookingDocument = gql`
    mutation removeAddonFromBooking($bookingId: ID!, $bookingAddonId: ID!) {
  removeAddonFromBooking(bookingId: $bookingId, bookingAddonId: $bookingAddonId) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type RemoveAddonFromBookingMutationFn = Apollo.MutationFunction<RemoveAddonFromBookingMutation, RemoveAddonFromBookingMutationVariables>;

/**
 * __useRemoveAddonFromBookingMutation__
 *
 * To run a mutation, you first call `useRemoveAddonFromBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAddonFromBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAddonFromBookingMutation, { data, loading, error }] = useRemoveAddonFromBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      bookingAddonId: // value for 'bookingAddonId'
 *   },
 * });
 */
export function useRemoveAddonFromBookingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAddonFromBookingMutation, RemoveAddonFromBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAddonFromBookingMutation, RemoveAddonFromBookingMutationVariables>(RemoveAddonFromBookingDocument, options);
      }
export type RemoveAddonFromBookingMutationHookResult = ReturnType<typeof useRemoveAddonFromBookingMutation>;
export type RemoveAddonFromBookingMutationResult = Apollo.MutationResult<RemoveAddonFromBookingMutation>;
export type RemoveAddonFromBookingMutationOptions = Apollo.BaseMutationOptions<RemoveAddonFromBookingMutation, RemoveAddonFromBookingMutationVariables>;
export const GetBookingsByIdsDocument = gql`
    query getBookingsByIds($ids: [ID!]!) {
  bookings(ids: $ids) {
    id
    status
    date
    duration
    guestPrice {
      total
    }
    bookingAddOn {
      id
      bookingGuest {
        email
        firstName
        lastName
        phone
        plusone
      }
      offeringAddOn {
        metadata
      }
      offeringAddOnOption {
        quantity
      }
    }
    eventComponent {
      id
      date
      alias
      duration
      guestPays
      __typename
      offering {
        ... on RoomOffering {
          id
          name
          mainImage {
            ...ImageFields
          }
          provider {
            name
          }
        }
        ... on PackageOffering {
          id
          name
          mainImage {
            ...ImageFields
          }
          provider {
            name
          }
        }
      }
    }
  }
}
    ${ImageFieldsFragmentDoc}`;

/**
 * __useGetBookingsByIdsQuery__
 *
 * To run a query within a React component, call `useGetBookingsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetBookingsByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>(GetBookingsByIdsDocument, options);
      }
export function useGetBookingsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>(GetBookingsByIdsDocument, options);
        }
export type GetBookingsByIdsQueryHookResult = ReturnType<typeof useGetBookingsByIdsQuery>;
export type GetBookingsByIdsLazyQueryHookResult = ReturnType<typeof useGetBookingsByIdsLazyQuery>;
export type GetBookingsByIdsQueryResult = Apollo.QueryResult<GetBookingsByIdsQuery, GetBookingsByIdsQueryVariables>;
export const AddAllAddonsToBookingDocument = gql`
    mutation addAllAddonsToBooking($bookingId: ID!, $extraNights: ExtraNightsAddon, $freeGuests: [GuestBookingAddon], $paidGuests: [GuestBookingAddon], $addonsToDelete: [ID!]) {
  addAllAddonsToBooking(
    bookingId: $bookingId
    freeGuests: $freeGuests
    paidGuests: $paidGuests
    extraNights: $extraNights
    addonsToDelete: $addonsToDelete
  ) {
    result {
      id
    }
    errors {
      key
      message
    }
  }
}
    `;
export type AddAllAddonsToBookingMutationFn = Apollo.MutationFunction<AddAllAddonsToBookingMutation, AddAllAddonsToBookingMutationVariables>;

/**
 * __useAddAllAddonsToBookingMutation__
 *
 * To run a mutation, you first call `useAddAllAddonsToBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAllAddonsToBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAllAddonsToBookingMutation, { data, loading, error }] = useAddAllAddonsToBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      extraNights: // value for 'extraNights'
 *      freeGuests: // value for 'freeGuests'
 *      paidGuests: // value for 'paidGuests'
 *      addonsToDelete: // value for 'addonsToDelete'
 *   },
 * });
 */
export function useAddAllAddonsToBookingMutation(baseOptions?: Apollo.MutationHookOptions<AddAllAddonsToBookingMutation, AddAllAddonsToBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAllAddonsToBookingMutation, AddAllAddonsToBookingMutationVariables>(AddAllAddonsToBookingDocument, options);
      }
export type AddAllAddonsToBookingMutationHookResult = ReturnType<typeof useAddAllAddonsToBookingMutation>;
export type AddAllAddonsToBookingMutationResult = Apollo.MutationResult<AddAllAddonsToBookingMutation>;
export type AddAllAddonsToBookingMutationOptions = Apollo.BaseMutationOptions<AddAllAddonsToBookingMutation, AddAllAddonsToBookingMutationVariables>;
export const AddFlightInformationDocument = gql`
    mutation addFlightInformation($guestId: ID!, $input: FlightInformationInput!) {
  addFlightInformation(guestId: $guestId, input: $input) {
    result {
      ...flightData
    }
    errors {
      key
      message
    }
  }
}
    ${FlightDataFragmentDoc}`;
export type AddFlightInformationMutationFn = Apollo.MutationFunction<AddFlightInformationMutation, AddFlightInformationMutationVariables>;

/**
 * __useAddFlightInformationMutation__
 *
 * To run a mutation, you first call `useAddFlightInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFlightInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFlightInformationMutation, { data, loading, error }] = useAddFlightInformationMutation({
 *   variables: {
 *      guestId: // value for 'guestId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFlightInformationMutation(baseOptions?: Apollo.MutationHookOptions<AddFlightInformationMutation, AddFlightInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFlightInformationMutation, AddFlightInformationMutationVariables>(AddFlightInformationDocument, options);
      }
export type AddFlightInformationMutationHookResult = ReturnType<typeof useAddFlightInformationMutation>;
export type AddFlightInformationMutationResult = Apollo.MutationResult<AddFlightInformationMutation>;
export type AddFlightInformationMutationOptions = Apollo.BaseMutationOptions<AddFlightInformationMutation, AddFlightInformationMutationVariables>;
export const UpdateFlightInformationDocument = gql`
    mutation updateFlightInformation($guestId: ID!, $input: FlightInformationInput!) {
  updateFlightInformation(guestId: $guestId, input: $input) {
    result {
      ...flightData
    }
    errors {
      key
      message
    }
  }
}
    ${FlightDataFragmentDoc}`;
export type UpdateFlightInformationMutationFn = Apollo.MutationFunction<UpdateFlightInformationMutation, UpdateFlightInformationMutationVariables>;

/**
 * __useUpdateFlightInformationMutation__
 *
 * To run a mutation, you first call `useUpdateFlightInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlightInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlightInformationMutation, { data, loading, error }] = useUpdateFlightInformationMutation({
 *   variables: {
 *      guestId: // value for 'guestId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFlightInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFlightInformationMutation, UpdateFlightInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFlightInformationMutation, UpdateFlightInformationMutationVariables>(UpdateFlightInformationDocument, options);
      }
export type UpdateFlightInformationMutationHookResult = ReturnType<typeof useUpdateFlightInformationMutation>;
export type UpdateFlightInformationMutationResult = Apollo.MutationResult<UpdateFlightInformationMutation>;
export type UpdateFlightInformationMutationOptions = Apollo.BaseMutationOptions<UpdateFlightInformationMutation, UpdateFlightInformationMutationVariables>;
export const ManageGuestBookingDocument = gql`
    mutation manageGuestBooking($id: ID!, $eventId: ID!, $action: HostBookingAction!) {
  manageGuestBooking(id: $id, eventId: $eventId, action: $action) {
    result {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
    `;
export type ManageGuestBookingMutationFn = Apollo.MutationFunction<ManageGuestBookingMutation, ManageGuestBookingMutationVariables>;

/**
 * __useManageGuestBookingMutation__
 *
 * To run a mutation, you first call `useManageGuestBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageGuestBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageGuestBookingMutation, { data, loading, error }] = useManageGuestBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      eventId: // value for 'eventId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useManageGuestBookingMutation(baseOptions?: Apollo.MutationHookOptions<ManageGuestBookingMutation, ManageGuestBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageGuestBookingMutation, ManageGuestBookingMutationVariables>(ManageGuestBookingDocument, options);
      }
export type ManageGuestBookingMutationHookResult = ReturnType<typeof useManageGuestBookingMutation>;
export type ManageGuestBookingMutationResult = Apollo.MutationResult<ManageGuestBookingMutation>;
export type ManageGuestBookingMutationOptions = Apollo.BaseMutationOptions<ManageGuestBookingMutation, ManageGuestBookingMutationVariables>;
export const FeedDocument = gql`
    query feed($eventIds: [ID!], $cursor: CursorInput) {
  feed(eventIds: $eventIds, cursor: $cursor) {
    entries {
      ...postsFields
    }
    cursor
  }
  me {
    id
    firstName
    lastName
    avatarUrl
  }
}
    ${PostsFieldsFragmentDoc}`;

/**
 * __useFeedQuery__
 *
 * To run a query within a React component, call `useFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedQuery({
 *   variables: {
 *      eventIds: // value for 'eventIds'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useFeedQuery(baseOptions?: Apollo.QueryHookOptions<FeedQuery, FeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedQuery, FeedQueryVariables>(FeedDocument, options);
      }
export function useFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedQuery, FeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedQuery, FeedQueryVariables>(FeedDocument, options);
        }
export type FeedQueryHookResult = ReturnType<typeof useFeedQuery>;
export type FeedLazyQueryHookResult = ReturnType<typeof useFeedLazyQuery>;
export type FeedQueryResult = Apollo.QueryResult<FeedQuery, FeedQueryVariables>;
export const CommentsDocument = gql`
    query comments($postId: ID!, $cursor: String) {
  comments(postId: $postId, cursor: {cursor: $cursor, limit: 6}) {
    entries {
      ...commentsFields
    }
    cursor
  }
}
    ${CommentsFieldsFragmentDoc}`;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const CreatePostDocument = gql`
    mutation createPost($eventId: ID!, $tierIds: [ID!]!, $input: PostInput!) {
  createPost(eventId: $eventId, tierIds: $tierIds, input: $input) {
    result {
      ...postsFields
    }
    errors {
      key
      message
    }
  }
}
    ${PostsFieldsFragmentDoc}`;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      tierIds: // value for 'tierIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const CreateCommentPostDocument = gql`
    mutation createCommentPost($postId: ID!, $input: CommentInput!) {
  createComment(postId: $postId, input: $input) {
    result {
      ...commentsFields
    }
    errors {
      key
      message
    }
  }
}
    ${CommentsFieldsFragmentDoc}`;
export type CreateCommentPostMutationFn = Apollo.MutationFunction<CreateCommentPostMutation, CreateCommentPostMutationVariables>;

/**
 * __useCreateCommentPostMutation__
 *
 * To run a mutation, you first call `useCreateCommentPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentPostMutation, { data, loading, error }] = useCreateCommentPostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentPostMutation, CreateCommentPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentPostMutation, CreateCommentPostMutationVariables>(CreateCommentPostDocument, options);
      }
export type CreateCommentPostMutationHookResult = ReturnType<typeof useCreateCommentPostMutation>;
export type CreateCommentPostMutationResult = Apollo.MutationResult<CreateCommentPostMutation>;
export type CreateCommentPostMutationOptions = Apollo.BaseMutationOptions<CreateCommentPostMutation, CreateCommentPostMutationVariables>;
export const PostAddedDocument = gql`
    subscription postAdded($eventId: ID!) {
  postAdded(eventId: $eventId)
}
    `;

/**
 * __usePostAddedSubscription__
 *
 * To run a query within a React component, call `usePostAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePostAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostAddedSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function usePostAddedSubscription(baseOptions: Apollo.SubscriptionHookOptions<PostAddedSubscription, PostAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PostAddedSubscription, PostAddedSubscriptionVariables>(PostAddedDocument, options);
      }
export type PostAddedSubscriptionHookResult = ReturnType<typeof usePostAddedSubscription>;
export type PostAddedSubscriptionResult = Apollo.SubscriptionResult<PostAddedSubscription>;
export const DeletePostDocument = gql`
    mutation deletePost($id: ID!) {
  deletePost(id: $id) {
    result {
      id
    }
    errors {
      message
    }
  }
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($id: ID!) {
  deleteComment(id: $id) {
    result {
      ...commentsFields
    }
    errors {
      message
    }
  }
}
    ${CommentsFieldsFragmentDoc}`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const UpdatePostDocument = gql`
    mutation updatePost($id: ID!, $input: PostInput!, $tierIds: [ID!]!) {
  updatePost(id: $id, input: $input, tierIds: $tierIds) {
    result {
      ...postsFields
    }
    errors {
      key
      message
    }
  }
}
    ${PostsFieldsFragmentDoc}`;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      tierIds: // value for 'tierIds'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const PinPostDocument = gql`
    mutation pinPost($id: ID!) {
  pinPost(id: $id) {
    result {
      ...postsFields
    }
    errors {
      key
      message
    }
  }
}
    ${PostsFieldsFragmentDoc}`;
export type PinPostMutationFn = Apollo.MutationFunction<PinPostMutation, PinPostMutationVariables>;

/**
 * __usePinPostMutation__
 *
 * To run a mutation, you first call `usePinPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinPostMutation, { data, loading, error }] = usePinPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePinPostMutation(baseOptions?: Apollo.MutationHookOptions<PinPostMutation, PinPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PinPostMutation, PinPostMutationVariables>(PinPostDocument, options);
      }
export type PinPostMutationHookResult = ReturnType<typeof usePinPostMutation>;
export type PinPostMutationResult = Apollo.MutationResult<PinPostMutation>;
export type PinPostMutationOptions = Apollo.BaseMutationOptions<PinPostMutation, PinPostMutationVariables>;
export const UnpinPostDocument = gql`
    mutation unpinPost($id: ID!) {
  unpinPost(id: $id) {
    result {
      ...postsFields
    }
    errors {
      key
      message
    }
  }
}
    ${PostsFieldsFragmentDoc}`;
export type UnpinPostMutationFn = Apollo.MutationFunction<UnpinPostMutation, UnpinPostMutationVariables>;

/**
 * __useUnpinPostMutation__
 *
 * To run a mutation, you first call `useUnpinPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinPostMutation, { data, loading, error }] = useUnpinPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnpinPostMutation(baseOptions?: Apollo.MutationHookOptions<UnpinPostMutation, UnpinPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpinPostMutation, UnpinPostMutationVariables>(UnpinPostDocument, options);
      }
export type UnpinPostMutationHookResult = ReturnType<typeof useUnpinPostMutation>;
export type UnpinPostMutationResult = Apollo.MutationResult<UnpinPostMutation>;
export type UnpinPostMutationOptions = Apollo.BaseMutationOptions<UnpinPostMutation, UnpinPostMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation updateComment($id: ID!, $input: CommentInput!) {
  updateComment(id: $id, input: $input) {
    result {
      ...commentsFields
    }
    errors {
      key
      message
    }
  }
}
    ${CommentsFieldsFragmentDoc}`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const VoteDocument = gql`
    mutation vote($postId: ID!, $optionId: ID!) {
  vote(postId: $postId, optionId: $optionId) {
    result {
      id
      name
      percent
      voted
    }
    errors {
      key
      message
    }
  }
}
    `;
export type VoteMutationFn = Apollo.MutationFunction<VoteMutation, VoteMutationVariables>;

/**
 * __useVoteMutation__
 *
 * To run a mutation, you first call `useVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteMutation, { data, loading, error }] = useVoteMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useVoteMutation(baseOptions?: Apollo.MutationHookOptions<VoteMutation, VoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteMutation, VoteMutationVariables>(VoteDocument, options);
      }
export type VoteMutationHookResult = ReturnType<typeof useVoteMutation>;
export type VoteMutationResult = Apollo.MutationResult<VoteMutation>;
export type VoteMutationOptions = Apollo.BaseMutationOptions<VoteMutation, VoteMutationVariables>;
export const VotesDocument = gql`
    query votes($optionId: ID!, $postId: ID!) {
  votes(optionId: $optionId, postId: $postId) {
    avatarUrl
    firstName
    lastName
  }
}
    `;

/**
 * __useVotesQuery__
 *
 * To run a query within a React component, call `useVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVotesQuery({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useVotesQuery(baseOptions: Apollo.QueryHookOptions<VotesQuery, VotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VotesQuery, VotesQueryVariables>(VotesDocument, options);
      }
export function useVotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VotesQuery, VotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VotesQuery, VotesQueryVariables>(VotesDocument, options);
        }
export type VotesQueryHookResult = ReturnType<typeof useVotesQuery>;
export type VotesLazyQueryHookResult = ReturnType<typeof useVotesLazyQuery>;
export type VotesQueryResult = Apollo.QueryResult<VotesQuery, VotesQueryVariables>;
export const GetBookedEventComponentsDocument = gql`
    query getBookedEventComponents($eventId: ID!) {
  me {
    id
    ... on Customer {
      myEventList {
        id
      }
      eventInfo(id: $eventId) {
        ...getBookedEventComponentsCommonEvent
      }
      myBookings(eventId: $eventId) {
        ...getBookedEventComponentsCommonBooking
      }
      invitation(eventId: $eventId) {
        id
        components {
          ...EventComponentFields
        }
      }
    }
    ... on Concierge {
      myEventList {
        id
      }
      eventInfo(id: $eventId) {
        ...getBookedEventComponentsCommonEvent
      }
      myBookings(eventId: $eventId) {
        ...getBookedEventComponentsCommonBooking
      }
      invitation(eventId: $eventId) {
        id
        components {
          ...EventComponentFields
        }
      }
    }
    ... on Admin {
      myEventList {
        id
      }
      eventInfo(id: $eventId) {
        ...getBookedEventComponentsCommonEvent
      }
      myBookings(eventId: $eventId) {
        ...getBookedEventComponentsCommonBooking
      }
      invitation(eventId: $eventId) {
        id
        components {
          ...EventComponentFields
        }
      }
    }
  }
}
    ${GetBookedEventComponentsCommonEventFragmentDoc}
${GetBookedEventComponentsCommonBookingFragmentDoc}
${EventComponentFieldsFragmentDoc}`;

/**
 * __useGetBookedEventComponentsQuery__
 *
 * To run a query within a React component, call `useGetBookedEventComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookedEventComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookedEventComponentsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetBookedEventComponentsQuery(baseOptions: Apollo.QueryHookOptions<GetBookedEventComponentsQuery, GetBookedEventComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookedEventComponentsQuery, GetBookedEventComponentsQueryVariables>(GetBookedEventComponentsDocument, options);
      }
export function useGetBookedEventComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookedEventComponentsQuery, GetBookedEventComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookedEventComponentsQuery, GetBookedEventComponentsQueryVariables>(GetBookedEventComponentsDocument, options);
        }
export type GetBookedEventComponentsQueryHookResult = ReturnType<typeof useGetBookedEventComponentsQuery>;
export type GetBookedEventComponentsLazyQueryHookResult = ReturnType<typeof useGetBookedEventComponentsLazyQuery>;
export type GetBookedEventComponentsQueryResult = Apollo.QueryResult<GetBookedEventComponentsQuery, GetBookedEventComponentsQueryVariables>;
export const GetMyMainInformationDocument = gql`
    query getMyMainInformation {
  me {
    id
    firstName
    lastName
    avatarUrl
  }
}
    `;

/**
 * __useGetMyMainInformationQuery__
 *
 * To run a query within a React component, call `useGetMyMainInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyMainInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyMainInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyMainInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetMyMainInformationQuery, GetMyMainInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyMainInformationQuery, GetMyMainInformationQueryVariables>(GetMyMainInformationDocument, options);
      }
export function useGetMyMainInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyMainInformationQuery, GetMyMainInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyMainInformationQuery, GetMyMainInformationQueryVariables>(GetMyMainInformationDocument, options);
        }
export type GetMyMainInformationQueryHookResult = ReturnType<typeof useGetMyMainInformationQuery>;
export type GetMyMainInformationLazyQueryHookResult = ReturnType<typeof useGetMyMainInformationLazyQuery>;
export type GetMyMainInformationQueryResult = Apollo.QueryResult<GetMyMainInformationQuery, GetMyMainInformationQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserBasicData!) {
  updateUser(input: $input) {
    result {
      id
      avatarUrl
      firstName
      lastName
    }
    errors {
      key
      message
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetMyInvitationsDocument = gql`
    query getMyInvitations {
  me {
    id
    ... on Customer {
      invitations {
        eventId
        eventName
        status
        guestId
      }
    }
  }
}
    `;

/**
 * __useGetMyInvitationsQuery__
 *
 * To run a query within a React component, call `useGetMyInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>(GetMyInvitationsDocument, options);
      }
export function useGetMyInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>(GetMyInvitationsDocument, options);
        }
export type GetMyInvitationsQueryHookResult = ReturnType<typeof useGetMyInvitationsQuery>;
export type GetMyInvitationsLazyQueryHookResult = ReturnType<typeof useGetMyInvitationsLazyQuery>;
export type GetMyInvitationsQueryResult = Apollo.QueryResult<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>;
export const GetMyProvidersDocument = gql`
    query getMyProviders($cursor: String) {
  me {
    id
    ... on Vendor {
      myProviders(cursor: {cursor: $cursor, limit: 15}) {
        cursor
        entries {
          id
          name
          url
          address
        }
      }
    }
  }
}
    `;

/**
 * __useGetMyProvidersQuery__
 *
 * To run a query within a React component, call `useGetMyProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProvidersQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetMyProvidersQuery(baseOptions?: Apollo.QueryHookOptions<GetMyProvidersQuery, GetMyProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProvidersQuery, GetMyProvidersQueryVariables>(GetMyProvidersDocument, options);
      }
export function useGetMyProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProvidersQuery, GetMyProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProvidersQuery, GetMyProvidersQueryVariables>(GetMyProvidersDocument, options);
        }
export type GetMyProvidersQueryHookResult = ReturnType<typeof useGetMyProvidersQuery>;
export type GetMyProvidersLazyQueryHookResult = ReturnType<typeof useGetMyProvidersLazyQuery>;
export type GetMyProvidersQueryResult = Apollo.QueryResult<GetMyProvidersQuery, GetMyProvidersQueryVariables>;
export const GetNegotiationMessagesVendorDocument = gql`
    query getNegotiationMessagesVendor($eventId: ID!, $providerId: ID!, $cursor: String) {
  me {
    id
    ... on Vendor {
      id
      negotiation(eventId: $eventId, providerId: $providerId) {
        quotes {
          eventComponent {
            id
            offering {
              __typename
              ... on PackageOffering {
                name
              }
              ... on RoomOffering {
                name
              }
            }
          }
        }
        messages(cursor: {cursor: $cursor, limit: 15}) {
          cursor
          entries {
            ...MessagesFields
          }
        }
      }
    }
  }
}
    ${MessagesFieldsFragmentDoc}`;

/**
 * __useGetNegotiationMessagesVendorQuery__
 *
 * To run a query within a React component, call `useGetNegotiationMessagesVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiationMessagesVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiationMessagesVendorQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      providerId: // value for 'providerId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNegotiationMessagesVendorQuery(baseOptions: Apollo.QueryHookOptions<GetNegotiationMessagesVendorQuery, GetNegotiationMessagesVendorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNegotiationMessagesVendorQuery, GetNegotiationMessagesVendorQueryVariables>(GetNegotiationMessagesVendorDocument, options);
      }
export function useGetNegotiationMessagesVendorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNegotiationMessagesVendorQuery, GetNegotiationMessagesVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNegotiationMessagesVendorQuery, GetNegotiationMessagesVendorQueryVariables>(GetNegotiationMessagesVendorDocument, options);
        }
export type GetNegotiationMessagesVendorQueryHookResult = ReturnType<typeof useGetNegotiationMessagesVendorQuery>;
export type GetNegotiationMessagesVendorLazyQueryHookResult = ReturnType<typeof useGetNegotiationMessagesVendorLazyQuery>;
export type GetNegotiationMessagesVendorQueryResult = Apollo.QueryResult<GetNegotiationMessagesVendorQuery, GetNegotiationMessagesVendorQueryVariables>;
export const GetNegotiationQuotesVendorDocument = gql`
    query getNegotiationQuotesVendor($eventId: ID!, $providerId: ID!) {
  me {
    id
    ... on Vendor {
      id
      negotiation(eventId: $eventId, providerId: $providerId) {
        provider {
          id
          name
          mainImage {
            ...ImageFields
          }
        }
        event {
          id
          name
          host {
            firstName
            lastName
            avatarUrl
          }
        }
        quotes {
          eventComponent {
            id
            status
            date
            duration
            offering {
              __typename
              ... on PackageOffering {
                addOns {
                  id
                  frequency
                  label
                  price
                  mandatory
                  offeringAddOnOptions {
                    id
                    description
                    label
                    price
                  }
                }
              }
            }
            packageCustomization {
              ...PackageAddonsFields
            }
          }
          items {
            ...negotiationItemFields
          }
        }
      }
    }
  }
}
    ${ImageFieldsFragmentDoc}
${PackageAddonsFieldsFragmentDoc}
${NegotiationItemFieldsFragmentDoc}`;

/**
 * __useGetNegotiationQuotesVendorQuery__
 *
 * To run a query within a React component, call `useGetNegotiationQuotesVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiationQuotesVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiationQuotesVendorQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetNegotiationQuotesVendorQuery(baseOptions: Apollo.QueryHookOptions<GetNegotiationQuotesVendorQuery, GetNegotiationQuotesVendorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNegotiationQuotesVendorQuery, GetNegotiationQuotesVendorQueryVariables>(GetNegotiationQuotesVendorDocument, options);
      }
export function useGetNegotiationQuotesVendorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNegotiationQuotesVendorQuery, GetNegotiationQuotesVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNegotiationQuotesVendorQuery, GetNegotiationQuotesVendorQueryVariables>(GetNegotiationQuotesVendorDocument, options);
        }
export type GetNegotiationQuotesVendorQueryHookResult = ReturnType<typeof useGetNegotiationQuotesVendorQuery>;
export type GetNegotiationQuotesVendorLazyQueryHookResult = ReturnType<typeof useGetNegotiationQuotesVendorLazyQuery>;
export type GetNegotiationQuotesVendorQueryResult = Apollo.QueryResult<GetNegotiationQuotesVendorQuery, GetNegotiationQuotesVendorQueryVariables>;
export const GetNegotiationsPreviewVendorDocument = gql`
    query getNegotiationsPreviewVendor($cursor: String) {
  me {
    id
    ... on Vendor {
      id
      negotiations(cursor: {cursor: $cursor, limit: 5}) {
        cursor
        entries {
          event {
            id
            name
          }
          lastUpdate
          provider {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetNegotiationsPreviewVendorQuery__
 *
 * To run a query within a React component, call `useGetNegotiationsPreviewVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiationsPreviewVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiationsPreviewVendorQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNegotiationsPreviewVendorQuery(baseOptions?: Apollo.QueryHookOptions<GetNegotiationsPreviewVendorQuery, GetNegotiationsPreviewVendorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNegotiationsPreviewVendorQuery, GetNegotiationsPreviewVendorQueryVariables>(GetNegotiationsPreviewVendorDocument, options);
      }
export function useGetNegotiationsPreviewVendorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNegotiationsPreviewVendorQuery, GetNegotiationsPreviewVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNegotiationsPreviewVendorQuery, GetNegotiationsPreviewVendorQueryVariables>(GetNegotiationsPreviewVendorDocument, options);
        }
export type GetNegotiationsPreviewVendorQueryHookResult = ReturnType<typeof useGetNegotiationsPreviewVendorQuery>;
export type GetNegotiationsPreviewVendorLazyQueryHookResult = ReturnType<typeof useGetNegotiationsPreviewVendorLazyQuery>;
export type GetNegotiationsPreviewVendorQueryResult = Apollo.QueryResult<GetNegotiationsPreviewVendorQuery, GetNegotiationsPreviewVendorQueryVariables>;